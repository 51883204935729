import getBancos from './getBancos'
import getFontesPagamentos from './getFontesPagamentos'
import getConvenios from './getConvenios'
import getCidades from './getCidades'
import getEstados from './getEstados'
import getOrgaos from './getOrgaos'
import getUpags from './getUpags'
import postSalvarLeadPortabilidade from './postSalvarLeadPortabilidade'
import getValidaCliente from './getValidaCliente'
import postValidaPortabilidade from './postValidaPortabilidade'
import postValidaMatricula from './postValidaMatricula'
import getValidaToken from './getValidaToken'
import postEnviaToken from './postEnviaToken'
import getDadosBeneficiarioInss from './getDadosBeneficiarioInss'
import postInssAsyncStart from './postInssAsyncStart'
import postConsultaMargemGeral from './postConsultaMargemGeral'
import postAtualizaCacheSiape from './postAtualizaCacheSiape'
import getCacheSiape from './getCacheSiape'
import getDadosInssResult from './getDadosInssResult'
import postSimulatePortability from './postSimulatePortability'
import getPrimeiroAcesso from './getPrimeiroAcesso'
import postContractMarginAccept from './postContractMarginAccept'
import postContractMarginResult from './postContractMarginResult'
import postSendPortabilityLead from './postSendPortabilityLead'

export default {
  getBancos,
  getFontesPagamentos,
  getConvenios,
  getCidades,
  getEstados,
  getOrgaos,
  getUpags,
  postSalvarLeadPortabilidade,
  getValidaCliente,
  postValidaPortabilidade,
  postValidaMatricula,
  getValidaToken,
  postEnviaToken,
  getDadosBeneficiarioInss,
  postInssAsyncStart,
  postConsultaMargemGeral,
  postAtualizaCacheSiape,
  getCacheSiape,
  getDadosInssResult,
  getPrimeiroAcesso,
  postContractMarginAccept,
  postContractMarginResult,
  postSimulatePortability,
  postSendPortabilityLead,
}
