import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postSalvarLeadPortabilidade, {
  SalvarLeadRequest,
} from '@services/postSalvarLeadPortabilidade'
import { ApplicationState } from '@store/types'
import {
  salvarLeadSuccess,
  salvarLeadError,
  salvarLeadRequest,
} from '@store/portabilidade/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes, FontesPagamento } from '@utils/enums'
import { setLoading } from '@store/portabilidade/validacao/actions'

import { buildLeadRequest, buildLeadRequestInss } from './models'
import { SalvarLeadRequestPayload } from '../types'

export default function* postSalvarLead(action: AnyAction) {
  const payload = action.payload as SalvarLeadRequestPayload
  try {
    const state = yield* select((s: ApplicationState) => s)
    let lead: SalvarLeadRequest
    if (state.portabilidade.dadosProfissionais.fonteSelecionada === FontesPagamento.INSS) {
      lead = buildLeadRequestInss(state)
    } else {
      lead = buildLeadRequest(state)
    }

    yield call(postSalvarLeadPortabilidade, lead)
    yield put(salvarLeadSuccess())
    yield put(setLoading(false))
    yield payload.callback()
  } catch (error) {
    yield put(salvarLeadError())
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.P0_008_LEAD,
        actionCallback: () => salvarLeadRequest(payload),
      }),
    )
  }
}
