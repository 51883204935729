import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { GridStyled, Separator } from '@atoms'
import IcHandMoney from '@interco/icons/core/human/ic_hand_money'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { ErrorCodesAPI, StepPortabilidadeNovo } from '@utils/enums'
import fgtsBanner from '@images/opcoesCredito/fgts_banner.png'
import homeequityBanner from '@images/opcoesCredito/homeequity_banner.png'
import { BridgeService } from '@services/bridge'
import { useTrackingPortabilidade } from '@hooks'
import useTicTac from '@hooks/useTicTac'
import Tags from '@utils/Tags'

import { ImageBanner, ImagesLine } from './styles'

const PortabilidadeNaoLiberada = () => {
  const [pendencia, setPendencia] = useState('')
  const location = useLocation()
  const { useTac } = useTicTac()
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  useTrackingPortabilidade(StepPortabilidadeNovo.NAO_LIBERADO)

  useTac({
    name: 'nao-liberado',
    module: 'portabilidade_consignado',
    actions: ['retornar_margin_result'],
    params: {},
    isError: true,
  })

  useEffect(() => {
    setPendencia(location.state)
  }, [location.state])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: `Feedback: desqualificacao ${
        location.state === ErrorCodesAPI.R0004 ? 'com cross' : 'sem cross'
      }`,
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Ainda não conseguimos te oferecer esse crédito',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [location.state, nomeConvenioSelecionado])

  return (
    <NovoPage
      id="nao-liberado"
      stickyFooter={
        <Button
          variant="link"
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: `Feedback: desqualificacao ${
                location.state === ErrorCodesAPI.R0004 ? 'com cross' : 'sem cross'
              }`,
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: `${
                location.state === ErrorCodesAPI.R0004 ? 'Ir para a home' : 'Voltar pra home'
              }`,
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
          }}
        >
          {pendencia === ErrorCodesAPI.R0004 ? 'Ir para a home' : 'Voltar pra home'}
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <GridStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              background: Colors.GRAY100,
              width: '64px',
              height: '64px',
              borderRadius: '50px',
            }}
          >
            <IcHandMoney width={32} height={32} color={Colors.GRAY500} />
          </div>
        </GridStyled>

        <ColorWrapper fontWeight="600" margin="24px 0 8px 0" style={{ textAlign: 'center' }}>
          <TextParagraph variant="headline-h1" textAlign="center">
            Ainda não conseguimos te oferecer esse crédito
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper margin="16px 0 16px 0" style={{ textAlign: 'center' }}>
          <TextParagraph variant="body-3">
            Isso pode acontecer devido nossas políticas internas de crédito.
          </TextParagraph>
        </ColorWrapper>
        {pendencia === ErrorCodesAPI.R0004 ? (
          <>
            <Separator variant="small" margin="8px 0 24px 0" />
            <ColorWrapper fontWeight="600" margin="0 0 24px 0">
              <TextParagraph variant="headline-h3">
                Confira outra opções de crédito disponíveis:
              </TextParagraph>
            </ColorWrapper>
            <ImagesLine>
              <ImageBanner
                src={homeequityBanner}
                alt="Banner de home equity"
                onClick={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '8',
                    name: 'BANNER',
                    screen: `Feedback: desqualificacao com cross`,
                    ref_type: 'Banner',
                    content_action: 'Toque',
                    action_id: `Home Equity`,
                    link: String(process.env.REACT_APP_HOME_EQUITY_DEEPLINK),
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  if (BridgeService.isBrowser()) {
                    window.location.href = String(process.env.REACT_APP_HOME_EQUITY_SITE)
                  } else {
                    BridgeService.openDeepLink(String(process.env.REACT_APP_HOME_EQUITY_DEEPLINK))
                  }
                }}
              />
              <ImageBanner
                src={fgtsBanner}
                alt="Banner de FGTS"
                onClick={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '8',
                    name: 'BANNER',
                    screen: `Feedback: desqualificacao com cross`,
                    ref_type: 'Banner',
                    content_action: 'Toque',
                    action_id: `Antecipacao FGTS`,
                    link: String(process.env.REACT_APP_FGTS_DEEPLINK),
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  if (BridgeService.isBrowser()) {
                    window.location.href = String(process.env.REACT_APP_FGTS_SITE)
                  } else {
                    BridgeService.openDeepLink(String(process.env.REACT_APP_FGTS_DEEPLINK))
                  }
                }}
              />
            </ImagesLine>
          </>
        ) : (
          <></>
        )}
      </div>
    </NovoPage>
  )
}

export default PortabilidadeNaoLiberada
