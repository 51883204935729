import React from 'react'

import { Container, Content, Icon } from './styles'
import { Props } from './props'

export const ListaDescricao: React.FC<Props & React.HtmlHTMLAttributes<HTMLElement>> = ({
  margin,
  alignAllItems,
  AlignOnlyLeftIcon,
  AlignOnlyRightIcon,
  iconLeft,
  iconRight,
  borderBottom,
  children,
  withBorder,
  borderColor,
  ...props
}) => (
  <Container
    margin={margin}
    borderBottom={borderBottom}
    alignItems={alignAllItems}
    withBorder={withBorder}
    borderColor={borderColor}
    data-testid="container"
    {...props}
  >
    <Icon left={!!iconLeft} align={AlignOnlyLeftIcon || alignAllItems} data-testid="left">
      {iconLeft}
    </Icon>

    <Content>{children}</Content>

    <Icon right={!!iconRight} align={AlignOnlyRightIcon || alignAllItems} data-testid="right">
      {iconRight}
    </Icon>
  </Container>
)
