import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { buildEstados } from '@utils/functions'
import { Select } from '@atoms'

import { CustomBottomSheet } from './styles'
import { EstadoDropdownProps } from './props'

const EstadoDropdown = ({ estadoSelecionado, onChange, id, disabled }: EstadoDropdownProps) => {
  const { estados = [], estadosLoading } = useSelector((state: ApplicationState) => state.globalApi)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Em qual estado?"
        name="dropdown"
        margin="0 0 20px 0"
        value={estadoSelecionado}
        hideSearch={false}
        options={buildEstados(estados)}
        placeholder="Selecione o estado"
        onChange={(value) => {
          onChange(value)
        }}
        disabled={estadosLoading || disabled}
        loading={estadosLoading}
      />
    )
  }

  return (
    <CustomBottomSheet
      id={id}
      label="Em qual estado?"
      name="dropdown"
      value={estadoSelecionado}
      onChange={(value) => {
        onChange(value)
      }}
      options={buildEstados(estados)}
      placeholder="Selecione o estado"
      disabled={estadosLoading || disabled}
      isLoading={estadosLoading}
      isUsingFilter
    />
  )
}

export default memo(EstadoDropdown)
