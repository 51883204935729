import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { openLinkByEnviroment } from '@utils/functions'
import Warning from '@interco/icons/core/status/ic_warning_circle'

import { Container, ContainerButton, Header, IconContainer } from './styles'

interface OwnProps {
  setShowBottomsheet: React.Dispatch<React.SetStateAction<boolean>>
  consultarMargem: () => void
}

const BottomsheetSegundaTentativa = ({ setShowBottomsheet, consultarMargem }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomsheet(false)} noSwipe>
    <Container>
      <Header>
        <IconContainer>
          <Warning height={64} width={64} color="rgb(255, 186, 0)" />
        </IconContainer>
        <Text variant="body-2" bold colorWeight={500}>
          Autorize a consulta de margem para prosseguir com a sua contratação
        </Text>
        <Text variant="body-3">
          Acesse o SouGov e autorize a consulta para que que possamos te oferecer as melhores
          condições e uma contratação 100% digital.
        </Text>
      </Header>
    </Container>
    <ContainerButton>
      <Button
        fullWidth
        onClick={async () => {
          await openLinkByEnviroment('https://www.gov.br/sougov')
        }}
      >
        Acessar SouGov
      </Button>
      <Button
        fullWidth
        variant="secondary"
        onClick={() => {
          setShowBottomsheet(false)
          consultarMargem()
        }}
      >
        Já autorizei
      </Button>
    </ContainerButton>
  </BottomSheet>
)

export default BottomsheetSegundaTentativa
