import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import IcHelpCircle from '@interco/icons/core/status/ic_help_circle'
import { Input } from '@atoms'
import { currencyToFloat } from '@utils/masks'
import useTicTac from '@hooks/useTicTac'
import { ErrorCodesAPI, StepPortabilidadeNovo } from '@utils/enums'
import {
  simulacaoPortabilidadeRequest,
  trackingRequest,
} from '@store/portabilidade/simulacao/actions'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import Tags from '@utils/Tags'

import { ContainerSimulador } from './styles'
import BottomsheetResultadoIndisponivel from './BottomsheetResultadoIndisponivel'
import BottomsheetSaldoDevedorHelp from './BottomsheetSaldoDevedorHelp'
import BottomsheetValorParcelaHelp from './BottomsheetValorParcelaHelp'

const Simulador = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendTic, useTac } = useTicTac()
  const [saldoDevedorValue, setSaldoDevedorValue] = useState<string>('')
  const [errorInputSaldoDevedor, setErrorInputSaldoDevedor] = useState<boolean | null>(null)
  const [valorParcelaValue, setvalorParcelaValue] = useState<string>('')
  const [errorInputValorParcela, setErrorInputValorParcela] = useState<boolean | null>(null)
  const [showBottomsheetResultadoIndisponivel, setShowBottomsheetResultadoIndisponivel] =
    useState(false)
  const [showBottomsheetSaldoDevedorhelp, setShowBottomsheetSaldoDevedorhelp] = useState(false)
  const [showBottomsheetValorParcelahelp, setShowBottomsheetValorParcelahelp] = useState(false)

  const { loadingSimulacaoPort } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacao,
  )
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  const simularPortabilidade = () => {
    let cancelarSimulacao
    if (currencyToFloat(saldoDevedorValue) <= 0) {
      setErrorInputSaldoDevedor(true)
      cancelarSimulacao = true
    }

    if (currencyToFloat(valorParcelaValue) <= 0) {
      setErrorInputValorParcela(true)
      cancelarSimulacao = true
    }

    if (cancelarSimulacao) return

    sendTic({
      name: 'btn_simular',
      module: 'portabilidade_consignado',
      action: 'simular_portabilidade',
      params: {},
    })

    Tags.enviarTagueamentoApp({
      ref_figma: '3',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Simulador',
      ref_type: 'Botao',
      content_action: 'Toque',
      action_id: 'Simular agora',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })

    dispatch(
      simulacaoPortabilidadeRequest({
        installmentValue: currencyToFloat(valorParcelaValue),
        debtBalance: currencyToFloat(saldoDevedorValue),
        callback: (erroCode: string) => {
          if (erroCode === ErrorCodesAPI.R0002) {
            navigate(routes.ENTRAREMOS_CONTATO_V2)
          } else if (erroCode === ErrorCodesAPI.R0003 || erroCode === ErrorCodesAPI.R0004) {
            navigate(routes.NAO_LIBERADO_V2, { state: erroCode })
          } else {
            navigate(routes.RESULTADO_SIMULACAO)
          }
        },
      }),
    )
  }

  useTac({
    name: 'simulador_portabilidade',
    module: 'portabilidade_consignado',
    actions: ['retornar_margin_result'],
    params: {},
  })

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Simulador',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Simule se quer trazer seu contrato com troco ou sem',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado])

  return (
    <NovoPage
      id="dados-basicos"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <div
          style={{
            borderTop: `1px solid ${Colors.GRAY100}`,
            margin: '0 -24px',
            padding: '16px 24px',
          }}
        >
          <Button
            fullWidth
            style={{ marginTop: '8px' }}
            disabled={loadingSimulacaoPort}
            onClick={() => {
              dispatch(trackingRequest(StepPortabilidadeNovo.DADOS_PROPOSTA))
              sendTic({
                name: 'btn_continuar',
                module: 'portabilidade_consignado',
                action: 'trazer_contrato',
                params: {},
              })
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Simulador',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Continuar e trazer contrato',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              navigate(routes.ENTRAREMOS_CONTATO_V2)
            }}
          >
            Continuar e trazer contrato
          </Button>
        </div>
      }
    >
      <Text variant="headline-h1" semiBold colorWeight={500}>
        Simule se quer trazer seu contrato com troco ou sem
      </Text>
      <ContainerSimulador>
        <Input
          id="input-saldo-devedor"
          label="Saldo devedor"
          placeholder="R$ 0,00"
          type="currency"
          onlyNumber
          maxLength={14}
          helper={
            <IcHelpCircle
              height={20}
              width={20}
              color={Colors.PRIMARY500}
              onClick={() => setShowBottomsheetSaldoDevedorhelp(true)}
            />
          }
          value={saldoDevedorValue || 'R$ 0,00'}
          error={errorInputSaldoDevedor || false}
          infoText={errorInputSaldoDevedor ? 'Informe o saldo devedor do seu contrato' : ''}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (currencyToFloat(value) > 0) setErrorInputSaldoDevedor(null)
            setSaldoDevedorValue(value)
          }}
        />
        <Input
          id="input-parcela"
          label="Valor da parcela"
          placeholder="R$ 0,00"
          type="currency"
          onlyNumber
          maxLength={12}
          helper={
            <IcHelpCircle
              height={20}
              width={20}
              color={Colors.PRIMARY500}
              onClick={() => setShowBottomsheetValorParcelahelp(true)}
            />
          }
          value={valorParcelaValue || 'R$ 0,00'}
          error={errorInputValorParcela || false}
          infoText={errorInputValorParcela ? 'Informe o valor da parcela' : ''}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (currencyToFloat(value) > 0) setErrorInputValorParcela(null)
            setvalorParcelaValue(value)
          }}
        />
        <Button
          variant="secondary"
          isLoading={loadingSimulacaoPort}
          onClick={() => simularPortabilidade()}
        >
          Simular agora
        </Button>
      </ContainerSimulador>
      {showBottomsheetResultadoIndisponivel ? (
        <BottomsheetResultadoIndisponivel
          setShowBottomSheet={setShowBottomsheetResultadoIndisponivel}
        />
      ) : (
        <></>
      )}
      {showBottomsheetSaldoDevedorhelp ? (
        <BottomsheetSaldoDevedorHelp setShowBottomSheet={setShowBottomsheetSaldoDevedorhelp} />
      ) : (
        <></>
      )}
      {showBottomsheetValorParcelahelp ? (
        <BottomsheetValorParcelaHelp setShowBottomSheet={setShowBottomsheetValorParcelahelp} />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default Simulador
