import React from 'react'

import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { Column, Line } from '../BottomsheetValorParcelaHelp/styles'

const DDC = () => {
  const navigate = useNavigate()

  return (
    <NovoPage
      id="dados-basicos"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <div
          style={{
            borderTop: `1px solid ${Colors.GRAY100}`,
            margin: '0 -24px',
            padding: '16px 24px',
          }}
        >
          <Button
            fullWidth
            style={{ marginTop: '8px' }}
            onClick={() => {
              navigate(-1)
            }}
          >
            Entendi
          </Button>
        </div>
      }
    >
      <Text variant="headline-h1" semiBold colorWeight={500}>
        Como solicitar o Documento Descritivo de Crédito (DDC)
      </Text>

      <Column>
        <Text variant="body-2" bold colorWeight={500} style={{ marginTop: '32px' }}>
          Através dos canais de atendimento do banco de origem:
        </Text>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">
            Deve estar sempre disponível para consulta em canais eletrônicos, como site e aplicativo
          </Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">
            Se o pedido foi feito na agência, presencialmente, deve ser entregue imediatamente.
          </Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">
            Em outro tipo de canal, deve ser entregue em até um dia útil.
          </Text>
        </Line>
      </Column>
      <Column>
        <Text variant="body-2" bold colorWeight={500} style={{ marginTop: '32px' }}>
          O documento contém as seguintes informações:
        </Text>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Número do contrato</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Saldo devedor atualizado</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Demonstrativo da evolução do saldo devedor</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Tipo da operação</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Taxa de juros anual, nominal e efetiva</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Prazo total</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Forma de pagamento</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Valor de cada parcela</Text>
        </Line>
        <Line>
          <div
            style={{
              width: '4px',
              height: '4px',
              backgroundColor: Colors.GRAY500,
              borderRadius: '50%',
            }}
          />
          <Text variant="body-3">Data de vencimento da ultima parcela</Text>
        </Line>
      </Column>
    </NovoPage>
  )
}

export default DDC
