import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { isCpf, isDate } from '@utils/validators'
import { Paragraph, Separator } from '@atoms'
import { Colors } from '@utils/colors'
import { setCpf, setDataNascimento, setNome } from '@store/portabilidade/dadosPessoais/actions'
import { cpfMask, dataMask, removeMask } from '@utils/masks'
import { Input } from '@atoms'
import { StepPortabilidade } from '@utils/enums'
import { useTrackingPortabilidade } from '@hooks'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { AlertLGPD } from '@molecules'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'

import { FooterGrid, LinkPrivacidade, Div } from './styles'

const DadosPessoais = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nome, cpf, dataNascimento } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosPessoais,
  )

  const validaNome = (nomeValido: string) => {
    const nomeArray = nomeValido.split(' ')
    return nomeArray.filter((element) => element.length >= 2).length >= 2
  }

  const disableMobileContinue = (): boolean =>
    !nome || !validaNome(nome) || !isCpf(cpf) || !isDate(dataNascimento)

  const disableContinue = (): boolean =>
    !nome || !validaNome(nome) || !isCpf(cpf) || !isDate(dataNascimento) || !checkAutorizacao

  useTrackingPortabilidade(StepPortabilidade.DADOS_PESSOAIS)

  const [checkAutorizacao, setCheckAutorizacao] = useState(false)

  const CheckboxLGPD = () => (
    <Div>
      <Separator variant="small" height="1px" color={Colors.GRAY200} />
      <Paragraph
        fontWeight="bold"
        color={Colors.GRAY500}
        fontSize="14px"
        lineHeight="17px"
        textAlign="left"
        margin="16px 0 12px"
      >
        Seus dados estão protegidos!
      </Paragraph>
      <Checkbox
        reversed
        id="check-lgpd"
        checked={checkAutorizacao}
        onChange={(e) => setCheckAutorizacao(e.target.checked)}
        label={
          <>
            <Paragraph color={Colors.GRAY500} fontSize="14px" lineHeight="17px">
              Faça a sua simulação com tranquilidade que garantimos a segurança e privacidade de
              todos os seus dados.
            </Paragraph>
            <Paragraph color={Colors.GRAY500} fontSize="14px" lineHeight="17px" margin="0">
              Concordo com o tratamento dos meus dados e com a{' '}
              <LinkPrivacidade href="https://www.bancointer.com.br/politica-de-privacidade/">
                Política de Privacidade.
              </LinkPrivacidade>
            </Paragraph>
          </>
        }
      />
    </Div>
  )

  return (
    <Page
      id="page-dados-pessoais"
      stickyFooter={
        <Button
          disabled={isApp ? disableMobileContinue() : disableContinue()}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidade.CONTINUAR_DADOS_PESSOAIS))
            navigate(routes.CONTATO)
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
      >
        Dados Pessoais
      </Paragraph>
      <Paragraph color={Colors.GRAY500} margin="0 0 24px">
        Por favor nos confirme se seus dados estão corretos:
      </Paragraph>
      <Input
        id="dados-pessoais-nome"
        type="text"
        label="Qual é o seu nome completo?"
        placeholder="Digite seu nome completo"
        value={nome}
        onChange={(e) => dispatch(setNome((e.target as HTMLInputElement).value))}
      />
      <Input
        id="dados-pessoais-cpf"
        type="text"
        label="Qual é o seu CPF?"
        placeholder="Digite seu CPF, apenas números"
        value={cpfMask(cpf)}
        onChange={(e) => dispatch(setCpf(removeMask((e.target as HTMLInputElement).value)))}
        maxLength={14}
        error={Boolean(cpf && !isCpf(cpf))}
        infoText={cpf && !isCpf(cpf) ? 'CPF Inválido' : ''}
      />
      <Input
        id="dados-pessoais-data-nascimento"
        type="text"
        label="Qual sua data de nascimento?"
        placeholder="00/00/0000"
        value={dataMask(dataNascimento)}
        onChange={(e) =>
          dispatch(setDataNascimento(dataMask((e.target as HTMLInputElement).value)))
        }
        maxLength={10}
      />
      <FooterGrid>{isApp ? <AlertLGPD /> : <CheckboxLGPD />}</FooterGrid>
    </Page>
  )
}

export default DadosPessoais
