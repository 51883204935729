import styled from 'styled-components'

import { Radio } from '@interco/inter-ui/components/Radio'
import Paragraph from '@atoms/Paragraph'

export const Grid = styled.div`
  width: 100%;
`

export const RadioCustom = styled(Radio)`
  & > label {
    width: 100%;
  }
`
export const FullParagraph = styled(Paragraph)`
  width: 100%;
`
