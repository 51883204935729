import { call, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postTrackingPortabilidade, {
  TrackPortabilidadeRequest,
} from '@services/postTrackingPortabilidade'
import { ApplicationState } from '@store/types'
import { StepPortabilidade } from '@utils/enums'

import { buildTrackPortabilidade } from './models'

export default function* postTrackPortabilidade(action: AnyAction) {
  const payload = action.payload as StepPortabilidade

  try {
    const state = yield* select((s: ApplicationState) => s)

    const trackingRequest: TrackPortabilidadeRequest = buildTrackPortabilidade(state, payload)
    yield* call(postTrackingPortabilidade, trackingRequest)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
