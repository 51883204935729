import { combineReducers } from 'redux'

import { ContatoState } from './contato/types'
import contato from './contato'
import { ContratoAtualState } from './contratoAtual/types'
import contratoAtual from './contratoAtual'
import { DadosPessoaisState } from './dadosPessoais/types'
import dadosPessoais from './dadosPessoais'
import { SimulacaoState } from './simulacao/types'
import simulacao from './simulacao'
import { DadosProfissionaisState } from './dadosProfissionais/types'
import dadosProfissionais from './dadosProfissionais'
import { SituacaoFuncionalState } from './situacaoFuncional/types'
import situacaoFuncional from './situacaoFuncional'
import { ConsultaMarginQueryState } from './consultaMarginQuery/types'
import consultaMarginQuery from './consultaMarginQuery'
import { ConsultaMargemGeralState } from './consultaMargemGeral/types'
import consultaMargemGeral from './consultaMargemGeral'

export interface PortabilidadeState {
  contato: ContatoState
  contratoAtual: ContratoAtualState
  dadosPessoais: DadosPessoaisState
  simulacao: SimulacaoState
  situacaoFuncional: SituacaoFuncionalState
  dadosProfissionais: DadosProfissionaisState
  consultaMarginQuery: ConsultaMarginQueryState
  consultaMargemGeral: ConsultaMargemGeralState
}

export default combineReducers({
  contato,
  contratoAtual,
  dadosPessoais,
  simulacao,
  situacaoFuncional,
  dadosProfissionais,
  consultaMarginQuery,
  consultaMargemGeral,
})
