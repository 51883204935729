import { connect, ConnectedProps } from 'react-redux'

import { ApplicationState } from '@store/types'

const mapStateToProps = (state: ApplicationState) => ({
  showError: state.ui.error.showError,
  message: state.ui.error.message,
  title: state.ui.error.title,
  actionTitle: state.ui.error.actionTitle,
  actionCallback: state.ui.error.actionCallback,
  block: state.ui.error.block,
  code: state.ui.error.code,
})

export const connector = connect(mapStateToProps)

export type Props = ConnectedProps<typeof connector>
