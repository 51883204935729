import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import QueryMiddleware from '@middlewares/QueryMiddleware'
import { useMobileUserInfo } from '@hooks'
import { Page } from '@templates'
import GridStyled from '@atoms/GridStyled'
import { Loading } from '@atoms/Loading'
import { setEmail, setTelefone } from '@store/portabilidade/contato/actions'
import { setNome, setCpf, setDataNascimento } from '@store/portabilidade/dadosPessoais/actions'
import { timestampToDatePt } from '@utils/functions'
import { BridgeService } from '@services/bridge'

const RootPage = () => {
  const dispatch = useDispatch()

  const userInfo = useMobileUserInfo()

  // GET USER INFO
  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      dispatch(setNome(userInfo?.name || ''))
      dispatch(setCpf(userInfo?.cpf || ''))
      dispatch(setTelefone(userInfo?.cellPhoneNumber || ''))
      dispatch(setEmail(userInfo?.email || ''))
      dispatch(setDataNascimento(timestampToDatePt(Number(userInfo?.birthDate)) || ''))
    }
  }, [userInfo, dispatch])

  return (
    <Page id="page-root">
      <QueryMiddleware />
      <GridStyled margin="40vh 0 0">
        <Loading width={50} height={50} />
      </GridStyled>
    </Page>
  )
}

export default RootPage
