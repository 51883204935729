/**
 * Actions Types
 */

import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'

export enum GlobalApiTypes {
  FONTES_SUCCESS = '@globalApi/FONTES_SUCCESS',
  FONTES_REQUEST = '@globalApi/FONTES_REQUEST',
  FONTES_ERROR = '@globalApi/FONTES_ERROR',

  ESTADOS_SUCCESS = '@globalApi/ESTADOS_SUCCESS',
  ESTADOS_REQUEST = '@globalApi/ESTADOS_REQUEST',
  ESTADOS_ERROR = '@globalApi/ESTADOS_ERROR',

  CIDADES_SUCCESS = '@globalApi/CIDADES_SUCCESS',
  CIDADES_REQUEST = '@globalApi/CIDADES_REQUEST',
  CIDADES_ERROR = '@globalApi/CIDADES_ERROR',

  BANCOS_SUCCESS = '@globalApi/BANCOS_SUCCESS',
  BANCOS_REQUEST = '@globalApi/BANCOS_REQUEST',
  BANCOS_ERROR = '@globalApi/BANCOS_ERROR',

  UPAGS_SUCCESS = '@globalApi/UPAGS_SUCCESS',
  UPAGS_REQUEST = '@globalApi/UPAGS_REQUEST',
  UPAGS_ERROR = '@globalApi/UPAGS_ERROR',

  CONVENIOS_SUCCESS = '@globalApi/CONVENIOS_SUCCESS',
  CONVENIOS_REQUEST = '@globalApi/CONVENIOS_REQUEST',
  CONVENIOS_ERROR = '@globalApi/CONVENIOS_ERROR',

  ORGAOS_SUCCESS = '@globalApi/ORGAOS_SUCCESS',
  ORGAOS_REQUEST = '@globalApi/ORGAOS_REQUEST',
  ORGAOS_ERROR = '@globalApi/ORGAOS_ERROR',
}

/**
 * Data Types
 */

export interface FontePagamento {
  codigo: number
  descricao: string
}
export interface FontePagamentoApi {
  familiasConvenios: FontePagamento[]
}

export interface Estado {
  uf: string
  nome: string
}
export interface Cidade {
  codigo: string
  nome: string
}
export interface Banco {
  codigo: number
  nome: string
}
export interface Upag {
  codigoOrgao: string
  codigoUpag: string
  nome: string
  sigla: string
}
export interface Convenio {
  codigo: number
  descricao: string
  estado?: string
}
export interface Orgao {
  codigo: number
  descricao: string
}
export interface OptionsSituacaoFuncional {
  value: number
  option: Option
}

/**
 * State Type
 */
export interface GlobalApiState {
  readonly fontesPagamento?: FontePagamento[]
  readonly fontesPagamentoError?: boolean
  readonly fontesPagamentoLoading?: boolean

  readonly estados?: Estado[]
  readonly estadosError?: boolean
  readonly estadosLoading?: boolean

  readonly cidades?: Cidade[]
  readonly cidadesError?: boolean
  readonly cidadesLoading?: boolean

  readonly bancos?: Banco[]
  readonly bancosError?: boolean
  readonly bancosLoading?: boolean

  readonly upags: Upag[]
  readonly upagsError?: boolean
  readonly upagsLoading?: boolean

  readonly convenios?: Convenio[]
  readonly conveniosError: boolean
  readonly conveniosLoading: boolean

  readonly orgaos?: Orgao[]
  readonly orgaosError: boolean
  readonly orgaosLoading: boolean
}

/**
 * APIs Type
 */
export interface EstadosAPI {
  uf: string[]
}
export interface CidadesAPI {
  cidades: Cidade[]
}
export interface BancoApi {
  bancos: Banco[]
}
export interface UpagApi {
  upags: Upag[]
}
export interface ConvenioApi {
  convenios: Convenio[]
}
export interface OrgaoApi {
  orgaosPorConvenioConsulta: Orgao[]
}

export interface ConvenioRequestPayload {
  fontePagamento: number
  callback?: (convenios?: Convenio[]) => void
}
