import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { isCelphone } from '@utils/validators'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import { resetToken, sendToken, validateToken } from '@store/token/actions'
import { useGetRecaptchaToken } from '@hooks'
import { phoneMask, removeMask } from '@utils/masks'
import { Loading } from '@atoms/Loading'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { Input, Separator } from '@atoms'
import { setTelefone } from '@store/portabilidade/contato/actions'
import { ConveniosCodes, StepPortabilidadeNovo } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'

import InputToken from '../InputToken'
import { WrapLoadingText } from './styles'

const RESEND_TIMER = 60

const ValidaTelefone = () => {
  const type = 'sms'
  const stateType = 'portabilidade'
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getRecaptchaToken } = useGetRecaptchaToken()
  const [timer, setTimer] = useState(RESEND_TIMER)
  const [showTokenField, setShowTokenField] = useState(false)
  const [token, setToken] = useState('')
  const [filledToken, setFilledToken] = useState(false)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { error, loading, stepError } = useSelector((state: ApplicationState) => state.token)
  const { telefone } = useSelector((state: ApplicationState) => state.portabilidade.contato)
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  const onSuccess = useCallback(() => {
    dispatch(trackingRequest(StepPortabilidadeNovo.TOKEN_VALIDADO_SMS))
    if (convenioSelecionado === ConveniosCodes.INSS) {
      navigate(routes.CARREGAMENTO_DADOS_V2)
    } else {
      navigate(routes.CONSULTA_MARGEM_SIAPE_V2)
    }
  }, [convenioSelecionado, dispatch, navigate])

  const sendTokenCallback = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      if (recaptchaToken) {
        dispatch(trackingRequest(StepPortabilidadeNovo.ENVIO_TOKEN_SMS))
        dispatch(sendToken({ type, stateType, recaptchaToken }))
      }
    } else {
      dispatch(trackingRequest(StepPortabilidadeNovo.ENVIO_TOKEN_SMS))
      dispatch(sendToken({ type, stateType, recaptchaToken: 'NULLCAPTCHA' }))
    }
  }, [dispatch, getRecaptchaToken, isApp, stateType, type])

  useEffect(() => {
    if (showTokenField) {
      sendTokenCallback()
    }
  }, [sendTokenCallback, showTokenField])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const sendValidateToken = useCallback(async () => {
    let recaptchaToken = 'NULLCAPTCHA'

    if (!isApp) recaptchaToken = await getRecaptchaToken()

    dispatch(
      validateToken({
        stateType,
        token,
        type,
        recaptchaToken,
        success: () => onSuccess(),
      }),
    )
    dispatch(trackingRequest(StepPortabilidadeNovo.VALIDA_TOKEN_SMS))
  }, [isApp, dispatch, stateType, token, type, getRecaptchaToken, onSuccess])

  useEffect(() => {
    if (filledToken) {
      sendValidateToken()
    }
  }, [filledToken, sendValidateToken])

  useEffect(() => {
    dispatch(resetToken())
  }, [dispatch])

  if (loading) {
    return (
      <NovoPage id="page-novo-simulador-token">
        <WrapLoadingText>
          <Loading height={48} width={48} />
        </WrapLoadingText>
      </NovoPage>
    )
  }

  return (
    <NovoPage id="valida-email" stickyFooter={<></>} stickyContainerFooterBottom="180px">
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0">
        <TextParagraph variant="headline-h1">Agora, vamos validar o seu telefone</TextParagraph>
      </ColorWrapper>
      <ColorWrapper margin="16px 0 24px 0">
        <TextParagraph variant="body-3">
          Enviaremos um código de verificação via SMS (mensagem eletrônica) para o telefone que você
          informar.
        </TextParagraph>
      </ColorWrapper>
      <Input
        id="valida-telefone"
        label="Informe seu telefone"
        placeholder="(00) 00000-0000"
        value={phoneMask(telefone)}
        onChange={(e) => {
          setShowTokenField(false)
          dispatch(setTelefone(removeMask((e.target as HTMLInputElement).value)))
        }}
        maxLength={15}
        error={telefone.length ? !isCelphone(phoneMask(telefone)) : false}
        infoText={
          telefone.length && !isCelphone(phoneMask(telefone))
            ? 'Preencha com um número válido.'
            : ''
        }
      />

      <Button
        variant="primary"
        disabled={showTokenField || !isCelphone(phoneMask(telefone))}
        onClick={() => {
          if (isCelphone(phoneMask(telefone))) {
            setShowTokenField(true)
          }
        }}
      >
        Enviar SMS
      </Button>
      {showTokenField ? (
        <>
          <Separator
            margin="24px 0 0 0"
            variant="small"
            style={{ backgroundColor: Colors.GRAY100 }}
          />
          <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="24px 0 0 0">
            <TextParagraph variant="headline-h3">Insira o código enviado por SMS:</TextParagraph>
          </ColorWrapper>
          <InputToken
            error={error}
            stepError={stepError}
            getToken={(t, filled) => {
              setToken(t)
              setFilledToken(filled)
            }}
            id="input-token"
          />
          <Button
            fullWidth
            variant="link"
            disabled={timer > 0}
            onClick={() => {
              dispatch(resetToken())
              sendTokenCallback()
              setTimer(RESEND_TIMER)
            }}
            style={{
              marginTop: '32px',
            }}
          >
            {`Reenviar código ${timer > 0 ? `${timer}s` : ''}`}
          </Button>
        </>
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default ValidaTelefone
