import { ImageNumeroPRECCP, ImageSiglaUpag } from '@images'

export const dialogVariants = {
  valorParcela: {
    title: 'Valor das parcelas',
    text: 'Informe o valor atual das parcelas, se você tem mais de um contrato e deseja fazer portabilidade deles tambêm, basta informar o valor somado das parcelas.',
  },
  informarOutroCelular: {
    title: 'Informar outro celular',
    text: 'Se você não possui acesso ao celular informado, informe outro número de celular no cadastro',
  },
  situacaoFuncional: {
    title: 'Situação funcional',
    text: 'É a classificação do Servidor Público, que pode variar de acordo com o órgão, como efetivo, aposentado, pensionista, entre outros. ',
  },
  convenio: {
    title: 'Convênio',
    text: 'São as empresas e órgãos que têm parceria com o Inter.',
  },
  numeroBeneficio: {
    title: 'Benefício',
    text: 'Você pode conseguir esta informação no seu contracheque. Precisamos deste número para consultar seus dados no INSS.',
  },
  matricula: {
    title: 'Matricula',
    text: 'Você pode conseguir esta informação no seu contracheque. Precisamos dela para calcular sua simulação personalizada.',
  },
  siglaUpag: {
    title: 'Sigla UPAG',
    text: 'É a Unidade Pagadora. Você encontra a essa informação em seu contracheque.',
    image: (
      <ImageSiglaUpag
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
  numeroPRECCP: {
    title: 'Número de PREC-CP',
    text: 'Você encontra esta informação em seu contracheque.',
    image: (
      <ImageNumeroPRECCP
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
}

export interface VariantDialog {
  title: string
  text: string
  image?: JSX.Element
}
