import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Box, Paragraph } from '@atoms'
import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/colors'
import { StepPortabilidade } from '@utils/enums'
import { ApplicationState } from '@store/types'
import {
  setConvenioSelecionado,
  setOrgaoSelecionado,
} from '@store/portabilidade/dadosProfissionais/actions'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { useTrackingPortabilidade } from '@hooks'
import { estadosRequest, orgaosRequest } from '@store/globalApi/actions'

import { FullParagraph, Grid, RadioCustom } from './styles'

const ConvenioForcasArmadas = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { orgaos = [], convenios = [] } = useSelector((state: ApplicationState) => state.globalApi)
  useTrackingPortabilidade(StepPortabilidade.CONVENIO_FORCAS_ARMADAS)

  useEffect(() => {
    convenioSelecionado !== '' && dispatch(orgaosRequest(Number(convenioSelecionado)))
  }, [convenioSelecionado, dispatch])

  return (
    <Page
      id="page-forcas-armadas"
      stickyFooter={
        <Button
          disabled={!convenioSelecionado}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidade.CONTINUAR_CONVENIO_FORCAS_ARMADAS))
            dispatch(estadosRequest())
            if (orgaos.length !== 0) dispatch(setOrgaoSelecionado(orgaos[0].codigo))
            navigate(routes.DADOS_PROFISSIONAIS)
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph
        fontFamily="Sora"
        fontSize="24px"
        lineHeight="30px"
        fontWeight={600}
        color={Colors.GRAY500}
      >
        Convênio
      </Paragraph>
      <Paragraph margin="0 0 24px 0">Escolha uma das opções e aperte em continuar:</Paragraph>

      <>
        {convenios?.map((convenio) => (
          <Box
            justify="flex-start"
            margin="8px 0px"
            key={convenio.codigo}
            actived={convenioSelecionado === convenio.codigo}
            minHeight="0"
          >
            <Grid>
              <RadioCustom
                id={`input-radio-${convenio.codigo}`}
                name="input-radio"
                reversed
                checked={convenioSelecionado === convenio.codigo}
                onChange={() => {
                  dispatch(setConvenioSelecionado(convenio.codigo))
                }}
                value={`${convenio.codigo}`}
                key={convenio.codigo}
                label={
                  <FullParagraph
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="17px"
                    margin="0"
                    color={Colors.GRAY500}
                  >
                    {convenio.descricao}
                  </FullParagraph>
                }
              />
            </Grid>
          </Box>
        ))}
      </>
    </Page>
  )
}

export default ConvenioForcasArmadas
