import styled from 'styled-components'

import { Input } from '@interco/inter-ui/components/Input'
import { Colors } from '@utils/colors'

export const CustomInput = styled(Input)`
  & input {
    color: ${Colors.GRAY500};
  }
`
