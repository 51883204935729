import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import { getCacheSiapeRequest } from '../actions'

export default function* getCacheSiapeSaga(action: AnyAction) {
  const payload = action.payload as PayloadGetCacheSiape

  try {
    const { data } = yield services.getCacheSiape(payload.socialNumber)

    yield payload.callback(data.fields.authorizedMargin, data.fields.salary)
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao consultar o cache do siape',
        message: 'Ocorreu um erro ao consultar o cache do siape.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => getCacheSiapeRequest(action.payload),
      }),
    )
  }
}

export interface PayloadGetCacheSiape {
  callback: (authorizedMargin: boolean, salary: string) => void
  socialNumber: string
}
