/**
 * Valida se a string passada é um email (contém @ e .)
 * @param value String
 * @returns boolean
 */
export const isEmail = (email: string) => {
  const regex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2,})?$/
  return regex.test(String(email).toLowerCase())
}

/**
 * Valida se a string passada é um telefone celular
 * @param value String
 * @returns boolean
 */
export const isCelphone = (value: string) => {
  if (value.length >= 15) {
    const cel = value.split(' ')
    const valid = cel[1] ? cel[1].search('[^9]') : 0
    if (valid > 0) {
      return true
    }
    return false
  }
  return false
}

/**
 * Valida se a string passada é um cpf
 * @param value String
 * @returns boolean
 */
export const isCpf = (value: string) => {
  if (!value) {
    return false
  }
  const cpf = value.replace(/[^\d]+/g, '')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i), 10) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9), 10)) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i), 11) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10), 11)) return false
  return true
}

/**
 * Valida se a string passada é uma data
 * @param value String
 * @returns boolean
 */
export const isDate = (date: string) => {
  if (!date) return false
  const validateDate = () => {
    const yearReg = '(\\d{4})'
    const monthReg = '(0[1-9]|1[0-2])'
    const dayReg = '(0[1-9]|1[0-9]|2[0-9]|3[0-1])'
    const regExp = new RegExp(`^(?:${dayReg}/${monthReg}/${yearReg})`, 'g')

    return regExp.test(date)
  }
  return (
    Object.getPrototypeOf(date) instanceof Date ||
    (typeof date === 'string' && date.includes('/') && validateDate())
  )
}

// Função para validar a data de nascimento no formato 'Tue Oct 22 2024'
export function isValidDate(dataNascimento: string): boolean {
  // Expressão regular para validar o formato e os componentes individuais da data
  const regex =
    /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{2}) (\d{4})$/

  // Verifica se a string corresponde ao padrão da expressão regular
  if (!regex.test(dataNascimento)) {
    return false
  }

  // Divide a data em partes
  const [, month, day, year] = dataNascimento.split(' ')

  // Tentativa de criar um objeto Date a partir dos componentes
  const date = new Date(`${month} ${day}, ${year}`)

  // Verificar se o objeto Date é válido e corresponde aos componentes estimados
  // Porque o JavaScript Date objeto pode criar datas inválidas como "Feb 30"
  const isValid =
    date.toString() !== 'Invalid Date' &&
    date.getDate() === parseInt(day, 10) &&
    date.getFullYear() === parseInt(year, 10)

  return isValid
}

export function validarContrato(contrato: string): boolean {
  // Remove espaços em branco à esquerda e à direita
  contrato = contrato.trim()

  // Verifica se não contém caracteres especiais e é alfanumérico
  const regex = /^[a-zA-Z0-9]+$/

  if (contrato.length > 40 || !regex.test(contrato)) {
    return false
  }

  return true
}

export const validaNome = (nomeValido: string) => {
  const nomeArray = nomeValido.split(' ')
  return nomeArray.filter((element) => element.length >= 2).length >= 2
}
