import { ConsultaMargemGeralRequest } from '@services/postConsultaMargemGeral'
import { PortabilidadeState } from '@store/portabilidade'
import { ConveniosCodes } from '@utils/enums'
import { datePtToEn } from '@utils/functions'

export const modelMargemGeral = (portabilidade: PortabilidadeState) => {
  let bodyRequest: ConsultaMargemGeralRequest = {
    documento: portabilidade.dadosPessoais?.cpf,
    origem: 'NOVO',
    codigoConvenio: Number(portabilidade.dadosProfissionais.convenioSelecionado),
    codigoOrgao: Number(portabilidade.dadosProfissionais.orgaoSelecionado),
  }

  if (ConveniosCodes.INSS === Number(portabilidade.dadosProfissionais.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(portabilidade.situacaoFuncional?.matricula),
    }
  } else if (
    ConveniosCodes.SIAPE_SERVIDOR_FEDERAL ===
    Number(portabilidade.dadosProfissionais.convenioSelecionado)
  ) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(portabilidade.situacaoFuncional?.matricula),
      dataNascimento: datePtToEn(portabilidade.dadosPessoais.dataNascimento),
    }
  }

  return bodyRequest
}
