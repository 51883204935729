import React from 'react'

import { currencyToFloat, formatCurrency } from '@utils/masks'

import { CustomInput } from './styles'

interface OwnProps {
  id: string
  label?: string
  placeholder?: string
  infoButton?: JSX.Element
  salario: number | ''
  onChange: (ev: number) => void
  disabled?: boolean
}

const SalarioInput = ({
  id,
  label,
  placeholder,
  infoButton,
  salario,
  onChange,
  disabled,
}: OwnProps) => (
  <CustomInput
    id={id}
    type="currency"
    dataTestId={id}
    label={label}
    placeholder={placeholder}
    value={formatCurrency(salario)}
    onChange={(e) => onChange(currencyToFloat((e.target as HTMLInputElement).value))}
    helper={infoButton}
    disabled={disabled}
    maxLength={13}
  />
)

SalarioInput.defaultProps = {
  label: 'Qual é o seu salário bruto?',
  placeholder: 'Informe o valor total',
  infoButton: <></>,
  disabled: false,
}

export default SalarioInput
