import { call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import { setOrgaoSelecionado } from '@store/portabilidade/dadosProfissionais/actions'

import { orgaosError, orgaosRequest, orgaosSuccess } from '../actions'

export default function* getOrgaos(action: AnyAction) {
  const { payload } = action

  try {
    const response = yield* call(services.getOrgaos, payload)
    const { orgaosPorConvenioConsulta } = response.data
    const data = Object.values(orgaosPorConvenioConsulta).filter(
      (value) => !!value && !!value?.codigo,
    )
    yield put(orgaosSuccess(data))
    if (data.length === 1) {
      const [orgao] = data
      yield put(setOrgaoSelecionado(orgao.codigo))
    }
  } catch (error) {
    yield put(orgaosError())
    yield put(
      showError({
        title: 'Erro ao buscar órgãos',
        message: 'Ocorreu um erro listagem de órgãos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_006_ORGAOS,
        actionCallback: () => orgaosRequest(payload),
      }),
    )
  }
}
