import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { validaEnvHomeByBridge } from '@utils/functions'
import GridStyled from '@atoms/GridStyled'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/colors'
import Timer from '@icons/Timer'
import NovoPage from '@templates/NovoPage'
import useTicTac from '@hooks/useTicTac'
import { useTrackingPortabilidade } from '@hooks'
import { StepPortabilidadeNovo } from '@utils/enums'
import useEditBar from '@hooks/useEditBar'
import Tags from '@utils/Tags'

const EntraremosContato = () => {
  const { useTac } = useTicTac()
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  // TODO: verificar se nessa tela vai haver alguma chamada de enviar lead, pois no figma diz que enviariamos um lead qnd caisse aqui em caso de erro
  useTac({
    name: 'entraremos-contato',
    module: 'portabilidade_consignado',
    actions: ['retornar_margin_result', 'trazer_contrato', 'trazer_contrato_resultado'],
    params: {},
  })

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Feedback: problema tecnico inter',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Entraremos em contato',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado])

  useTrackingPortabilidade(StepPortabilidadeNovo.DADOS_RECEBIDOS)
  useEditBar('Trazer contrato')

  return (
    <NovoPage
      id="entraremos-em-contato"
      stickyFooter={
        <>
          <Button
            variant="primary"
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Feedback: problema tecnico inter',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Ir para a home',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
            }}
          >
            Ir para a home
          </Button>
        </>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <GridStyled justify="space-around">
          <Timer />
        </GridStyled>
        <ColorWrapper
          color={Colors.GRAY500}
          fontWeight="600"
          margin="24px 0 16px 0"
          style={{ textAlign: 'center' }}
        >
          <TextParagraph variant="headline-h1">Entraremos em contato</TextParagraph>
        </ColorWrapper>

        <TextParagraph variant="body-3" textAlign="center" margin="16px 0 0 0">
          Assim, você conseguirá dar sequência a sua contratação com um consultor.
        </TextParagraph>
      </div>
    </NovoPage>
  )
}

export default EntraremosContato
