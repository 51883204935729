import { BaseServices, HttpMethod } from '@services/base'
import { EstadosAPI } from '@store/globalApi/types'

export default () => {
  const pathname = process.env.REACT_APP_GET_ESTADOS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<EstadosAPI>({
    pathname,
    method,
    host,
  })
  return response
}
