import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Colors } from '@utils/colors'

export const CheckboxCustom = styled(Checkbox)`
  & label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
export const LinkTermoAutorizacao = styled(Link)`
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`

export const FooterGrid = styled.div`
  background-color: transparent;
  margin-top: auto;
`
