import { action, Action } from 'typesafe-actions'

import { ContratoAtualTypes } from './types'

export const setBancoSelecionado = (payload: string | number | undefined): Action =>
  action(ContratoAtualTypes.SET_BANCO_SELECIONADO, payload)
export const setvalorParcela = (payload: string | number): Action =>
  action(ContratoAtualTypes.SET_VALOR_PARCELA, payload)
export const setSaldoDevedorContratoAtual = (payload: string | number): Action =>
  action(ContratoAtualTypes.SET_SALDO_DEVEDOR, payload)
export const setNumeroContrato = (payload: string): Action =>
  action(ContratoAtualTypes.SET_NUMERO_CONTRATO, payload)
export const setParcelasRestantes = (payload: number | string): Action =>
  action(ContratoAtualTypes.SET_PARCELAS_RESTANTES, payload)
