import React from 'react'

import { useSelector } from 'react-redux'

import { Page } from '@templates'
import { validaEnvHomeByBridge } from '@utils/functions'
import { GridStyled, Paragraph } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { useTrackingPortabilidade } from '@hooks'
import { StepPortabilidade } from '@utils/enums'
import ChevronRightIcon from '@interco/icons/core/action-navigation/ic_chevron_right'
import SuccessIcon from '@icons/SuccessIcon'

import { Banner, DivP, P } from './styles'

const DadosRecebidos = () => {
  useTrackingPortabilidade(StepPortabilidade.DADOS_RECEBIDOS)

  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)

  return (
    <Page
      id="page-dados-recebidos"
      stickyFooter={
        <>
          <Button
            variant="secondary"
            icon={<ChevronRightIcon color={Colors.PRIMARY500} width={25} height={25} />}
            onClick={() => {
              window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack), true) || ''
            }}
          >
            Voltar para a home
          </Button>
        </>
      }
    >
      <GridStyled margin="16px 0 0">
        <SuccessIcon />
      </GridStyled>
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="40px 0 0"
        textAlign="left"
      >
        Entraremos em contato
      </Paragraph>
      <Paragraph
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        margin="8px 10px 0 0"
        textAlign="left"
      >
        Em breve entraremos em contato sobre a simulação da sua portabilidade.
        <br />É só aguardar, combinado?
      </Paragraph>
      <>
        {!isApp && (
          <Banner
            onClick={() => {
              window.location.href = process.env.REACT_APP_CONTA_DIGITAL || ''
            }}
          >
            <DivP>
              <P
                variant="sora"
                fontWeight="600"
                fontSize="20px"
                lineHeight="25px"
                color={Colors.PRIMARY500}
                textAlign="left"
              >
                Abra sua conta no Inter
              </P>
              <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY400} textAlign="left">
                Abra sua conta gratuitamente e tenha acesso a toda plataforma Inter!
              </Paragraph>
            </DivP>
          </Banner>
        )}
      </>
    </Page>
  )
}

export default DadosRecebidos
