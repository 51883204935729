import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/colors'

import { InputHelperButtonProps } from './props'
import { Button } from './styles'

export const InputHelperButton = ({
  text,
  fontWeight,
  margin,
  fontSize,
  lineHeight,
  textAlign,
  onClick,
}: InputHelperButtonProps) => (
  <Button type="button" onClick={onClick}>
    <Paragraph
      color={Colors.PRIMARY500}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      margin={margin}
      textAlign={textAlign}
    >
      {text}
    </Paragraph>
  </Button>
)

InputHelperButton.defaultProps = {
  text: 'Saiba mais',
  fontWeight: '400',
  margin: '0',
  lineHeight: '14px',
  fontSize: '12px',
}
