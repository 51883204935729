import { Reducer } from 'redux'

import { DadosPessoaisState, DadosPessoaisTypes } from './types'

const INITIAL_STATE: DadosPessoaisState = {
  nome: '',
  cpf: '',
  dataNascimento: '',
}

const reducer: Reducer<DadosPessoaisState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosPessoaisTypes.SET_NOME:
      return { ...state, nome: action.payload }
    case DadosPessoaisTypes.SET_CPF:
      return { ...state, cpf: action.payload }
    case DadosPessoaisTypes.SET_DATA_NASCIMENTO:
      return { ...state, dataNascimento: action.payload }
    case DadosPessoaisTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
