import * as React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'

import { Container, Content, ContainerFooter } from './styles'
import { PageProps } from './props'

const Page = ({ children, stickyFooter }: PageProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  return (
    <Container id="page-container">
      <Content isWeb={!isApp} id="page-content">
        {children}
      </Content>
      {stickyFooter && <ContainerFooter id="page-sticky-footer">{stickyFooter}</ContainerFooter>}
    </Container>
  )
}

export default Page
