import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const LineInfo = styled.div<{ removeBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${Colors.GRAY200}`)};
`
export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
`
export const CircleIcon = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${Colors.GRAY200};
  align-items: center;
  justify-content: center;
`
export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
`
