import React from 'react'

import { useSelector } from 'react-redux'

import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'

import { Dot, OrangeText } from '../../styles'

interface OwnProps {
  setOpenBottomsheet: React.Dispatch<React.SetStateAction<boolean>>
}

const ondeEncontrarDadosList = [
  'No contrato de empréstimo que deseja trazer para o Inter.',
  'No seu Documento Descritivo de Crédito (DDC)',
]

const OndeEncontrar = ({ setOpenBottomsheet }: OwnProps) => {
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  return (
    <BottomSheet onClose={() => setOpenBottomsheet(false)} noSwipe>
      <ColorWrapper fontWeight="600" margin="0 0 24px 0">
        <TextParagraph variant="headline-h3">Você pode encontrar os dados:</TextParagraph>
      </ColorWrapper>

      <>
        {ondeEncontrarDadosList.map((informacao, index) => (
          <>
            <ColorWrapper
              margin="0 0 16px 0"
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Dot />
              <TextParagraph variant="body-3" margin="0">
                {informacao}
                <br />
                {ondeEncontrarDadosList[convenioSelecionado as 6 | 3].length === index + 1 ? (
                  <OrangeText
                    onClick={() => {
                      setOpenBottomsheet(false)
                      // TODO: chamar o bottomsheet de passo a passo
                    }}
                  >
                    Saiba como solicitar
                  </OrangeText>
                ) : (
                  ''
                )}
              </TextParagraph>
            </ColorWrapper>
          </>
        ))}
      </>

      <Button
        fullWidth
        style={{ marginTop: '8px' }}
        onClick={() => {
          setOpenBottomsheet(false)
        }}
      >
        Entendi
      </Button>
    </BottomSheet>
  )
}

export default OndeEncontrar
