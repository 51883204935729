import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const LineInfo = styled.div<{ removeBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${Colors.GRAY200}`)};
`
