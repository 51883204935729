import { BaseServices, HttpMethod } from '@services/base'

export default (data: ConsultaMargemGeralRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_CONSULTA_MARGEM_GERAL
    : process.env.REACT_APP_CONSULTA_MARGEM_GERAL
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ConsultaMargemGeralResponse>({
    pathname,
    method,
    host,
    data: {
      ...data,
    },
  })

  return response
}

export interface ConsultaMargemGeralRequest {
  documento: string
  matricula?: string
  senhaServidor?: string
  vinculo?: string
  idFuncional?: string
  numeroPensionista?: string
  codigoConvenio?: number
  codigoOrgao?: number
  origem: string
  matriculaMultipla?: boolean
  dataNascimento?: string
}

export interface InfoInss {
  banco: string
  agencia: string
  conta: string
  digitoConta: string
  tipoCredito: string
  margem: number
  nome: string
  valorSalarioLiquido: number
}

export interface ConsultaMargemGeralResponse {
  isMatriculaValida: boolean
  isConsultaRealizada: boolean
  isHorarioValido: boolean
  pendencia: string
  infoInss: InfoInss
  tentativas: number
  margem: number
  situacaoFuncional: string
  indicativo: string
  dataIngresso: string
  matricula?: string
  dataValidadeAutorizacao: string
}
