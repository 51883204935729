import React, { PropsWithChildren } from 'react'

import { Text } from '@interco/inter-ui/components/Text'

import { CustomIconContainer, TimelineItemContainer, VerticalBar } from './styles'

type TimelineType = 'default' | 'disabled'

type TimelineItemProps = {
  title: string
  icon: React.ReactNode
  type?: TimelineType
  verticalBar: boolean
}

export const TimelineItem = ({
  title,
  icon,
  type = 'default',
  verticalBar,
  children,
}: PropsWithChildren<TimelineItemProps>) => (
  <TimelineItemContainer>
    <div style={{ minWidth: '24px' }}>
      <CustomIconContainer>{icon}</CustomIconContainer>
      {verticalBar ? <VerticalBar /> : <></>}
    </div>

    <div style={{ paddingLeft: '15px', paddingBottom: '15px', paddingTop: '8px', width: '100%' }}>
      {title && (
        <Text
          color="typography"
          colorWeight={type === 'disabled' ? 400 : 500}
          bold
          variant="body-3"
        >
          {title}
        </Text>
      )}

      <Text color="typography" colorWeight={type === 'disabled' ? 200 : 400} variant="caption-1">
        <p>{children}</p>
      </Text>
    </div>
  </TimelineItemContainer>
)
