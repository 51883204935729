import styled from 'styled-components'

import imageBanner from '@images/imageBanner.png'
import { Colors } from '@utils/colors'
import { Paragraph } from '@atoms'

export const Banner = styled.button`
  width: 100%;
  background-image: url(${imageBanner});
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 100% 40%;
  border: 1px solid ${Colors.GRAY200};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 21px 0 21px 16px;
  margin: 40px 0 auto;
`

export const P = styled(Paragraph)`
  max-width: 180px;
`

export const DivP = styled.div`
  width: 170px;
`
