import routes from '@routes/routes'
import { FontesPagamento } from '@utils/enums'

interface Order {
  [codigo: number]: number
}

interface Convenios {
  codigo: number
  descricao: string
  detalhes?: string
}

export const homeConvenios: Convenios[] = [
  {
    codigo: FontesPagamento.INSS,
    descricao: 'INSS',
    detalhes: 'Aposentado ou pensionista',
  },
  {
    codigo: FontesPagamento.SIAPE,
    descricao: 'SIAPE',
    detalhes: 'Servidor público federal',
  },
  {
    codigo: FontesPagamento.DEFAULT,
    descricao: 'Outro convênio',
  },
]

export const outrosConvenios: Convenios[] = [
  {
    codigo: FontesPagamento.ESTADUAL,
    descricao: 'Servidor público estadual',
  },
  {
    codigo: FontesPagamento.MUNICIPAL,
    descricao: 'Servidor público municipal',
  },
  {
    codigo: FontesPagamento.FORCAS_ARMADAS,
    descricao: 'Forças armadas',
  },
  {
    codigo: FontesPagamento.FEDERAL,
    descricao: 'STF e TCU',
  },
  {
    codigo: FontesPagamento.TRIBUNAIS,
    descricao: 'Tribunais',
  },
  {
    codigo: FontesPagamento.CLT,
    descricao: 'Funcionário de empresa privada',
  },
]

export const groupOrder: Order = {
  [FontesPagamento.INSS]: 1,
  [FontesPagamento.SIAPE]: 2,
  [FontesPagamento.ESTADUAL]: 3,
  [FontesPagamento.FORCAS_ARMADAS]: 4,
  [FontesPagamento.MUNICIPAL]: 5,
  [FontesPagamento.FEDERAL]: 6,
  [FontesPagamento.TRIBUNAIS]: 7,
  [FontesPagamento.CLT]: 8,
  [FontesPagamento.AUTONOMO]: 9,
}

export const getNextPage = (fonteSelecionada: number | undefined) => {
  switch (fonteSelecionada) {
    case FontesPagamento.MUNICIPAL:
    case FontesPagamento.ESTADUAL:
    case FontesPagamento.FEDERAL:
    case FontesPagamento.TRIBUNAIS:
    case FontesPagamento.FORCAS_ARMADAS:
    case FontesPagamento.CLT:
    case FontesPagamento.AUTONOMO:
      return routes.ONBOARDING
    default:
      return undefined
  }
}
