import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postValidaPortabilidade, { ValidaRequest } from '@services/postValidaPortabilidade'
import { ApplicationState } from '@store/types'
import { findUpag } from '@utils/functions'

import { ValidaRequestPayload } from '../types'
import { setLoading } from '../actions'

export default function* postValida(action: AnyAction) {
  const payload = action.payload as ValidaRequestPayload
  try {
    yield put(setLoading(true))
    const { dadosPessoais, dadosProfissionais, fontePagamento } = yield select(
      (state: ApplicationState) => state.portabilidade,
    )
    const globalApi = yield* select((state: ApplicationState) => state.globalApi)

    const validarCPF: ValidaRequest = {
      cpf: dadosPessoais?.cpf,
      upag: findUpag(globalApi.upags, String(dadosProfissionais?.upag)),
      estado: fontePagamento?.estadoSelecionado,
      cidade: fontePagamento?.cidadeSelecionada,
      idConvenio: fontePagamento?.convenioSelecionado,
    }

    const response = yield* call(postValidaPortabilidade, validarCPF)
    yield payload.callback({
      contato: response?.data?.contato,
    })
  } catch (error) {
    yield put(setLoading(false))
    yield payload.callback({
      contato: false,
    })
  }
}
