import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarLeadRequest) => {
  const pathname = process.env.REACT_APP_POST_SALVAR_PORTABILIDADE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SalvarLeadRequest {
  origem: string
  bancoContratoAtual: string
  numeroContrato: string
  saldoDevedor: string
  quantidadeParcelasFalta: string
  valorParcela: string
  convenioEstado: string
  convenioCidade: string
  convenioId: string
  convenioDesc: string
  situacaoFuncional: string
  situacaoFuncionalMotivo: string
  upag: string
  matricula: string
  salarioBruto: string
  primeiroNome: string
  segundoNome: string
  cpf: string
  dataNascimento: string
  telefoneCelular: string
  email: string
  valorParcelaNovo: string
  taxaNominalMensal: string
  taxaNominalMensalNovo: string
  valorEconomia: string
  valorSolicitado: string
  valorFinanciado: string
  orgaoId: string
  orgaoDescricao: string
  clientId: string
  familiaId: string
  familiaDescricao: string
  utmSource: string
  utmMedium: string
  utmContent: string
  utmCampaign: string
  facebookId: string
  gaClientId: string
  gaClid: string
  formaContato: string
  dataIngressoExercito: string
  indicativo: string
  patente: string
  origemSimulacao: string
  margem: number
}
