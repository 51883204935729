/**
 * Actions Types
 */
export enum ValidaTypes {
  VALIDA_REQUEST = '@valida/VALIDAR_REQUEST',
  VALIDA_SET_LOADING = '@valida/VALIDA_SET_LOADING',

  RESET = 'RESET',
}

export interface CallBackContatoType {
  readonly contato: boolean
}

export type ValidaRequestPayload = {
  callback: (params: CallBackContatoType) => void
}

export interface ValidaState {
  readonly loading: boolean
}
