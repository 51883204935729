import { BaseServices, HttpMethod } from '@services/base'
import { SimulateDataResponse } from '@store/portabilidade/simulacao/types'

export interface SimulateData {
  debtBalance: number
  installmentValue: number
}

export default (convenioSelecionado: string, data: SimulateData) => {
  const pathname = `${process.env.REACT_APP_SIMULATE_PORTABILITY}/${convenioSelecionado}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<SimulateDataResponse>({
    pathname,
    method,
    host,
    data,
  })
}
