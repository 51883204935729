import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import NovoPage from '@templates/NovoPage'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/colors'
import imageOnboardingV2 from '@images/imageOnboardingV2.png'
import { Image } from '@atoms/Image'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'
import useEditBar from '@hooks/useEditBar'
import useTicTac from '@hooks/useTicTac'
import Tags from '@utils/Tags'
import { ApplicationState } from '@store/types'

import { Dot } from './styles'

const Onboarding = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendTic } = useTicTac()
  const { utmCampaign } = useSelector((state: ApplicationState) => state.session)

  useEditBar('Trazer contrato')

  useEffect(() => {
    // TODO: aqui vai ter um tipo de consulta pra saber se o usuario já passou por essa tela antes
    Tags.enviarTagueamentoApp({
      ref_figma: '1',
      name: 'CONSIGNADO_PORT_ACESSO',
      screen: 'Onboarding',
      content_action: 'Dado carregado',
      product: 'PORTABILIDADE_CONSIGNADO',
      utm_campaign: utmCampaign,
    })
  }, [utmCampaign])

  return (
    <NovoPage
      id="onboarding-v2"
      stickyFooter={
        <Button
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidadeNovo.ONBOARDING))
            sendTic({
              name: 'btn_continuar',
              module: 'portabilidade_consignado',
              action: 'continuar_portabilidade',
              params: {},
            })
            Tags.enviarTagueamentoApp({
              ref_figma: '2',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Onboarding',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Continuar',
              product: 'PORTABILIDADE_CONSIGNADO',
            })
            navigate(routes.SELECAO_GRUPO_V2)
          }}
        >
          Continuar
        </Button>
      }
    >
      <Image src={imageOnboardingV2} alt="Homem olhando o celular" />
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="24px 0">
        <TextParagraph variant="headline-h1">
          Traga seus contratos e refinancie com troco e as melhores taxas!
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper
        margin="0 0 16px 0"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Dot />
        <TextParagraph variant="body-3" margin="0">
          Processo 100% digital e seguro
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper
        margin="0 0 8px 0"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Dot />
        <TextParagraph variant="body-3">Dinheiro rápido na sua conta</TextParagraph>
      </ColorWrapper>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Dot />
        <ColorWrapper margin="0 0 16px 0" style={{ width: '80%' }}>
          <TextParagraph variant="body-3">
            Tranquilidade com parcelas descontadas diretamente do seu benefício ou folha de
            pagamento.
          </TextParagraph>
        </ColorWrapper>
      </div>
    </NovoPage>
  )
}
export default Onboarding
