import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'

/**
 * Header com titulo do bottomsheet.
 */
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px 8px;
`
export const DivLoading = styled.div`
  margin: auto 0;
  text-align: center;
`
export const ButtonReenviarCodigo = styled(Button)`
  margin: 10px auto 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  display: flex;
`

export const ButtonCancelar = styled(Button)`
  margin-top: 16px;
`
