import { action, Action } from 'typesafe-actions'

import { SituacaoFuncionalTypes, ValidarMatriculaPayload } from './types'

export const setSituacaoFuncional = (payload: string): Action =>
  action(SituacaoFuncionalTypes.SET_SITUACAO_FUNCIONAL, payload)

export const setUpag = (payload: string): Action => action(SituacaoFuncionalTypes.SET_UPAG, payload)

export const setMotivo = (payload: string): Action =>
  action(SituacaoFuncionalTypes.SET_MOTIVO, payload)

export const setPatente = (payload: string): Action =>
  action(SituacaoFuncionalTypes.SET_PATENTE, payload)

export const setAnoIngresso = (payload: number): Action =>
  action(SituacaoFuncionalTypes.SET_ANO_INGRESSO, payload)

export const setMatricula = (payload: string): Action =>
  action(SituacaoFuncionalTypes.SET_MATRICULA, payload)

export const setIsMatriculaValida = (payload: boolean): Action =>
  action(SituacaoFuncionalTypes.SET_MATRICULA_VALIDA, payload)

export const validarMatriculaRequest = (payload: ValidarMatriculaPayload): Action =>
  action(SituacaoFuncionalTypes.VALIDAR_MATRICULA, payload)
