import React from 'react'

import { useSelector } from 'react-redux'

import { Page } from '@templates'
import { validaEnvHomeByBridge } from '@utils/functions'
import { Button } from '@interco/inter-ui/components/Button'
import ErrorIcon from '@icons/ErrorIcon'
import { GridStyled, Paragraph } from '@atoms'
import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'

const NaoLiberado = () => {
  const { origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)
  return (
    <Page
      id="page-nao-liberado"
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            window.location.href = validaEnvHomeByBridge(true, String(origemGoBack)) || ''
          }}
        >
          Voltar ao início
        </Button>
      }
    >
      <GridStyled margin="16px 0 0">
        <ErrorIcon />
      </GridStyled>

      <Paragraph
        variant="sora"
        fontSize="24px"
        fontWeight="600"
        lineHeight="30px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="40px 0 16px"
      >
        No momento não conseguimos te oferecer a Portabilidade
      </Paragraph>
      <Paragraph textAlign="left" variant="inter">
        Em nossas validações internas, vimos que não será possível oferecer a portabilidade
        solicitada.
      </Paragraph>
    </Page>
  )
}
export default NaoLiberado
