import styled from 'styled-components'

import { Colors } from '@utils/colors'

/**
 * Div principal.
 */
export const Container = styled.div<{ margin?: string }>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  position: relative;
  width: 100%;
  .group-search {
    position: relative;
    margin: 0 0 5px;

    .inter-ic-search {
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: translateX(-60px);
    }

    .inter-ic-contextual-error {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5%;
      margin: auto;
      &:hover {
        cursor: pointer;
      }
    }

    input {
      text-align: center;
      background-color: ${Colors.WHITE};
      width: calc(100% - 32px);
      padding: 14px 16px;
      border-radius: 8px;
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: 700;
      color: #161616;
    }
  }
`

/**
 * Label a ser exibida acima do dropdown.
 */
export const Label = styled.div<{
  disabled?: boolean
  useHelperButton?: boolean
  isHelperIcon?: boolean
}>`
  display: flex;
  justify-content: ${(props) => (props.isHelperIcon ? 'flex-start' : 'space-between')};
  margin: 0 0 4px 2px;
  font-size: 0.875rem;
  line-height: 1.063rem;
  align-items: ${(props) => (props.isHelperIcon ? 'center' : '')};
  color: ${(props) => (props.disabled ? Colors.GRAY300 : Colors.GRAY400)};
`

/**
 * Input fake para o Select
 */
export const InputSelect = styled.div<{
  disabled?: boolean
  placeholder?: string | undefined
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  background-color: ${Colors.GRAY100};
  border-radius: 8px;

  p {
    width: 100%;
    margin-right: 4px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${Colors.GRAY500};
    ${(props) =>
      (props.disabled && `color: ${Colors.GRAY200}`) ||
      (props.placeholder && `color: ${Colors.GRAY300}`)};
  }

  span {
    width: 20px;
  }
`

/**
 * Div overlay.
 */
export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
`

/**
 * Div container para lista de opções exibidas no bottom sheet.
 */
export const SelectArea = styled.div`
  position: relative;
  width: 100%;
  min-height: 50px;
  z-index: 99;
  background-color: ${Colors.GRAY100};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  padding: 8px 24px;
  transition: height 2s;
  transition-duration: 0.5s;
  transition-delay: 0.1s;
`

/**
 * Div container para lista de opções exibidas no bottom sheet.
 */
export const ContentOptions = styled.div`
  max-height: 170px;
  min-height: 50px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    display: block;
    background-color: ${Colors.GRAY200};
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: ${Colors.GRAY300};
  }
`

/**
 * Div para o item das opções exibidas no bottom sheet.
 */
export const Option = styled.div`
  padding: 13px 0;
`

/**
 * Botão auxiliar do select.
 */
export const HelpButton = styled.button`
  align-items: center;
  font-family: Inter;
  font-size: 0.75rem;
  line-height: 0.938rem;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  color: ${Colors.PRIMARY500};
`
