import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { AlertModal } from '@interco/inter-ui/components/Modal/AlertModal'
import { ConfirmationModal } from '@interco/inter-ui/components/Modal/ConfirmationModal'

/**
 * Header com titulo do bottomsheet.
 */
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LargeButton = styled(Button)`
  width: 100%;
`

export const SpaceBetween = styled.div`
  height: 16px;
`

export const ModalAlert = styled(AlertModal)`
  padding: 24px;
`

export const ModalConfirmation = styled(ConfirmationModal)`
  padding: 24px;
`
