import styled from 'styled-components'

export const TimelineContainer = styled.ul``

export const TimelineItemContainer = styled.li`
  list-style: none;
  display: flex;
  position: relative;
  margin: 0;
  list-style: none;
`

export const CustomIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    margin-top: 2px;
  }
`

export const VerticalBar = styled.div`
  position: absolute;
  top: 32px;
  left: 11px;
  background-color: #dedfe4;
  border-radius: 1px;
  width: 2px;
  height: calc(100% - 36px);
`
