import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { Box } from '@atoms/Box'
import { Loading } from '@atoms/Loading'
import { FontesPagamento, StepPortabilidade } from '@utils/enums'
import { conveniosRequest, estadosRequest, fontesRequest } from '@store/globalApi/actions'
import { Page } from '@templates'
import { useTrackingPortabilidade } from '@hooks'
import {
  setConvenioSelecionado,
  setFonteSelecionada,
} from '@store/portabilidade/dadosProfissionais/actions'
import routes from '@routes/routes'
import { getHoraBaseINSS } from '@utils/functions'
import { DivLoading } from '@organisms/PageToken/styles'
import { BridgeService } from '@services/bridge'

import { FullParagraph, Grid, RadioCustom } from './styles'

const SelecaoGrupo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { fontesPagamento } = useSelector((state: ApplicationState) => state.globalApi)
  const { fonteSelecionada, convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const loading = useSelector((state: ApplicationState) => state.globalApi.fontesPagamentoLoading)
  const horaAtual = new Date().getHours().toLocaleString()

  useTrackingPortabilidade(StepPortabilidade.FONTE_PAGAMENTO_GRUPO)

  useEffect(() => {
    if (!fontesPagamento || fontesPagamento.length === 0) {
      dispatch(fontesRequest())
    }
  }, [dispatch, fontesPagamento])

  useEffect(() => {
    if (convenioSelecionado !== '') {
      dispatch(setConvenioSelecionado(''))
    }
  }, [convenioSelecionado, dispatch])

  const navigateNextPage = () => {
    dispatch(conveniosRequest({ fontePagamento: Number(fonteSelecionada) }))
    dispatch(setFonteSelecionada(Number(fonteSelecionada)))

    if (fonteSelecionada === FontesPagamento.FORCAS_ARMADAS) {
      navigate(routes.CONVENIO_FORCAS_ARMADAS)
    } else if (fonteSelecionada === FontesPagamento.INSS && getHoraBaseINSS(horaAtual)) {
      navigate(routes.INSS_SIMPLIFICADO)
    } else if (
      (fonteSelecionada === FontesPagamento.INSS && !getHoraBaseINSS(horaAtual)) ||
      fonteSelecionada === FontesPagamento.SIAPE
    ) {
      dispatch(estadosRequest())
      navigate(routes.DADOS_PROFISSIONAIS)
    } else {
      navigate(routes.DADOS_PROFISSIONAIS)
    }
  }

  const filterBy = (codigo: number) => {
    if (BridgeService.isBrowser()) {
      return (
        codigo !== FontesPagamento.FEDERAL &&
        codigo !== FontesPagamento.CLT &&
        codigo !== FontesPagamento.AUTONOMO
      )
    }
    return (
      codigo !== FontesPagamento.INSS &&
      codigo !== FontesPagamento.SIAPE &&
      codigo !== FontesPagamento.FEDERAL &&
      codigo !== FontesPagamento.CLT &&
      codigo !== FontesPagamento.AUTONOMO
    )
  }

  if (loading) {
    return (
      <Page id="page-grupos">
        <DivLoading>
          <Loading width={80} height={80} />
        </DivLoading>
      </Page>
    )
  }

  return (
    <Page
      id="page-grupos"
      stickyFooter={
        <Button disabled={!fonteSelecionada} onClick={navigateNextPage}>
          Continuar
        </Button>
      }
    >
      <Paragraph
        fontFamily="Sora"
        fontSize="24px"
        lineHeight="30px"
        fontWeight={600}
        color={Colors.GRAY500}
      >
        Qual é o seu grupo?
      </Paragraph>
      <Paragraph margin="0 0 24px 0">
        Para personalizar a sua simulação, precisamos conhecer você melhor!
      </Paragraph>

      <>
        {fontesPagamento?.length ? (
          fontesPagamento
            ?.filter((convenio) => filterBy(convenio.codigo))
            .map((fontePag) => (
              <Box
                justify="flex-start"
                margin="0 0 16px"
                key={fontePag.codigo}
                actived={fonteSelecionada === fontePag.codigo}
                minHeight="0"
              >
                <Grid
                  onClick={() => {
                    dispatch(setFonteSelecionada(fontePag.codigo))
                  }}
                >
                  <RadioCustom
                    id={`input-radio-${fontePag.codigo}`}
                    name="input-radio"
                    reversed
                    checked={fonteSelecionada === fontePag.codigo}
                    onChange={() => {
                      dispatch(setFonteSelecionada(fontePag.codigo))
                    }}
                    value={`${fontePag.codigo}`}
                    key={fontePag.codigo}
                    label={
                      <FullParagraph
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="17px"
                        margin="0"
                        color={Colors.GRAY500}
                      >
                        {fontePag.descricao}
                      </FullParagraph>
                    }
                  />
                </Grid>
              </Box>
            ))
        ) : (
          <></>
        )}
      </>
    </Page>
  )
}

export default SelecaoGrupo
