import React, { useCallback, useEffect } from 'react'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ErrorMessage } from '@organisms'
import { enableDarkMode, initTheme, Theme } from '@interco/inter-ui'
import { interWbSession } from '@interco/inter-webview-bridge'
import { BridgeService } from '@services/bridge'

import Routes from '../routes'
import { store, persistor } from '../store'

import './App.scss'

const App = () => {
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()
    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
    }
  }, [])
  useEffect(() => {
    if (!BridgeService.isBrowser()) getAppInfo()
    initTheme(Theme.PF)
  }, [getAppInfo])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorMessage />
        <Routes />
      </PersistGate>
    </Provider>
  )
}

export default App
