import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Select } from '@atoms'
import { motivoOptions } from '@utils/functions'

import { CustomBottomSheet } from './styles'
import { MotivoProps } from './props'

const MotivoDropdown = ({
  id,
  motivo,
  situacaoFuncional,
  loading,
  onChange,
  disabled,
}: MotivoProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="E o motivo?"
        margin="0 0 20px 0"
        name="dropdown"
        value={motivo}
        hideSearch={false}
        options={motivoOptions(situacaoFuncional)}
        placeholder="Selecione o motivo"
        onChange={(value) => {
          onChange(value)
        }}
        loading={loading}
        disabled={disabled}
      />
    )
  }
  return (
    <CustomBottomSheet
      id={id}
      label="E o motivo?"
      name="dropdown"
      isUsingFilter
      value={motivo}
      onChange={(value) => {
        onChange(value)
      }}
      options={motivoOptions(situacaoFuncional)}
      placeholder="Selecione o motivo"
      isLoading={loading}
      disabled={disabled}
    />
  )
}
export default memo(MotivoDropdown)
