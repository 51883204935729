import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postValidaMatricula, { ValidaMatriculaRequest } from '@services/postValidaMatricula'
import { ApplicationState } from '@store/types'

import { setIsMatriculaValida } from '../actions'
import { ValidarMatriculaPayload } from '../types'

export default function* postValidarMatriculaSaga(action: AnyAction) {
  const payload = action.payload as ValidarMatriculaPayload
  try {
    const portabilidadeState = yield* select((state: ApplicationState) => state.portabilidade)
    const buildPostValidarMatricula: ValidaMatriculaRequest = {
      convenio: Number(portabilidadeState.dadosProfissionais.convenioSelecionado) || 0,
      cpf: portabilidadeState.dadosPessoais.cpf,
      matricula: portabilidadeState.dadosProfissionais.matricula || '',
      indicativo: 0,
      categoria: Number(portabilidadeState.situacaoFuncional.situacaoFuncional) || 0,
      orgao: Number(portabilidadeState.dadosProfissionais.orgaoSelecionado) || 0,
    }

    const { data } = yield* call(postValidaMatricula, buildPostValidarMatricula)
    yield put(setIsMatriculaValida(data.matriculaValida))
    yield payload.callback(data.matriculaValida)
  } catch (error) {
    yield put(setIsMatriculaValida(false))
  }
}
