/**
 * Actions Types
 */

export enum ConsultaMargemGeralTypes {
  SET_CHECK_CONSULTA_MARGEM_GERAL = '@portabilidade/consultaMargemGeral/SET_CHECK_CONSULTA_MARGEM_GERAL',
  CONSULTA_MARGEM_GERAL_SUCCESS = '@portabilidade/consultaMargemGeral/CONSULTA_MARGEM_GERAL_SUCCESS',
  CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK = '@portabilidade/consultaMargemGeral/CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK',
  CONSULTA_MARGEM_GERAL_ERROR = '@portabilidade/consultaMargemGeral/CONSULTA_MARGEM_GERAL_ERROR',
  CONSULTA_MARGEM_GERAL_REQUEST = '@portabilidade/consultaMargemGeral/CONSULTA_MARGEM_GERAL_REQUEST',
  SET_VALOR_MARGEM = '@portabilidade/consultaMargemGeral/geral/SET_VALOR_MARGEM',
  SET_DATA_VALIDADE_AUTORIZACAO = '@portabilidade/consultaMargemGeral/geral/SET_DATA_VALIDADE_AUTORIZACAO',

  ATUALIZAR_SIAPE_CACHE = '@novo/siapeAutorizarAnuencia/ATUALIZAR_SIAPE_CACHE',
  GET_SIAPE_CACHE = '@novo/siapeAutorizarAnuencia/GET_SIAPE_CACHE',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ConsultaMargemGeralState {
  readonly checkConsultaMargem: boolean
  readonly loading: boolean
  readonly error: boolean
  readonly consultaMargemAutorizada: boolean
  readonly margem: number
  readonly dataValidadeAutorizacao: string
}

export type ConsultaMargemGeralPayload = {
  callback: (margem: number, tentativas: number, pendencia: string) => void
}
