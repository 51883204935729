import React, { memo } from 'react'

import { Input } from '@atoms'

import { MatriculaProps } from './props'

const MatriculaInput = ({
  id,
  label,
  placeholder,
  infoButton,
  matricula,
  matriculaError = false,
  invalidText,
  onChange,
  onBlur,
  maxLength,
  disabled,
}: MatriculaProps) => (
  <Input
    id={id}
    dataTestId={id}
    label={label}
    placeholder={placeholder}
    value={matricula}
    error={matriculaError}
    infoText={matriculaError ? invalidText : ''}
    onChange={(e) => onChange((e.target as HTMLInputElement).value)}
    onBlur={onBlur}
    helper={infoButton}
    maxLength={maxLength}
    disabled={disabled}
  />
)

MatriculaInput.defaultProps = {
  label: 'Qual é sua matrícula?',
  placeholder: 'Informe os números',
  matriculaError: false,
  maxLength: undefined,
  onBlur: undefined,
  disabled: false,
  invalidText: 'Matrícula não encontrada',
}

export default memo(MatriculaInput)
