import styled from 'styled-components'

export const Container = styled.div`
  & > div {
    width: 100%;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  display: grid;
  justify-items: center;
`

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  & {
    align-content: flex-start;
  }
`
