import { call, put } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { bancosError, bancosRequest, bancosSuccess } from '../actions'

export default function* getBancos() {
  try {
    const response = yield* call(services.getBancos)
    const { bancos } = response.data
    const data = Object.values(bancos).filter((value) => !!value && !!value?.codigo)
    yield put(bancosSuccess(data))
  } catch (error) {
    yield put(bancosError())
    yield put(
      showError({
        title: 'Erro ao buscar os bancos',
        message: 'Ocorreu um erro ao buscar a listagem de bancos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_001_BANCOS,
        actionCallback: () => bancosRequest(),
      }),
    )
  }
}
