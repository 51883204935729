import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: ${Colors.GRAY500};
  border-radius: 50%;
  margin-right: 16px;
`
