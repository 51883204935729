import { action, Action } from 'typesafe-actions'

import { SendTokenPayload, ValidateTokenRequestPayload, ErrorPayload, TokenTypes } from './types'

export const tokenSuccess = (): Action => action(TokenTypes.TOKEN_SUCCESS)

export const setError = (payload: ErrorPayload): Action => action(TokenTypes.TOKEN_ERROR, payload)

export const sendToken = (payload: SendTokenPayload): Action =>
  action(TokenTypes.SEND_TOKEN_REQUEST, payload)

export const validateToken = (payload: ValidateTokenRequestPayload): Action =>
  action(TokenTypes.VALIDATE_TOKEN_REQUEST, payload)

export const resetToken = () => ({ type: TokenTypes.RESET_TOKEN })
