/**
 * Actions Types
 */

export enum SituacaoFuncionalTypes {
  SET_SITUACAO_FUNCIONAL = '@situacaoFuncional/SET_SITUACAO_FUNCIONAL',
  SET_MOTIVO = '@situacaoFuncional/SET_MOTIVO',
  SET_UPAG = '@situacaoFuncional/SET_UPAG',
  SET_PATENTE = '@situacaoFuncional/SET_PATENTE',
  SET_ANO_INGRESSO = '@situacaoFuncional/SET_ANO_INGRESSO',
  SET_MATRICULA = '@situacaoFuncional/SET_MATRICULA',
  SET_MATRICULA_VALIDA = '@situacaoFuncional/SET_MATRICULA_VALIDA',
  VALIDAR_MATRICULA = '@situacaoFuncional/VALIDAR_MATRICULA',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SituacaoFuncionalState {
  readonly situacaoFuncional?: string
  readonly motivo?: string
  readonly upagSelecionada?: string
  readonly patente?: string
  readonly anoIngresso?: number
  readonly matricula?: string | number
  readonly matriculaValida?: boolean
}

export type SituacaoFuncionalComponentsTypes =
  | 'upag'
  | 'patente'
  | 'prec-cp'
  | 'ano-ingresso'
  | 'motivo'
  | 'matricula'
  | 'salario'

export type ValidarMatriculaPayload = {
  callback: (isValido: boolean | undefined) => void
}
