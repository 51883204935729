import styled from 'styled-components'

import { Colors } from '@utils/colors'

import { ParagraphProps } from './props'

const fonts = {
  sora: 'sora',
  inter: 'inter',
}

export const resolveFontFamily = (props: ParagraphProps) => {
  if (props.fontFamily) {
    return props.fontFamily
  }
  const font = fonts[props.variant || 'inter']
  return font || 'inter'
}

const Paragraph = styled.p<ParagraphProps>`
  margin: ${({ margin }) => margin || '0 0 8px 0'};
  font-size: ${(props) => props.fontSize || '14px'};
  line-height: ${(props) => props.lineHeight || '17px'};
  font-weight: ${(props) => props.fontWeight || '400'};
  text-align: ${(props) => props.textAlign || 'unset'};
  color: ${(props) => props.color || Colors.GRAY400};
  font-family: ${resolveFontFamily};
`

export default Paragraph
