import { BaseServices, HttpMethod } from '@services/base'

export default (convenioDescricao: string) => {
  const pathname = `${process.env.REACT_APP_MARGIN_ACCEPT_RESULT}/${convenioDescricao}/query/accept`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
  })
}
