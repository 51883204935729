import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { InputHelperButton, Select } from '@atoms'
import { dialogVariants } from '@utils/bottomSheetVariants'

import { CustomBottomSheet } from './styles'
import { SituacaoFuncionalProps } from './props'

const SituacaoFuncionalDropdown = ({
  id,
  situacoesFuncionais,
  situacaoFuncional,
  onChange,
  setVariant,
  setOpen,
}: SituacaoFuncionalProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Qual é sua situação funcional?"
        name="dropdown"
        margin="0 0 20px 0"
        value={situacaoFuncional}
        hideSearch={false}
        options={situacoesFuncionais || []}
        placeholder="Selecione o tipo"
        onChange={(value) => {
          onChange(value)
        }}
        helperButton={<InputHelperButton />}
        onClickHelper={() => {
          setVariant(dialogVariants.situacaoFuncional)
          setOpen(true)
        }}
      />
    )
  }
  return (
    <>
      <InputHelperButton
        color={Colors.PRIMARY500}
        fontSize="12px"
        onClick={() => {
          setVariant(dialogVariants.situacaoFuncional)
          setOpen(true)
        }}
      >
        Saiba mais
      </InputHelperButton>
      <CustomBottomSheet
        buttonSaibaMais
        id={id}
        data-testid={id}
        value={situacaoFuncional}
        isUsingFilter
        label="Qual é sua situação funcional?"
        name="dropdown"
        onChange={(ev) => onChange(ev)}
        options={situacoesFuncionais || []}
        placeholder="Selecione o tipo"
      />
    </>
  )
}
export default memo(SituacaoFuncionalDropdown)
