import { SalvarLeadRequest } from '@services/postSalvarLeadPortabilidade'
import { ApplicationState } from '@store/types'
import { TrackPortabilidadeRequest } from '@services/postTrackingPortabilidade'
import { findSituacaoFuncionalSalesforce, findUpag, SituacaoFuncionalTypes } from '@utils/functions'
import { FontesPagamento, SituacoesForcasArmadas, StepPortabilidade } from '@utils/enums'
import { removeMask } from '@utils/masks'

const verificarMotivoInss = (situacaoFuncional: string, motivo: string) => {
  if (situacaoFuncional === 'Aposentados') {
    return motivo
  }
  if (situacaoFuncional === 'Pensionistas') {
    return 'Por morte'
  }
  return ''
}
export const buildLeadRequest = ({
  globalApi,
  ui,
  portabilidade,
  session,
}: ApplicationState): SalvarLeadRequest => ({
  bancoContratoAtual: String(portabilidade.contratoAtual.bancoSelecionado) || '',
  convenioCidade:
    globalApi.cidades?.find(
      (cidade) => cidade.codigo === String(portabilidade.dadosProfissionais.cidadeSelecionada),
    )?.nome || '',
  convenioEstado: String(portabilidade.dadosProfissionais.estadoSelecionado) || '',
  convenioId: String(portabilidade.dadosProfissionais.convenioSelecionado) || '',
  convenioDesc:
    globalApi.convenios?.find(
      (convenio) =>
        convenio.codigo === Number(portabilidade.dadosProfissionais.convenioSelecionado),
    )?.descricao || '',
  origem: ui.navigation.origem,
  numeroContrato: '',
  quantidadeParcelasFalta: '',
  saldoDevedor: '',
  valorParcela: portabilidade.contratoAtual.valorParcela || '',
  situacaoFuncional:
    portabilidade.dadosProfissionais.fonteSelecionada === FontesPagamento.FORCAS_ARMADAS
      ? findSituacaoFuncionalSalesforce(
          portabilidade.dadosProfissionais.fonteSelecionada as SituacaoFuncionalTypes,
          SituacoesForcasArmadas.find(
            (situacao) => situacao.value === portabilidade.situacaoFuncional.situacaoFuncional,
          )?.text || '',
        )
      : findSituacaoFuncionalSalesforce(
          portabilidade.dadosProfissionais.fonteSelecionada as SituacaoFuncionalTypes,
          String(portabilidade.situacaoFuncional.situacaoFuncional) || '',
        ),
  situacaoFuncionalMotivo:
    portabilidade.dadosProfissionais.fonteSelecionada === FontesPagamento.INSS
      ? String(portabilidade.situacaoFuncional.motivo)
      : '',
  upag: findUpag(globalApi.upags, String(portabilidade.situacaoFuncional.upagSelecionada)),
  matricula: removeMask(String(portabilidade.dadosProfissionais.matricula) || ''),
  salarioBruto: String(portabilidade.dadosProfissionais.salario) || '',
  primeiroNome: portabilidade.dadosPessoais.nome.split(' ').slice(0, 1).join(' ') || '',
  segundoNome:
    portabilidade.dadosPessoais.nome
      .split(' ')
      .slice(1, portabilidade.dadosPessoais.nome.length)
      .join(' ') || '',
  cpf: portabilidade.dadosPessoais.cpf || '',
  dataNascimento: portabilidade.dadosPessoais.dataNascimento.split('/').reverse().join('-'),
  telefoneCelular: portabilidade.contato.telefone,
  email: portabilidade.contato.email,
  valorParcelaNovo: String(portabilidade.simulacao.novoValorParcela) || '',
  taxaNominalMensal: String(portabilidade.simulacao.taxaNominalMensal) || '',
  taxaNominalMensalNovo: String(portabilidade.simulacao.novoTaxaNominalMensal) || '',
  valorEconomia: String(portabilidade.simulacao.valorEconomia) || '',
  valorSolicitado: String(portabilidade.simulacao.valorSolicitado) || '',
  valorFinanciado: String(portabilidade.simulacao.valorFinanciado) || '',
  orgaoId: String(portabilidade.dadosProfissionais.orgaoSelecionado) || '',
  orgaoDescricao:
    globalApi.orgaos?.find(
      (orgao) => orgao.codigo === Number(portabilidade.dadosProfissionais.orgaoSelecionado),
    )?.descricao || '',
  clientId: session.sessionId || '',
  familiaId: String(portabilidade.dadosProfissionais.fonteSelecionada) || '',
  familiaDescricao:
    globalApi.fontesPagamento?.find(
      (fonte) => fonte.codigo === Number(portabilidade.dadosProfissionais.fonteSelecionada),
    )?.descricao || '',
  utmSource: String(session.utmSource) || '',
  utmMedium: String(session.utmMedium) || '',
  utmContent: String(session.utmContent) || '',
  utmCampaign: String(session.utmCampaign) || '',
  facebookId: String(session.facebookId) || '',
  gaClientId: String(session.gaClientId) || '',
  gaClid: String(session.gaClid) || '',
  formaContato: '',
  dataIngressoExercito: String(portabilidade?.situacaoFuncional?.anoIngresso) || '',
  indicativo: '',
  patente: portabilidade?.situacaoFuncional.patente || '',
  origemSimulacao: 'SIMULADOR_PORTABILIDADE',
  margem: 0,
})

export const buildLeadRequestInss = ({
  globalApi,
  ui,
  portabilidade,
  session,
}: ApplicationState): SalvarLeadRequest => ({
  bancoContratoAtual: String(portabilidade.contratoAtual.bancoSelecionado) || '',
  convenioCidade:
    globalApi.cidades?.find(
      (cidade) => cidade.codigo === String(portabilidade.dadosProfissionais.cidadeSelecionada),
    )?.nome || '',
  convenioEstado: String(portabilidade.dadosProfissionais.estadoSelecionado) || '',
  convenioId: '6',
  convenioDesc: 'INSS',
  origem: ui.navigation.origem,
  numeroContrato: '',
  quantidadeParcelasFalta: '',
  saldoDevedor: '',
  valorParcela: portabilidade.contratoAtual.valorParcela || '',
  situacaoFuncional: findSituacaoFuncionalSalesforce(
    portabilidade.dadosProfissionais.fonteSelecionada as SituacaoFuncionalTypes,
    String(portabilidade.situacaoFuncional.situacaoFuncional) || '',
  ),
  situacaoFuncionalMotivo: verificarMotivoInss(
    String(portabilidade.situacaoFuncional.situacaoFuncional),
    String(portabilidade.situacaoFuncional.motivo),
  ),
  upag: findUpag(globalApi.upags, String(portabilidade.situacaoFuncional.upagSelecionada)),
  matricula: removeMask(String(portabilidade.dadosProfissionais.matricula) || ''),
  salarioBruto: String(portabilidade.dadosProfissionais.salario) || '',
  primeiroNome: portabilidade.dadosPessoais.nome.split(' ').slice(0, 1).join(' ') || '',
  segundoNome:
    portabilidade.dadosPessoais.nome
      .split(' ')
      .slice(1, portabilidade.dadosPessoais.nome.length)
      .join(' ') || '',
  cpf: portabilidade.dadosPessoais.cpf || '',
  dataNascimento: portabilidade.dadosPessoais.dataNascimento.split('/').reverse().join('-'),
  telefoneCelular: portabilidade.contato.telefone,
  email: portabilidade.contato.email,
  valorParcelaNovo: String(portabilidade.simulacao.novoValorParcela) || '',
  taxaNominalMensal: String(portabilidade.simulacao.taxaNominalMensal) || '',
  taxaNominalMensalNovo: String(portabilidade.simulacao.novoTaxaNominalMensal) || '',
  valorEconomia: String(portabilidade.simulacao.valorEconomia) || '',
  valorSolicitado: String(portabilidade.simulacao.valorSolicitado) || '',
  valorFinanciado: String(portabilidade.simulacao.valorFinanciado) || '',
  orgaoId: '12',
  orgaoDescricao: 'INSS',
  clientId: session.sessionId || '',
  familiaId: String(portabilidade.dadosProfissionais.fonteSelecionada) || '',
  familiaDescricao:
    globalApi.fontesPagamento?.find(
      (fonte) => fonte.codigo === Number(portabilidade.dadosProfissionais.fonteSelecionada),
    )?.descricao || '',
  utmSource: String(session.utmSource) || '',
  utmMedium: String(session.utmMedium) || '',
  utmContent: String(session.utmContent) || '',
  utmCampaign: String(session.utmCampaign) || '',
  facebookId: String(session.facebookId) || '',
  gaClientId: String(session.gaClientId) || '',
  gaClid: String(session.gaClid) || '',
  formaContato: '',
  dataIngressoExercito: String(portabilidade?.situacaoFuncional?.anoIngresso) || '',
  indicativo: '',
  patente: portabilidade?.situacaoFuncional.patente || '',
  origemSimulacao: 'SIMULADOR_PORTABILIDADE',
  margem: portabilidade?.dadosProfissionais.dadosBeneciario?.infoInss?.margem || 0,
})

export const buildTrackPortabilidade = (
  { globalApi, ui, portabilidade, session }: ApplicationState,
  step: StepPortabilidade,
): TrackPortabilidadeRequest => ({
  cpf: removeMask(portabilidade.dadosPessoais.cpf) || '',
  banco: String(portabilidade.contratoAtual.bancoSelecionado) || '',
  cidade:
    globalApi.cidades?.find(
      (cidade) => cidade.codigo === String(portabilidade.dadosProfissionais.cidadeSelecionada),
    )?.nome || '',
  convenio:
    globalApi.convenios?.find(
      (convenio) =>
        convenio.codigo === Number(portabilidade.dadosProfissionais.convenioSelecionado),
    )?.descricao || '',
  clientId: session.sessionId || '',
  dataNascimento: portabilidade.dadosPessoais.dataNascimento.split('/').reverse().join('-'),
  estado: String(portabilidade.dadosProfissionais.estadoSelecionado) || '',
  email: portabilidade.contato.email,
  matricula: removeMask(String(portabilidade.dadosProfissionais.matricula) || ''),
  nome: portabilidade.dadosPessoais.nome || '',
  numeroContrato: '',
  salarioBruto: portabilidade.dadosProfissionais.salario || 0,
  sessionId: session.sessionId || '',
  situacaoFuncional: findSituacaoFuncionalSalesforce(
    portabilidade.dadosProfissionais.fonteSelecionada as SituacaoFuncionalTypes,
    String(portabilidade.situacaoFuncional.situacaoFuncional) || '',
  ),
  step: String(step),
  telefone: portabilidade.contato.telefone || '',
  valorParcela: Number(portabilidade.contratoAtual.valorParcela) || 0,
  fontePagamento:
    globalApi.fontesPagamento?.find(
      (fonte) => fonte.codigo === Number(portabilidade.dadosProfissionais.fonteSelecionada),
    )?.descricao || '',

  valorNovaParcela: portabilidade.simulacao.novoValorParcela || 0,
  valorParcelaAtual: portabilidade.simulacao.valorParcela || 0,
  actionType: '',
  autenticouSMS: portabilidade.simulacao.validar,
  eventType: 'SIMULADOR_PORTABILIDADE',
  houveEconomia: portabilidade.simulacao.validar,
  quantidadeParcelas: 0,
  siglaUPAG: findUpag(globalApi.upags, String(portabilidade.situacaoFuncional.upagSelecionada)),
  valorSaldoDevedor: portabilidade.simulacao.valorFinanciado,
  origem: ui?.navigation?.origem || '',
  produto: 'PORTABILIDADE',
})
