import { useEffect } from 'react'

import { interWbSession } from '@interco/inter-webview-bridge'
import { BridgeService } from '@services/bridge'

const useEditBar = (title: string) => {
  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar(title, true)
    }
  }, [title])
}

export default useEditBar
