import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { ConveniosCodes, SalarioSimulacao, SituacoesInss } from '@utils/enums'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { inssMask } from '@utils/masks'
import {
  dadosBeneficiarioInssRequest,
  setCheckAutorizacaoInss,
  setConvenioSelecionado,
  setMatricula,
  setSalario,
  validarMatriculaRequest,
} from '@store/portabilidade/dadosProfissionais/actions'
import { setSituacaoFuncional } from '@store/portabilidade/situacaoFuncional/actions'
import { Page } from '@templates'
import routes from '@routes/routes'
import MatriculaInput from '@organisms/InputsDropdown/MatriculaInput'
import { InputHelperButton } from '@atoms/InputHelperButton'
import { dialogVariants, VariantDialog } from '@utils/bottomSheetVariants'
import SalarioInput from '@organisms/InputsDropdown/SalarioInput'
import { getHoraBaseINSS } from '@utils/functions'
import { Dialog } from '@molecules'

import { CheckboxCustom, FooterGrid, LinkTermoAutorizacao } from './styles'

const InssSimplificado = () => {
  const horaAtual = new Date().getHours().toLocaleString()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openDialogConsulta, setOpenDialogConsulta] = useState(false)
  const [variant, setVariant] = useState<VariantDialog>()
  const [disabled, setDisabled] = useState(false)
  const {
    salario,
    dadosInssLoading,
    checkAutorizacaoInss,
    matricula,
    matriculaValida = true,
  } = useSelector((state: ApplicationState) => state.portabilidade.dadosProfissionais)

  useEffect(() => {
    // Selecionando o convenio Inss
    dispatch(setConvenioSelecionado(ConveniosCodes.INSS))
    // Enquanto as outras situações não são habilitadas, seleciona automaticamente a primeira
    dispatch(setSituacaoFuncional(SituacoesInss[0].value))
  }, [dispatch])

  useEffect(() => {
    setDisabled(
      !salario ||
        !matricula ||
        !matriculaValida ||
        salario < SalarioSimulacao ||
        !checkAutorizacaoInss,
    )
  }, [setDisabled, matricula, matriculaValida, salario, checkAutorizacaoInss])

  return (
    <Page
      id="page-novo-simulador-inss-fluxo"
      stickyFooter={
        <>
          <Button
            disabled={disabled || dadosInssLoading}
            isLoading={dadosInssLoading}
            onClick={() => {
              if (getHoraBaseINSS(horaAtual)) {
                dispatch(
                  validarMatriculaRequest({
                    callback: (isValido) => {
                      if (isValido) {
                        dispatch(
                          dadosBeneficiarioInssRequest({
                            callback: (isConsultaRealizada, pendencia, isBeneficioValido) => {
                              if (
                                pendencia === 'PENDENCIA_IRREVERSIVEL' ||
                                (isConsultaRealizada && !isBeneficioValido) ||
                                pendencia === ''
                              ) {
                                navigate(routes.NAO_LIBERADO)
                              } else if (!isConsultaRealizada) {
                                setOpenDialogConsulta(true)
                              } else {
                                navigate(routes.SITUACAO_FUNCIONAL)
                              }
                            },
                          }),
                        )
                      }
                    },
                  }),
                )
              } else {
                navigate(routes.DADOS_PROFISSIONAIS)
              }
            }}
          >
            Continuar
          </Button>
        </>
      }
    >
      <Paragraph
        fontFamily="sora"
        fontSize="16px"
        lineHeight="20px"
        fontWeight={600}
        color={Colors.GRAY500}
        margin="0 0 24px 0"
      >
        Simulação simplificada, vamos consultar seus dados
      </Paragraph>

      <MatriculaInput
        id="novo-input-numero-matricula-inss-fluxo"
        matricula={matricula}
        matriculaError={!matriculaValida}
        placeholder="Digite os números"
        label="Qual é o número do benefício?"
        onChange={(e) => dispatch(setMatricula(inssMask(e)))}
        onBlur={() => dispatch(validarMatriculaRequest({ callback: () => null }))}
        invalidText="Benefício não encontrado"
        infoButton={
          <InputHelperButton
            onClick={() => {
              setOpen(true)
              setVariant(dialogVariants.numeroBeneficio)
            }}
          />
        }
      />
      <SalarioInput
        id="novo-input-salario"
        salario={salario}
        placeholder="Informe o valor do benefício"
        label="Qual é o valor do benefício?"
        onChange={(e) => dispatch(setSalario(e))}
      />

      <FooterGrid>
        <CheckboxCustom
          id="checkbox-consulta-siape"
          reversed
          checked={Boolean(checkAutorizacaoInss)}
          onChange={(e) => dispatch(setCheckAutorizacaoInss(e.target.checked))}
          label={
            <Paragraph color={Colors.GRAY500} margin="0" fontSize="12px">
              Autorizo o Inter a consultar meus dados no INSS, de acordo com o{' '}
              <LinkTermoAutorizacao to={routes.AUTORIZACAO_CONSULTA_INSS}>
                Termo de Autorização
              </LinkTermoAutorizacao>
            </Paragraph>
          }
        />
      </FooterGrid>

      {open ? (
        <Dialog
          onClose={() => setOpen(false)}
          headerTitle={variant?.title || ''}
          description={variant?.text}
          buttonText="Entendi"
        />
      ) : (
        <></>
      )}

      {openDialogConsulta ? (
        <Dialog
          onClose={() => setOpen(false)}
          headerTitle="Integração automática indisponível"
          description="No momento, não foi possível consultar seus dados automaticamente. Não se preocupe, você poderá continuar a simulação com preenchimento manual."
          buttonText="Continuar manualmente"
          buttonAction={() => {
            navigate(routes.DADOS_PROFISSIONAIS)
          }}
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default InssSimplificado
