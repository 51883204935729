import { BridgeService } from '@services/bridge'
import { IDataLayer, ElementAction } from '@utils/types'

interface TagProps {
  installments_number?: string | number
  flow?: string
  only_app?: boolean
  content_name?: string
  tela?: string
  content_action?: string
  price?: string | number
  value?: string | number
  content_aba?: string
  content_grupo?: string | number
  content_conv?: string | number
  content_sel?: string
  contato?: string
  ref_figma?: string
  content_id?: string
  screen?: string
  area?: string
  element_action?: ElementAction
  c_page?: string
  step?: string | null
  redirect_url?: string | null
  input_name_1?: string | number | boolean
  input_value_1?: string | number | boolean
  input_name_2?: string | number | boolean
  input_value_2?: string | number | boolean
  input_name_3?: string | number | boolean
  input_value_3?: string | number | boolean
  input_name_4?: string | number | boolean
  input_value_4?: string | number | boolean
  input_name_5?: string | number | boolean
  input_value_5?: string | number | boolean
  input_name_6?: string | number | boolean
  input_value_6?: string | number | boolean
  input_name_7?: string | number | boolean
  input_value_7?: string | number | boolean
  input_name_8?: string | number | boolean
  input_value_8?: string | number | boolean
  input_name_9?: string | number | boolean
  input_value_9?: string | number | boolean
}

export default {
  simuladorConsignadoTag: ({
    only_app,
    content_name = '',
    tela,
    content_action,
    price = '',
    value,
    content_aba = '',
    content_grupo,
    content_conv,
    content_sel,
    contato,
    element_action,
    c_page = 'null',
    step,
    redirect_url,
    input_name_1 = 'null',
    input_value_1 = 'null',
    input_name_2 = 'null',
    input_value_2 = 'null',
    input_name_3 = 'null',
    input_value_3 = 'null',
    input_name_4 = 'null',
    input_value_4 = 'null',
    input_name_5 = 'null',
    input_value_5 = 'null',
    input_name_6 = 'null',
    input_value_6 = 'null',
    input_name_7 = 'null',
    input_value_7 = 'null',
    input_name_8 = 'null',
    input_value_8 = 'null',
    input_name_9 = 'null',
    input_value_9 = 'null',
  }: TagProps) => {
    if (!BridgeService.isBrowser()) {
      BridgeService.requestAnalytics('Simulador_Consignado', {
        ref_figma: '72',
        content_name: String(content_name),
        tela: String(tela),
        content_action: String(content_action),
        price: String(price),
        value: String(value),
        content_aba: String(content_aba),
        content_grupo: String(content_grupo),
        content_conv: String(content_conv),
        content_sel: String(content_sel),
        contato: String(contato),
      })
    } else if (!only_app) {
      const dataLayer: IDataLayer = {
        event: 'ga_event_body_cred',
        params: {
          section_name: String(tela),
          element_action,
          element_name: String(content_name),
          c_page,
          step: step || 'null',
          redirect_url: redirect_url || 'null',
        },
        params_input: {
          input_name_1,
          input_value_1,
          input_name_2,
          input_value_2,
          input_name_3,
          input_value_3,
          input_name_4,
          input_value_4,
          input_name_5,
          input_value_5,
          input_name_6,
          input_value_6,
          input_name_7,
          input_value_7,
          input_name_8,
          input_value_8,
          input_name_9,
          input_value_9,
        },
      }
      if (window.dataLayer) {
        window.dataLayer.push(dataLayer)
      }
    }
  },
}
