import { ValidaCodigoApiRequest } from '@services/getValidaToken'
import { EnviaCodigoApiRequest } from '@services/postEnviaToken'
import { ApplicationState } from '@store/types'

// Build validação codigo
export const buildValidateToken: {
  [stateType: string]: (
    state: ApplicationState,
    codigo: string,
    origem: 'sms' | 'email' | undefined,
  ) => ValidaCodigoApiRequest
} = {
  portabilidade: ({ portabilidade }, codigo, origem) => ({
    id: portabilidade.dadosPessoais.cpf,
    origem: origem === 'sms' ? 'SMS' : 'EMAIL',
    codigo,
  }),
}

// Build criação codigo
export const buildToken: {
  [stateType: string]: (
    state: ApplicationState,
    envio: 'sms' | 'email' | undefined,
  ) => EnviaCodigoApiRequest
} = {
  portabilidade: ({ portabilidade }, envio) => ({
    cpf: portabilidade.dadosPessoais.cpf,
    nome: portabilidade.dadosPessoais.nome,
    email: portabilidade.contato.email,
    celular: portabilidade.contato.telefone,
    envio: [envio === 'sms' ? 'SMS' : 'EMAIL'],
  }),
}
