import React from 'react'

import { BoxProps } from './props'
import { BoxComponent } from './styles'

export const Box: React.FC<BoxProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <BoxComponent data-testid="box" {...props}>
    {children}
  </BoxComponent>
)
