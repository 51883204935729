import { Reducer } from 'redux'

import { GlobalApiState, GlobalApiTypes } from './types'

const INITIAL_STATE: GlobalApiState = {
  fontesPagamento: [],
  fontesPagamentoError: false,
  fontesPagamentoLoading: false,

  estados: [],
  estadosError: false,
  estadosLoading: false,

  cidades: [],
  cidadesError: false,
  cidadesLoading: false,

  bancos: [],
  bancosError: false,
  bancosLoading: false,

  upags: [],
  upagsError: false,
  upagsLoading: false,

  convenios: [],
  conveniosError: false,
  conveniosLoading: false,

  orgaos: [],
  orgaosError: false,
  orgaosLoading: false,
}

const reducer: Reducer<GlobalApiState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Fontes de Pagamento
    case GlobalApiTypes.FONTES_SUCCESS:
      return {
        ...state,
        fontesPagamentoError: false,
        fontesPagamentoLoading: false,
        fontesPagamento: action.payload,
      }
    case GlobalApiTypes.FONTES_REQUEST:
      return {
        ...state,
        fontesPagamentoError: false,
        fontesPagamentoLoading: true,
      }
    case GlobalApiTypes.FONTES_ERROR:
      return {
        ...state,
        fontesPagamentoLoading: false,
        fontesPagamentoError: true,
      }

    // Estados
    case GlobalApiTypes.ESTADOS_SUCCESS:
      return {
        ...state,
        estadosError: false,
        estadosLoading: false,
        estados: action.payload,
      }
    case GlobalApiTypes.ESTADOS_REQUEST:
      return { ...state, estadosError: false, estadosLoading: true }
    case GlobalApiTypes.ESTADOS_ERROR:
      return { ...state, estadosLoading: false, estadosError: true }

    // Cidades
    case GlobalApiTypes.CIDADES_SUCCESS:
      return {
        ...state,
        cidadesError: false,
        cidadesLoading: false,
        cidades: action.payload,
      }
    case GlobalApiTypes.CIDADES_REQUEST:
      return { ...state, cidadesError: false, cidadesLoading: true }
    case GlobalApiTypes.CIDADES_ERROR:
      return { ...state, cidadesLoading: false, cidadesError: true }

    // Bancos
    case GlobalApiTypes.BANCOS_SUCCESS:
      return {
        ...state,
        bancosError: false,
        bancosLoading: false,
        bancos: action.payload,
      }
    case GlobalApiTypes.BANCOS_REQUEST:
      return { ...state, bancosError: false, bancosLoading: true }
    case GlobalApiTypes.BANCOS_ERROR:
      return { ...state, bancosLoading: false, bancosError: true }

    // Upags
    case GlobalApiTypes.UPAGS_SUCCESS:
      return {
        ...state,
        upagsError: false,
        upagsLoading: false,
        upags: action.payload,
      }
    case GlobalApiTypes.UPAGS_REQUEST:
      return { ...state, upagsError: false, upagsLoading: true }
    case GlobalApiTypes.UPAGS_ERROR:
      return { ...state, upagsLoading: false, upagsError: true }

    // Convenios
    case GlobalApiTypes.CONVENIOS_SUCCESS:
      return {
        ...state,
        conveniosLoading: false,
        conveniosError: false,
        convenios: action.payload,
      }
    case GlobalApiTypes.CONVENIOS_REQUEST:
      return {
        ...state,
        convenioSelecionado: '',
        orgaos: [],
        orgaoSelecionado: '',
        conveniosLoading: true,
        conveniosError: false,
      }
    case GlobalApiTypes.CONVENIOS_ERROR:
      return { ...state, conveniosLoading: false, conveniosError: true }

    // Orgaos
    case GlobalApiTypes.ORGAOS_SUCCESS:
      return {
        ...state,
        orgaosError: false,
        orgaosLoading: false,
        orgaos: action.payload,
      }
    case GlobalApiTypes.ORGAOS_REQUEST:
      return {
        ...state,
        orgaos: [],
        orgaoSelecionado: '',
        orgaosLoading: true,
        orgaosError: false,
      }
    case GlobalApiTypes.ORGAOS_ERROR:
      return { ...state, orgaosLoading: false, orgaosError: true }

    // Default
    default:
      return state
  }
}

export default reducer
