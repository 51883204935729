import { CidadesAPI } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (estadoUf: string) => {
  const pathname = `${process.env.REACT_APP_GET_CIDADES}?state=${estadoUf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<CidadesAPI>({
    pathname,
    method,
    host,
  })
  return response
}
