/**
 * Actions Types
 */

export enum ContatoTypes {
  SET_TELEFONE = '@contato/SET_TELEFONE',
  SET_EMAIL = '@contato/SET_EMAIL',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ContatoState {
  readonly telefone: string
  readonly email: string
}
