import { addMonths, addDays, differenceInYears, format } from 'date-fns'

import { Banco, Cidade, Convenio, Estado, Orgao, Upag } from '@store/globalApi/types'
import {
  BancosCodes,
  ConveniosSituacaoFuncional,
  FontesPagamentoTitle,
  ForcasArmadasConvenios,
  MotivosInss,
  SituacoesFuncionaisSalesforce,
} from '@utils/enums'
import caixaLogo from '@images/banksIcons/logoCaixa.png'
import itauLogo from '@images/banksIcons/logoItau.png'
import bbLogo from '@images/banksIcons/logoBB.png'
import bradescoLogo from '@images/banksIcons/logoBradesco.png'
import santanderLogo from '@images/banksIcons/logoSantander.png'
import { BridgeService } from '@services/bridge'

/**
 * Converte timestamp para formato de data PT
 *
 * @param timestamp Numero a ser convertido
 * @returns Data formatada
 */
export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

/**
 * Converte data formato de pattern passado por parametro
 *
 * @param date Data a ser convertida
 * @param pattern String do formato de data desejado
 * @returns Data formatada
 */
export const DateFormat = (date: Date, pattern = 'YYYY-MM-DDTHH:mm:ss.SSSZZ') =>
  format(date, pattern)

/**
 * Busca data atual
 *
 * @returns Data atual
 */
export const CurrentDate = () => DateFormat(new Date())

/**
 * Busca idade
 *
 * @param date Data a ser convertida
 * @param currentDate Data corrente
 * @param hasSumDate Boolean de somatorio de data
 * @returns Idade
 */
export const describeAge = (date: Date, currentDate = new Date(), hasSumDate = false) => {
  const now = currentDate
  let birthday = addMonths(date, 1)
  birthday = addDays(birthday, 1)
  let years = differenceInYears(now, birthday)

  if (
    date.getMonth() + 1 === currentDate.getMonth() + 1 &&
    date.getDate() > currentDate.getDate() &&
    hasSumDate
  ) {
    years += 1
  }
  return years
}

/**
 * Valida para onde será encaminhado a home
 *
 * @param isApp Boolean se está utilizando pelo aplicativo
 * @param isConsignado Boolean se é credito consignado
 * @returns Caminho a ser direcionado
 */
export const validaEnvHomeByBridge = (
  isApp: boolean,
  origemGoBack: string,
  isConsignado?: boolean,
) => {
  if (isApp) {
    if (origemGoBack === 'emprestimoinss') return `${process.env.REACT_APP_HOME_EMPRESTIMO}/inss`

    if (origemGoBack === 'emprestimosanguelaranja')
      return `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo&sangueLaranja=true`

    return isConsignado
      ? `${process.env.REACT_APP_HOME_EMPRESTIMO}/consignado`
      : process.env.REACT_APP_HOME_EMPRESTIMO
  }
  return process.env.REACT_APP_HOME_SIMULADOR_NOVO
}

/**
 * Remove as acentuações da string.
 *
 * @param data String a ser removida a acentuação.
 * @param lowercase Indicador para informar se deseja transformar em lowercase.
 * @returns String sem acentuação.
 */
export const RemoveAccents = (data: string, lowercase = true) => {
  try {
    if (lowercase) {
      data = data.toLowerCase()
    }

    return data
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  } catch {
    return data
  }
}

/**
 * Controi um formato de banco adequado para dropdown
 *
 * @param bancos Array de codigo e nome dos bancos recebido através de api
 * @returns String mapeada
 */
export const buildBancos = (bancos: Banco[]) =>
  bancos?.map(({ nome, codigo }) => ({ value: codigo, text: nome }))

/**
 * Controi um formato de convenio adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos convenios recebido através de api
 * @returns String mapeada
 */
export const buildConvenios = (convenios: Convenio[]) =>
  convenios?.map((convenio) => ({
    value: convenio.codigo,
    text: convenio.descricao,
  }))

/**
 * Controi um formato de cidade adequado para dropdown
 *
 * @param convenios Array de codigo e nome das cidades recebido através de api
 * @returns String mapeada
 */
export const buildCidades = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({ value: cidade.codigo, text: cidade.nome }))

/**
 * Controi um formato de estado adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos estados recebido através de api
 * @returns String mapeada
 */
export const buildEstados = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, text: uf }))

/**
 * Controi um formato de upag adequado para dropdown
 *
 * @param convenios Array de codigo e nome das upags recebido através de api
 * @returns String mapeada
 */
export const buildUpags = (upags: Upag[]) =>
  upags?.map((upag) => ({
    value: `${upag.sigla} - ${upag.nome} - ${upag.codigoUpag}`,
    text: `${upag.sigla} - ${upag.nome}`,
  }))

/**
 * Controi um formato de orgao adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos orgaos recebido através de api
 * @returns String mapeada
 */
export const buildOrgaos = (orgaos: Orgao[]) =>
  orgaos?.map((orgao) => ({ value: orgao.codigo, text: orgao.descricao }))

/**
 * Converter data em formato PT para formato EN
 *
 * @param date String a ser convertida
 * @returns Conversão
 */
export const datePtToEn = (date: string) =>
  date
    ? format(
        new Date(
          Number(date.substring(6)),
          Number(date.substring(3, 5)) - 1,
          Number(date.substring(0, 2)),
        ),
        'yyyy-MM-dd',
      )
    : ''

/**
 * Encontrar Upag
 *
 * @param listaUpags Lista de Upags a ser mapeado
 * @param upag String de upag que será pesquisado
 * @returns Sigla e nome caso encontrado ou vazio caso não encontrado
 */
export const findUpag = (listaUpags: Upag[], upag: string) => {
  const upagFound = listaUpags?.find(
    (upagItem) => `${upagItem.sigla} - ${upagItem.nome} - ${upagItem.codigoUpag}` === upag,
  )
  if (upagFound) {
    return `${upagFound?.sigla} - ${upagFound.nome}`
  }
  return ''
}

/**
 * Encontrar Fonte Pagamento
 *
 * @param fontePagamento fonte a ser mapeada
 * @returns nome caso encontrado ou vazio caso não encontrado
 */
export const findFontePagamento = (fontePagamento: number) => {
  const title = FontesPagamentoTitle.find((fonte) => fonte.value === fontePagamento)
  return title?.label
}

/**
 * Encontrar Fonte Pagamento Forcas Armadas
 *
 * @param fontePagamentoForcasArmadas fonte a ser mapeada
 * @returns nome caso encontrado ou vazio caso não encontrado
 */
export const findFontePagamentoForcasArmadas = (fontePagamentoForcasArmadas: number) => {
  const title = ForcasArmadasConvenios.find((fonte) => fonte.value === fontePagamentoForcasArmadas)
  return title?.label
}

/**
 * Encontrar Situacao Funcional de acordo com o convenio
 *
 * @param convenio fonte a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const findConveniosSituacaoFuncional = (convenio: number) => {
  const situacao = ConveniosSituacaoFuncional.find((fonte) => fonte.value === convenio)
  if (situacao) return situacao.options
  return []
}

/**
 * Busca os motivos de acordo com a situacao funcional do inss
 *
 * @param situacaoFuncional situacao a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const motivoOptions = (situacaoFuncional: string | number | undefined) => {
  if (!situacaoFuncional) return []
  if (situacaoFuncional === 'Aposentados') {
    return MotivosInss.aposentado
  }
  if (situacaoFuncional === 'Pensionistas') {
    return MotivosInss.pensionista
  }
  return []
}

/**
 * Busca o periodo em que a api do inss funciona
 *
 * @param horaAtual hora em que está sendo pesquisado
 * @returns true caso esteja dentro do periodo
 */
export const getHoraBaseINSS = (horaAtual: string) =>
  Number(horaAtual) >= 6 && Number(horaAtual) <= 21

export type SituacaoFuncionalTypes = 1 | 2 | 3 | 10 | 7 | 8
/**
 * Encontrar Situacao Funcional de acordo com o salesforce
 *
 * @param convenio fonte a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const findSituacaoFuncionalSalesforce = (
  convenio: SituacaoFuncionalTypes,
  situacaoFuncional: string,
) => {
  const situacoes = {
    1: SituacoesFuncionaisSalesforce.ESTADUAL,
    2: SituacoesFuncionaisSalesforce.MUNICIPAL,
    3: SituacoesFuncionaisSalesforce.INSS,
    10: SituacoesFuncionaisSalesforce.FEDERAL_SIAPE,
    7: SituacoesFuncionaisSalesforce.FORCAS_ARMADAS,
    8: SituacoesFuncionaisSalesforce.TRIBUNAIS,
  }

  if (convenio && situacaoFuncional)
    return situacoes[convenio].find((situacao) => situacao.value === situacaoFuncional)?.text || ''
  return ''
}

export const logoByBank = (banco: number) => {
  switch (String(banco)) {
    case BancosCodes.CAIXA:
      return caixaLogo
    case BancosCodes.ITAU:
      return itauLogo
    case BancosCodes.BB:
      return bbLogo
    case BancosCodes.BRADESCO:
      return bradescoLogo
    case BancosCodes.SANTANDER:
      return santanderLogo
    default:
      return ''
  }
}

export const openLinkByEnviroment = async (url: string) => {
  if (BridgeService.isBrowser()) {
    window.location.href = url
  } else {
    await BridgeService.openDeepLink(url)
  }
}

export function toTitleCase(input: string): string {
  return input
    .toLowerCase() // Converte todo o texto para minúsculas
    .split(' ') // Divide o texto em palavras
    .map(
      (word) =>
        // Converte a primeira letra de cada palavra para maiúscula e junta com o restante
        word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(' ') // Junta as palavras resultantes
}
