import { useEffect } from 'react'

import { interWbSession, IWbTac, IWbTic } from '@interco/inter-webview-bridge'
import { BridgeService } from '@services/bridge'

const useTicTac = () => {
  const sendTic = (ticProps: IWbTic) => {
    if (!BridgeService.isBrowser()) {
      interWbSession.tic(ticProps)
    }
  }

  const useTac = (tacProps: IWbTac) => {
    useEffect(() => {
      if (!BridgeService.isBrowser()) {
        interWbSession.tac(tacProps)
      }
    }, [tacProps])
  }
  return { sendTic, useTac }
}

export default useTicTac
