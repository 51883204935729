import styled from 'styled-components'

import { PropsContent } from './props'

export const Container = styled.div`
  margin: 24px auto;
  max-width: 600px;
  display: grid;

  @media (max-width: 720px) {
    margin: 24px;
  }
`

export const Content = styled.div<PropsContent>`
  display: flex;
  flex-direction: column;
  min-width: 35vw;
  min-height: ${({ isWeb }) => (isWeb ? 'calc(100vh - 282px)' : 'calc(100vh - 120px)')};
`

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0;
  row-gap: 2vh;
`
