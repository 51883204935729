import { BaseServices, HttpMethod } from '@services/base'

export default (socialNumber: string, salary: string, authorizedMargin: boolean) => {
  const pathname = process.env.REACT_APP_POST_ATUALIZA_CACHE_SIAPE_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    socialNumber,
    viewCode: 'SP01',
  }

  const fields = {
    salary,
    authorizedMargin,
  }

  return BaseServices.request({
    pathname,
    method,
    data: {
      fields,
    },
    host,
    headers,
  })
}
