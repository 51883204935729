import styled from 'styled-components'

export const Button = styled.button`
  background-color: transparent;
  display: flex;
  width: max-content;
  align-self: end;
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
  z-index: 10;
`
