import React from 'react'

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import HeaderAndFooter from '@templates/HeaderAndFooter'
import {
  Contato,
  ConvenioForcasArmadas,
  DadosPessoais,
  DadosProfissionais,
  DadosRecebidos,
  InssSimplificado,
  NaoLiberado,
  Onboarding,
  SelecaoGrupo,
  SituacaoFuncional,
  Token,
  AutorizacaoConsultaInss,
  RootPage,
} from '@pages'
import { BridgeService } from '@services/bridge'
import { GoBackConfig } from '@utils/goBackConfig'
import OnboardingV2 from '@pages/PortabilidadeV2/EtapaSimular/Onboarding'
import SelecaoConvenio from '@pages/PortabilidadeV2/EtapaSimular/SelecaoConvenio'
import Autorizacao from '@pages/PortabilidadeV2/EtapaSimular/Autorizacao'
import InssIndisponivel from '@pages/PortabilidadeV2/EtapaSimular/Grupos/Inss/InssIndisponivel'
import PortabilidadeNaoLiberada from '@pages/PortabilidadeV2/EtapaSimular/NaoLiberado'
import EntraremosContato from '@pages/PortabilidadeV2/EtapaSimular/EntraremosContato'
import CarregamentoDados from '@pages/PortabilidadeV2/EtapaSimular/CarregamentoDados'
import DadosBasicos from '@pages/PortabilidadeV2/EtapaSimular/DadosBasicos'
import ValidaTelefone from '@pages/PortabilidadeV2/EtapaSimular/Validacao/ValidaTelefone'
import BancoContrato from '@pages/PortabilidadeV2/EtapaContratos/BancoContrato'
import ConsultaMargemSiape from '@pages/PortabilidadeV2/EtapaSimular/Grupos/Siape/ConsultaMargem'
import DadosManuaisSiape from '@pages/PortabilidadeV2/EtapaSimular/Grupos/Siape/DadosManuais'
import FeedbackResultadoMargem from '@pages/PortabilidadeV2/EtapaSimular/Grupos/Siape/ConsultaMargem/FeedbackResultadoMargem'
import ValidaEmail from '@pages/PortabilidadeV2/EtapaSimular/Validacao/ValidaEmail'
import DicasContratos from '@pages/PortabilidadeV2/EtapaContratos/DicasContratos'
import AdicionarContrato from '@pages/PortabilidadeV2/EtapaContratos/AdicionarContrato'
import Simulador from '@pages/PortabilidadeV2/EtapaSimular/Simulador'
import ResultadoSimulacao from '@pages/PortabilidadeV2/EtapaSimular/ResultadoSimulacao'
import DDC from '@pages/PortabilidadeV2/EtapaSimular/Simulador/DDC'

import pageRoutes from './routes'

const RoutesPortabilidade: React.FC = () => (
  <>
    {BridgeService.isBrowser() ? (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        useEnterprise
      >
        <BrowserRouter>
          <GoBackConfig />
          <HeaderAndFooter>
            <Routes>
              <Route path={pageRoutes.ROOT} element={<RootPage />} />
              <Route path={pageRoutes.ONBOARDING} element={<Onboarding />} />
              <Route path={pageRoutes.DADOS_PESSOAIS} element={<DadosPessoais />} />
              <Route path={pageRoutes.CONTATO} element={<Contato />} />
              <Route path={pageRoutes.TOKEN} element={<Token />} />
              <Route path={pageRoutes.SELECAO_GRUPO} element={<SelecaoGrupo />} />
              <Route path={pageRoutes.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
              <Route path={pageRoutes.NAO_LIBERADO} element={<NaoLiberado />} />
              <Route path={pageRoutes.DADOS_PROFISSIONAIS} element={<DadosProfissionais />} />
              <Route
                path={pageRoutes.CONVENIO_FORCAS_ARMADAS}
                element={<ConvenioForcasArmadas />}
              />
              <Route path={pageRoutes.INSS_SIMPLIFICADO} element={<InssSimplificado />} />
              <Route path={pageRoutes.SITUACAO_FUNCIONAL} element={<SituacaoFuncional />} />
              <Route
                path={pageRoutes.AUTORIZACAO_CONSULTA_INSS}
                element={<AutorizacaoConsultaInss />}
              />

              <Route path={pageRoutes.ONBOARDING_V2} element={<OnboardingV2 />} />
              <Route path={pageRoutes.SELECAO_GRUPO_V2} element={<SelecaoConvenio />} />
              <Route path={pageRoutes.TERMO_AUTORIZACAO_V2} element={<Autorizacao />} />
              <Route path={pageRoutes.INSS_INDISPONIVEL_V2} element={<InssIndisponivel />} />
              <Route path={pageRoutes.NAO_LIBERADO_V2} element={<PortabilidadeNaoLiberada />} />
              <Route path={pageRoutes.ENTRAREMOS_CONTATO_V2} element={<EntraremosContato />} />
              <Route path={pageRoutes.CARREGAMENTO_DADOS_V2} element={<CarregamentoDados />} />
              <Route path={pageRoutes.DADOS_BASICOS_V2} element={<DadosBasicos />} />
              <Route path={pageRoutes.VALIDA_EMAIL_V2} element={<ValidaEmail />} />
              <Route path={pageRoutes.VALIDA_TELEFONE_V2} element={<ValidaTelefone />} />
              <Route path={pageRoutes.BANCO_CONTRATO_V2} element={<BancoContrato />} />
              <Route path={pageRoutes.CONSULTA_MARGEM_SIAPE_V2} element={<ConsultaMargemSiape />} />
              <Route path={pageRoutes.DADOS_MANUAIS_SIAPE_V2} element={<DadosManuaisSiape />} />
              <Route path={pageRoutes.SIMULADOR_PORTABILIDADE} element={<Simulador />} />
              <Route path={pageRoutes.RESULTADO_SIMULACAO} element={<ResultadoSimulacao />} />
              <Route path={pageRoutes.DDC} element={<DDC />} />
              <Route
                path={pageRoutes.FEEDBACK_RESULTADO_MANUAL_SIAPE_V2}
                element={<FeedbackResultadoMargem />}
              />
              <Route path={pageRoutes.DICAS_CONTRATO_V2} element={<DicasContratos />} />
              <Route path={pageRoutes.ADICIONAR_CONTRATO_V2} element={<AdicionarContrato />} />
            </Routes>
          </HeaderAndFooter>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    ) : (
      <BrowserRouter>
        <GoBackConfig />
        <HeaderAndFooter>
          <Routes>
            <Route path={pageRoutes.ROOT} element={<RootPage />} />
            <Route path={pageRoutes.ONBOARDING} element={<Onboarding />} />
            <Route path={pageRoutes.DADOS_PESSOAIS} element={<DadosPessoais />} />
            <Route path={pageRoutes.CONTATO} element={<Contato />} />
            <Route path={pageRoutes.TOKEN} element={<Token />} />
            <Route path={pageRoutes.SELECAO_GRUPO} element={<SelecaoGrupo />} />
            <Route path={pageRoutes.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
            <Route path={pageRoutes.NAO_LIBERADO} element={<NaoLiberado />} />
            <Route path={pageRoutes.DADOS_PROFISSIONAIS} element={<DadosProfissionais />} />
            <Route path={pageRoutes.CONVENIO_FORCAS_ARMADAS} element={<ConvenioForcasArmadas />} />
            <Route path={pageRoutes.INSS_SIMPLIFICADO} element={<InssSimplificado />} />
            <Route path={pageRoutes.SITUACAO_FUNCIONAL} element={<SituacaoFuncional />} />
            <Route
              path={pageRoutes.AUTORIZACAO_CONSULTA_INSS}
              element={<AutorizacaoConsultaInss />}
            />

            <Route path={pageRoutes.ONBOARDING_V2} element={<OnboardingV2 />} />
            <Route path={pageRoutes.SELECAO_GRUPO_V2} element={<SelecaoConvenio />} />
            <Route path={pageRoutes.TERMO_AUTORIZACAO_V2} element={<Autorizacao />} />
            <Route path={pageRoutes.INSS_INDISPONIVEL_V2} element={<InssIndisponivel />} />
            <Route path={pageRoutes.NAO_LIBERADO_V2} element={<PortabilidadeNaoLiberada />} />
            <Route path={pageRoutes.ENTRAREMOS_CONTATO_V2} element={<EntraremosContato />} />
            <Route path={pageRoutes.CARREGAMENTO_DADOS_V2} element={<CarregamentoDados />} />
            <Route path={pageRoutes.DADOS_BASICOS_V2} element={<DadosBasicos />} />
            <Route path={pageRoutes.VALIDA_EMAIL_V2} element={<ValidaEmail />} />
            <Route path={pageRoutes.VALIDA_TELEFONE_V2} element={<ValidaTelefone />} />
            <Route path={pageRoutes.BANCO_CONTRATO_V2} element={<BancoContrato />} />
            <Route path={pageRoutes.CONSULTA_MARGEM_SIAPE_V2} element={<ConsultaMargemSiape />} />
            <Route path={pageRoutes.DADOS_MANUAIS_SIAPE_V2} element={<DadosManuaisSiape />} />
            <Route path={pageRoutes.SIMULADOR_PORTABILIDADE} element={<Simulador />} />
            <Route path={pageRoutes.RESULTADO_SIMULACAO} element={<ResultadoSimulacao />} />
            <Route path={pageRoutes.DDC} element={<DDC />} />
            <Route
              path={pageRoutes.FEEDBACK_RESULTADO_MANUAL_SIAPE_V2}
              element={<FeedbackResultadoMargem />}
            />
            <Route path={pageRoutes.DICAS_CONTRATO_V2} element={<DicasContratos />} />
            <Route path={pageRoutes.ADICIONAR_CONTRATO_V2} element={<AdicionarContrato />} />
            <Route path={pageRoutes.SIMULADOR_PORTABILIDADE} element={<Simulador />} />
            <Route path={pageRoutes.RESULTADO_SIMULACAO} element={<ResultadoSimulacao />} />
            <Route path={pageRoutes.DDC} element={<DDC />} />
          </Routes>
        </HeaderAndFooter>
      </BrowserRouter>
    )}
  </>
)

export default RoutesPortabilidade
