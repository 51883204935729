import React, { useCallback, useEffect, useState, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@interco/inter-ui/components/Button'
import { InputToken } from '@molecules'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { ocultarTelefone } from '@utils/strings'
import { sendToken, resetToken, validateToken } from '@store/token/actions'
import { useGetRecaptchaToken } from '@hooks'
import { ApplicationState } from '@store/types'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/colors'
import { Loading } from '@atoms'
import { StepPortabilidade } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'

import { ButtonCancelar, ButtonReenviarCodigo, DivLoading, Header } from './styles'
import { PageTokenProps } from './props'

const RESEND_TIMER = 60

const PageToken = ({
  error,
  loading,
  telefone,
  onSuccess,
  navegarTrocarTelefone,
  bottomSheetVariant,
  open,
  setOpen,
  type,
  stateType,
  stepError,
}: PageTokenProps) => {
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [filledToken, setFilledToken] = useState(false)
  const { getRecaptchaToken } = useGetRecaptchaToken()
  const [timer, setTimer] = useState(RESEND_TIMER)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  useEffect(() => {
    dispatch(resetToken())
  }, [dispatch])

  const sendTokenCallback = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      if (recaptchaToken) {
        dispatch(trackingRequest(StepPortabilidade.ENVIO_TOKEN))
        dispatch(sendToken({ type, stateType, recaptchaToken }))
      }
    } else {
      dispatch(trackingRequest(StepPortabilidade.ENVIO_TOKEN))
      dispatch(sendToken({ type, stateType, recaptchaToken: 'NULLCAPTCHA' }))
    }
  }, [dispatch, getRecaptchaToken, isApp, stateType, type])

  useEffect(() => {
    sendTokenCallback()
  }, [sendTokenCallback])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const sendValidateToken = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      dispatch(
        validateToken({
          stateType,
          token,
          type,
          recaptchaToken,
          success: () => onSuccess(),
        }),
      )
      dispatch(trackingRequest(StepPortabilidade.ENVIO_TOKEN))
    } else {
      dispatch(
        validateToken({
          stateType,
          token,
          type,
          recaptchaToken: 'NULLCAPTCHA',
          success: () => onSuccess(),
        }),
      )
      dispatch(trackingRequest(StepPortabilidade.ENVIO_TOKEN))
    }
  }, [isApp, dispatch, stateType, token, type, getRecaptchaToken, onSuccess])

  useEffect(() => {
    if (filledToken) {
      sendValidateToken()
    }
  }, [filledToken, sendValidateToken])

  return (
    <>
      {loading ? (
        <DivLoading>
          <Loading width={80} height={80} />
        </DivLoading>
      ) : (
        <>
          <Paragraph variant="inter" fontSize="14px" lineHeight="17px" color={Colors.GRAY400}>
            Pra garantir sua segurança, enviamos um SMS com o código de verificação para o número{' '}
            {ocultarTelefone(telefone || '')}. Digite o código aqui :
          </Paragraph>
          <InputToken
            error={error}
            stepError={stepError}
            getToken={(t, filled) => {
              setToken(t)
              setFilledToken(filled)
            }}
            id="pageToken"
          />
          <ButtonReenviarCodigo
            variant="secondary"
            disabled={timer > 0}
            onClick={() => {
              dispatch(resetToken())
              sendTokenCallback()
            }}
          >
            {`Reenviar código ${timer > 0 ? `(${timer}s)` : ''}`}
          </ButtonReenviarCodigo>
          {open && (
            <BottomSheet onClose={() => setOpen(false)}>
              <Header>
                <Paragraph
                  fontFamily="sora"
                  fontSize="1rem"
                  lineHeight="1.25rem"
                  color={Colors.GRAY500}
                  fontWeight={600}
                >
                  {bottomSheetVariant.title}
                </Paragraph>
              </Header>
              <Paragraph
                variant="inter"
                fontWeight="400"
                fontSize="14px"
                lineHeight="17px"
                color={Colors.GRAY400}
                margin="0 0 32px 0"
              >
                {bottomSheetVariant.text}
              </Paragraph>
              <Button
                fullWidth
                onClick={() => {
                  setOpen(false)
                  navegarTrocarTelefone()
                }}
              >
                Informar outro número
              </Button>
              <ButtonCancelar variant="secondary" onClick={() => setOpen(false)} fullWidth>
                Cancelar
              </ButtonCancelar>
            </BottomSheet>
          )}
        </>
      )}
    </>
  )
}

PageToken.defaultProps = {
  error: false,
}

export default memo(PageToken)
