import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TimelineItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  width: 100%;
`

export const TextLink = styled.b<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 700;
  cursor: pointer;
  display: inline;
`
