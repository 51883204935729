import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import { requestMarginAccept, setLoadingQuery } from '../actions'
import { StartPayload } from '../types'

export default function* marginQueryAcceptSaga(action: AnyAction) {
  const payload = action.payload as StartPayload
  try {
    yield put(setLoadingQuery(true))
    yield services.postContractMarginAccept(payload.convenioDescricao)
    yield put(setLoadingQuery(false))
    if (payload.callback) {
      yield payload.callback()
    }
  } catch (error) {
    yield put(setLoadingQuery(false))
    if (payload.callbackError) {
      yield payload.callbackError()
    }
    yield put(
      showError({
        title: 'Erro',
        message: 'Ocorreu um erro ao iniciar a validação do benefício',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => requestMarginAccept(payload),
      }),
    )
  }
}
