import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { Box } from '@atoms'
import ValorParcelaInput from '@organisms/InputsDropdown/ValorParcelaInput'

export const ButtonWithArrow = styled(Button)<{ isApp: boolean }>`
  ${(props) => !props.isApp && `justify-content: space-between`};
`

export const SpaceBetween = styled.div`
  margin-bottom: 24px;
`

export const BoxGrid = styled(Box)`
  display: block;
  flex-direction: column;
`

export const Div = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  column-gap: 1vh;
`

export const ValorParcela = styled(ValorParcelaInput)`
  margin-top: -32px !important;
`
