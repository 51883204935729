import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import IcInfoCircle from '@interco/icons/core/status/ic_info_circle'
import { Colors } from '@utils/colors'

import { Container, Column } from './styles'

interface OwnProps {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetResultadoIndisponivel = ({ setShowBottomSheet }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)} noSwipe>
    <Container>
      <IcInfoCircle height={32} width={32} color={Colors.GRAY400} />

      <Column>
        <Text variant="body-2" bold colorWeight={500}>
          Resultado indisponível
        </Text>
        <Text variant="body-3">
          No momento, não foi possível oferecer um resultado com as informações disponibilizadas.
          Por favor, simule novamente.
        </Text>
      </Column>

      <Button fullWidth style={{ marginTop: '8px' }}>
        Simular novamente
      </Button>
    </Container>
  </BottomSheet>
)

export default BottomsheetResultadoIndisponivel
