import { action, Action } from 'typesafe-actions'

import { ConsultaMargemGeralPayload, ConsultaMargemGeralTypes } from './types'
import { PayloadAtualizarCacheSiape } from './sagas/postAtualizarCacheSiapeSaga'
import { PayloadGetCacheSiape } from './sagas/getCacheSiapeSaga'

export const setCheckConsultaMargem = (payload: boolean): Action =>
  action(ConsultaMargemGeralTypes.SET_CHECK_CONSULTA_MARGEM_GERAL, payload)

export const consultaMargemGeralRequest = (payload: ConsultaMargemGeralPayload): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_REQUEST, payload)

export const consultaMargemGeralSuccess = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS)

export const consultaMargemGeralSuccessCallback = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK)

export const consultaMargemGeralError = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_ERROR)

export const setValorMargem = (payload: number): Action =>
  action(ConsultaMargemGeralTypes.SET_VALOR_MARGEM, payload)

export const setDataValidadeAutorizacao = (payload: string): Action =>
  action(ConsultaMargemGeralTypes.SET_DATA_VALIDADE_AUTORIZACAO, payload)

export const atualizarCacheSiapeRequest = (payload: PayloadAtualizarCacheSiape): Action =>
  action(ConsultaMargemGeralTypes.ATUALIZAR_SIAPE_CACHE, payload)

export const getCacheSiapeRequest = (payload: PayloadGetCacheSiape): Action =>
  action(ConsultaMargemGeralTypes.GET_SIAPE_CACHE, payload)
