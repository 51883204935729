import { call, put } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { upagsRequest, upagsSuccess, upgasError } from '../actions'

export default function* getUpags() {
  try {
    const response = yield* call(services.getUpags)
    const { upags } = response.data
    const data = Object.values(upags).filter((value) => !!value && !!value?.sigla)
    yield put(upagsSuccess(data))
  } catch (error) {
    yield put(upgasError())
    yield put(
      showError({
        title: 'Erro ao buscar lista de upags',
        message: 'Ocorreu um erro ao buscar a listagem de upags.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_007_UPAGS,
        actionCallback: () => upagsRequest(),
      }),
    )
  }
}
