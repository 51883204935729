import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { ColorWrapper, LinkOrange, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { DatePicker } from '@interco/inter-ui/components/DatePicker'
import { requestMarginAccept } from '@store/portabilidade/consultaMarginQuery/actions'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { cpfMask, removeMask } from '@utils/masks'
import { setCpf, setDataNascimento, setNome } from '@store/portabilidade/dadosPessoais/actions'
import { isCpf, isValidDate, validaNome } from '@utils/validators'
import { Input } from '@atoms'
import { ConveniosCodes, StepPortabilidadeNovo } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'

const DadosBasicos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [cpfError, setCpfError] = useState<string>('')
  const [dataNascimentoError, setDataNascimentoError] = useState<string>('')
  const [nomeError, setNomeError] = useState<string>('')
  const { loadingQuery } = useSelector(
    (state: ApplicationState) => state.portabilidade.consultaMarginQuery,
  )
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { nome, cpf, dataNascimento } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosPessoais,
  )
  const { email } = useSelector((state: ApplicationState) => state.portabilidade.contato)

  const handleContinue = () => {
    let valid = true

    if (!cpf || !isCpf(cpf)) {
      setCpfError(!cpf ? 'Informe o CPF' : 'Informe um CPF válido')
      valid = false
    } else {
      setCpfError('')
    }

    if (
      convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL &&
      (!dataNascimento || !isValidDate(dataNascimento))
    ) {
      setDataNascimentoError(
        !dataNascimento ? 'Informe a Data de nascimento' : 'Informe uma data válida',
      )
      valid = false
    } else {
      setDataNascimentoError('')
    }

    if (
      convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL &&
      (!nome || !validaNome(nome))
    ) {
      setNomeError(
        !nome ? 'Informe o seu nome completo' : 'Informe pelo menos um nome e um sobrenome',
      )
      valid = false
    } else {
      setNomeError('')
    }

    if (valid) {
      if (convenioSelecionado === ConveniosCodes.INSS) {
        dispatch(trackingRequest(StepPortabilidadeNovo.DOCUMENTACAO_AUTORIZADA))
        setLoading(true)
        dispatch(
          requestMarginAccept({
            convenioDescricao: 'inss',
            callback: () => {
              setLoading(false)
              dispatch(trackingRequest(StepPortabilidadeNovo.DADOS_PESSOAIS))
              navigate(routes.VALIDA_EMAIL_V2)
            },
            callbackError: () => {
              setLoading(false)
            },
          }),
        )
      } else {
        dispatch(trackingRequest(StepPortabilidadeNovo.DADOS_PESSOAIS))
        navigate(routes.VALIDA_EMAIL_V2)
      }
    }
  }

  useEffect(() => {
    if (cpf && !isCpf(cpf)) {
      setCpfError('Informe um CPF válido')
    } else {
      setCpfError('')
    }

    if (
      convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL &&
      dataNascimento &&
      !isValidDate(dataNascimento)
    ) {
      setDataNascimentoError('Informe uma data válida')
    } else {
      setDataNascimentoError('')
    }
  }, [convenioSelecionado, cpf, dataNascimento, email])

  return (
    <NovoPage
      id="dados-basicos"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <div
          style={{
            borderTop: `1px solid ${Colors.GRAY100}`,
            margin: '0 -24px',
            padding: '16px 24px',
          }}
        >
          {convenioSelecionado === ConveniosCodes.INSS ? (
            <Text variant="caption-1" onClick={() => navigate(routes.TERMO_AUTORIZACAO_V2)}>
              Ao continuar, você declara que aceita o{' '}
              <LinkOrange>Termo de Autorização do INSS.</LinkOrange>
            </Text>
          ) : (
            <></>
          )}

          <Button
            fullWidth
            style={{ marginTop: '8px' }}
            isLoading={loading || loadingQuery}
            disabled={loading || loadingQuery}
            onClick={handleContinue}
          >
            Continuar
          </Button>
        </div>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 16px 0">
        <TextParagraph variant="headline-h1">Para começarmos, informe seus dados</TextParagraph>
      </ColorWrapper>
      <TextParagraph variant="body-3" margin="0 0 32px 0">
        Vamos analisar seus dados para oferecer a melhor condição possível pra você.
      </TextParagraph>
      <Input
        id="dados-cpf"
        type="tel"
        onlyNumber
        value={cpfMask(cpf)}
        label="CPF"
        placeholder="000.000.000-00"
        maxLength={14}
        onChange={(e) => dispatch(setCpf(removeMask((e.target as HTMLInputElement).value)))}
        error={Boolean(cpfError) || Boolean(cpf && !isCpf(cpf))}
        infoText={cpfError}
      />
      {convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL ? (
        <Input
          id="dados-nome"
          type="text"
          label="Nome completo"
          placeholder="Insira seu nome social"
          value={nome}
          onChange={(e) => dispatch(setNome((e.target as HTMLInputElement).value))}
          error={Boolean(nomeError)}
          infoText={nomeError}
        />
      ) : (
        <Input
          id="dados-nome"
          type="text"
          label="Nome social (opcional)"
          placeholder="Insira seu nome social"
          value={nome}
          onChange={(e) => dispatch(setNome((e.target as HTMLInputElement).value))}
        />
      )}

      {convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL ? (
        <DatePicker
          value={dataNascimento ? new Date(dataNascimento) : undefined}
          onChange={(date) => dispatch(setDataNascimento(date?.toDateString() || ''))}
          hasIcon
          error={Boolean(dataNascimentoError)}
          infoText={dataNascimentoError}
          inputEnabled
          label="Informe sua data de nascimento"
          placeholder="Selecionar data"
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}
export default DadosBasicos
