import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ErrorCodesAPI } from '@utils/enums'

import { setLoadingQuery, setMarginSucess } from '../actions'
import { ResultPayload } from '../types'

export default function* marginQueryResultSaga(action: AnyAction) {
  const payload = action.payload as ResultPayload
  try {
    yield put(setLoadingQuery(true))
    const { data } = yield services.postContractMarginResult(payload.convenioDescricao)
    if (data.code === ErrorCodesAPI.R000) {
      yield put(setMarginSucess(true))
    }
    yield put(setLoadingQuery(false))
    yield payload.callback(data.code)
  } catch (error) {
    yield put(setLoadingQuery(false))
    yield payload.callback(ErrorCodesAPI.R0002)
  }
}
