import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const ContainerSimulador = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.GRAY200};
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;

  div > label {
    margin-right: 8px;
  }
`
