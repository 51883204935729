import React from 'react'

import { Props } from './props'
import { SeparatorComponent } from './styles'

export const Separator: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  variant,
  height,
  margin,
  ...props
}) => (
  <SeparatorComponent
    variant={variant}
    height={height}
    margin={margin}
    role="separator"
    {...props}
  />
)
