import { Reducer } from 'redux'

import { SituacaoFuncionalTypes, SituacaoFuncionalState } from './types'

const INITIAL_STATE: SituacaoFuncionalState = {
  situacaoFuncional: '',
  motivo: '',
  upagSelecionada: '',
  patente: '',
  anoIngresso: undefined,
  matriculaValida: true,
  matricula: '',
}

const reducer: Reducer<SituacaoFuncionalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SituacaoFuncionalTypes.SET_SITUACAO_FUNCIONAL:
      return { ...state, situacaoFuncional: action.payload }
    case SituacaoFuncionalTypes.SET_MOTIVO:
      return { ...state, motivo: action.payload }
    case SituacaoFuncionalTypes.SET_UPAG:
      return { ...state, upagSelecionada: action.payload }
    case SituacaoFuncionalTypes.SET_PATENTE:
      return { ...state, patente: action.payload }
    case SituacaoFuncionalTypes.SET_ANO_INGRESSO:
      return { ...state, anoIngresso: action.payload }
    case SituacaoFuncionalTypes.SET_MATRICULA:
      return { ...state, matricula: action.payload }
    case SituacaoFuncionalTypes.SET_MATRICULA_VALIDA:
      return { ...state, matriculaValida: action.payload, loading: false }
    case SituacaoFuncionalTypes.VALIDAR_MATRICULA:
      return { ...state, loading: true, error: false }
    case SituacaoFuncionalTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
