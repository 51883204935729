import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > svg {
    width: 100%;
  }
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
