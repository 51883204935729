import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Alert } from '@interco/inter-ui/components/Alert'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'

import { Dot } from './styles'
import OndeEncontrar from './Bottomsheets/OndeEncontrar'

const DadosNecessarios = [
  'Banco de origem',
  'Número do contrato',
  'Valor da parcela',
  'Saldo devedor',
  'Prazo remanescente',
]

const DicasContratos = () => {
  const navigate = useNavigate()
  const [openBottomsheet, setOpenBottomsheet] = useState(false)
  // TODO: de onde vai vir essa informação de mais de um beneficio?
  const maisDeUmBeneficio = true

  // TODO: bottom sheet do passo a passo inss ja vai ser feito por renan, replicar ele aqui.

  return (
    <NovoPage
      id="dicas-contratos"
      stickyContainerFooterBottom="10px"
      positionFooter="sticky"
      stickyFooter={
        <>
          {maisDeUmBeneficio ? (
            <Alert type="warning">
              É possível adicionar apenas contratos do mesmo convênio e benefício.
            </Alert>
          ) : (
            <></>
          )}
          <Button onClick={() => navigate(routes.ADICIONAR_CONTRATO_V2)}>Adicionar contrato</Button>
        </>
      }
    >
      <ColorWrapper fontWeight="600" color={Colors.GRAY500}>
        <TextParagraph variant="headline-h1">Adicione os contratos que deseja trazer</TextParagraph>
      </ColorWrapper>
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="24px 0">
        <TextParagraph variant="body-2">Você vai precisar desses dados:</TextParagraph>
      </ColorWrapper>
      <>
        {DadosNecessarios.map((dado) => (
          <ColorWrapper
            margin="0 0 16px 0"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Dot />
            <TextParagraph variant="body-3" margin="0">
              {dado}
            </TextParagraph>
          </ColorWrapper>
        ))}
      </>
      <ColorWrapper
        color={Colors.PRIMARY500}
        fontWeight="600"
        onClick={() => setOpenBottomsheet(true)}
      >
        <TextParagraph variant="body-3">Saiba como solicitar</TextParagraph>
      </ColorWrapper>
      {openBottomsheet ? <OndeEncontrar setOpenBottomsheet={setOpenBottomsheet} /> : <></>}
    </NovoPage>
  )
}
export default DicasContratos
