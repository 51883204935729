import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
  flex-wrap: nowrap;
  flex-direction: row;
`

export const GridItem = styled.div<{ error: boolean }>`
  margin: 8px;
`

export const CustomInput = styled.input<{
  error: boolean
  value: string | number | readonly string[] | undefined
}>`
  font-family: Sora;
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 4;
  color: ${(props) => (props.error ? Colors.ERROR500 : Colors.GRAY500)};
  font-size: 2em;
  font-weight: 600;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0.8rem;
  @media (max-width: 430px) {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
  @media (max-width: 320px) {
    font-size: 1rem;
    padding: 0.5rem;
  }

  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${Colors.ERROR500}`
      : `2px solid ${props.value ? Colors.PRIMARY400 : Colors.GRAY200}`};
`
