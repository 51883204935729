import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Container = styled.div`
  & > div {
    width: 100%;
  }
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 16px;
  & {
    align-content: flex-start;
  }
`

export const ListStructure = styled.ul`
  width: 100%;
  margin: 0;
`

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
`

export const NumberCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${Colors.GRAY500};
  border-radius: 50%;

  > p {
    font-size: 12px;
    font-weight: 700;
    color: ${Colors.WHITE};
  }
`
