import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { InputHelperButton, Select } from '@atoms'
import { dialogVariants } from '@utils/bottomSheetVariants'
import { buildConvenios } from '@utils/functions'

import { CustomBottomSheet } from './styles'
import { ConvenioDropdownProps } from './props'

const ConvenioDropdown = ({
  id,
  setOpen,
  setVariant,
  convenioSelecionado,
  convenios,
  loading,
  onChange,
  disabled,
}: ConvenioDropdownProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Qual é o seu convênio?"
        name="dropdown"
        margin="0 0 20px 0"
        value={convenioSelecionado}
        hideSearch={false}
        options={buildConvenios(convenios)}
        placeholder="Selecione o convênio"
        onChange={(value) => {
          onChange(value)
        }}
        loading={loading}
        disabled={disabled}
        helperButton={<InputHelperButton />}
        onClickHelper={() => {
          setVariant(dialogVariants.convenio)
          setOpen(true)
        }}
      />
    )
  }

  return (
    <>
      <InputHelperButton
        color={Colors.PRIMARY500}
        fontSize="12px"
        onClick={() => {
          setVariant(dialogVariants.convenio)
          setOpen(true)
        }}
      >
        Saiba mais
      </InputHelperButton>
      <CustomBottomSheet
        buttonSaibaMais
        id={id}
        label="Qual é o seu convênio?"
        name="dropdown"
        isUsingFilter
        value={convenioSelecionado}
        onChange={(value) => {
          onChange(value)
        }}
        options={buildConvenios(convenios)}
        placeholder="Selecione o convênio"
        isLoading={loading}
        disabled={disabled}
      />
    </>
  )
}
export default memo(ConvenioDropdown)
