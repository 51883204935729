import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > svg {
    width: 100%;
  }
`

export const Line = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
