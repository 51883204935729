import { OrgaoApi } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (idConvenio: number) => {
  const pathname = `${process.env.REACT_APP_GET_ORGAOS}?covenantId=${idConvenio}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<OrgaoApi>({
    pathname,
    method,
    host,
  })
  return response
}
