import React from 'react'

import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'

interface Props {
  setOpenBottomsheetErroBeneficio: React.Dispatch<React.SetStateAction<boolean>>
}
const BottomsheetErroBeneficio = ({ setOpenBottomsheetErroBeneficio }: Props) => (
  <BottomSheet onClose={() => setOpenBottomsheetErroBeneficio(false)}>
    <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 16px 0">
      <TextParagraph variant="headline-h3">Erro na seleção do benefício</TextParagraph>
    </ColorWrapper>
    <TextParagraph variant="body-3">
      No momento, só é possível adicionar contratos do mesmo convênio e benefício. Altere o
      benefício e tente nomente.
    </TextParagraph>
    <div style={{ marginBottom: '16px' }} />
    <TextParagraph variant="body-3">
      Assim que concluir esta contratação, você poderá retornar para adicionar os demais contratos
      que estão vinculados ao outro benefício.
    </TextParagraph>
    <Button
      fullWidth
      onClick={() => setOpenBottomsheetErroBeneficio(false)}
      style={{ marginTop: '24px' }}
    >
      Entendi
    </Button>
  </BottomSheet>
)

export default BottomsheetErroBeneficio
