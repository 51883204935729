import React, { useCallback, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { Page } from '@templates'
import { PageToken } from '@organisms'
import { dialogVariants } from '@utils/bottomSheetVariants'
import { useTrackingPortabilidade } from '@hooks'
import { StepPortabilidade } from '@utils/enums'
import { clearSimulacao, trackingRequest } from '@store/portabilidade/simulacao/actions'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/colors'
import routes from '@routes/routes'

const Token = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const bottomSheetVariant = dialogVariants.informarOutroCelular
  const { error, loading, stepError } = useSelector((state: ApplicationState) => state.token)
  const { telefone } = useSelector((state: ApplicationState) => state.portabilidade.contato)
  const { loading: loadingSimulacao } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacao,
  )

  useTrackingPortabilidade(StepPortabilidade.DADOS_PESSOAIS)

  const onSuccess = useCallback(() => {
    dispatch(trackingRequest(StepPortabilidade.TOKEN_VALIDADO))
    dispatch(clearSimulacao())
    navigate(routes.SELECAO_GRUPO)
  }, [dispatch, navigate])

  return (
    <Page
      id="page-token"
      stickyFooter={
        error ? (
          <Button variant="secondary" onClick={() => setOpen(true)}>
            Não tenho acesso ao celular
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="0 0 8px"
      >
        Verificação de Segurança
      </Paragraph>
      <PageToken
        error={error}
        stepError={stepError}
        type="sms"
        loading={loading || loadingSimulacao || false}
        telefone={telefone}
        onSuccess={onSuccess}
        navegarTrocarTelefone={() => navigate(routes.CONTATO)}
        open={open}
        setOpen={setOpen}
        bottomSheetVariant={bottomSheetVariant}
        stateType="portabilidade"
      />
    </Page>
  )
}

export default Token
