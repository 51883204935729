import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import axios from 'axios'

import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import getValidaToken from '@services/getValidaToken'

import { ErrorType, ValidateTokenRequestPayload } from '../types'
import { setError, tokenSuccess } from '../actions'
import { buildValidateToken } from './models'

const verificarErro = (error: ErrorType) =>
  error?.response?.status === HttpStatus.BAD_REQUEST && axios.isAxiosError(error)

export default function* validateTokenSaga(action: AnyAction) {
  const payload = action.payload as ValidateTokenRequestPayload
  const appState = yield* select((state: ApplicationState) => state)

  try {
    const bodyToken = buildValidateToken[payload.stateType](appState, payload.token, payload.type)

    const { data } = yield getValidaToken(bodyToken, payload.recaptchaToken)
    if (data && data.tokenValido) {
      yield put(tokenSuccess())
      if (payload?.success) {
        yield payload.success()
      }
    } else {
      yield put(
        setError({
          title: 'Token inválido!',
          mensagem: 'Por favor, confirme o token e tente novamente.',
          step: 'validate-token',
        }),
      )
    }
  } catch (error) {
    if (verificarErro(error as ErrorType)) {
      yield put(
        setError({
          title: 'Token inválido!',
          mensagem: 'Por favor, confirme o token e tente novamente.',
          step: 'validate-token',
        }),
      )
    } else {
      yield put(
        setError({
          title: 'Ocorreu um erro!',
          mensagem:
            'Aconteceu um erro interno ao tentar validar seu token! Por favor tente novamente mais tarde.',
          step: 'validate-token',
        }),
      )
    }
  }
}
