import React from 'react'

import { currencyToFloat, formatCurrency } from '@utils/masks'

import { CustomInputNoLabel } from './styles'
import { ValorParcelaProps } from './props'

const ValorParcelaInput = ({
  id,
  label,
  placeholder,
  infoButton,
  valorParcela,
  onChange,
  disabled,
}: ValorParcelaProps) => (
  <CustomInputNoLabel
    id={id}
    type="currency"
    dataTestId={id}
    label={label}
    placeholder={placeholder}
    value={formatCurrency(valorParcela)}
    onChange={(e) => onChange(currencyToFloat((e.target as HTMLInputElement).value))}
    helper={infoButton}
    disabled={disabled}
    maxLength={13}
  />
)

ValorParcelaInput.defaultProps = {
  placeholder: 'Informe o valor da parcela',
  infoButton: <></>,
  disabled: false,
}

export default ValorParcelaInput
