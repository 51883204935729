interface List {
  code: number
  description: string
}

export const listaAutorizacaoPortal: List[] = [
  {
    code: 1,
    description:
      'No portal do Portal do Servidor, escolha a opção “Servidores e Pensionistas” e insira os seus dados de acesso.',
  },
  {
    code: 2,
    description: 'Já no SIGEPE, clique nas três barrinhas que no canto superior esquerdo da tela.',
  },
  {
    code: 3,
    description: 'Clique em “Gestão de Pessoas”, e logo em seguida em “Consignações”.',
  },
  {
    code: 4,
    description:
      'Na parte superior da tela seguinte, clique em “Autorizações” e selecione “Gerar Autorização do Consignatário”. ',
  },
  {
    code: 5,
    description:
      'Então, escolha o tipo de consignação, selecione Banco Inter S.A. como consignatário e prossiga. ',
  },
  {
    code: 6,
    description:
      'Sem fechar o portal, abra seu e-mail e  busque pelo código enviado por: sigepe.nao-responda@planejamento.gov.br ',
  },
  {
    code: 7,
    description: 'No portal do servidor, insira o código e clique em “Gerar Autorização”.',
  },
]
