import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { InputHelperButton, Select } from '@atoms'
import { buildUpags } from '@utils/functions'
import { dialogVariants } from '@utils/bottomSheetVariants'

import { CustomBottomSheet } from './styles'
import { UpagDropdownProps } from './props'

const UpagDropdown = ({
  id,
  setOpen,
  setVariant,
  upagSelecionado,
  upags,
  loading,
  onChange,
  disabled,
}: UpagDropdownProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Qual é a sigla UPAG?"
        name="dropdown"
        margin="0 0 20px 0"
        value={upagSelecionado}
        hideSearch={false}
        options={buildUpags(upags)}
        placeholder="Selecione a sigla UPAG"
        onChange={(value) => {
          onChange(value)
        }}
        loading={loading}
        disabled={disabled}
        helperButton={<InputHelperButton />}
        onClickHelper={() => {
          setVariant(dialogVariants.siglaUpag)
          setOpen(true)
        }}
      />
    )
  }
  return (
    <>
      <InputHelperButton
        color={Colors.PRIMARY500}
        fontSize="12px"
        onClick={() => {
          setVariant(dialogVariants.siglaUpag)
          setOpen(true)
        }}
      >
        Saiba mais
      </InputHelperButton>
      <CustomBottomSheet
        buttonSaibaMais
        id={id}
        label="Qual é a sigla UPAG?"
        name="dropdown"
        isUsingFilter
        value={upagSelecionado}
        onChange={(value) => {
          onChange(value)
        }}
        options={buildUpags(upags)}
        placeholder="Selecione a sigla UPAG"
        isLoading={loading}
        disabled={disabled}
      />
    </>
  )
}
export default memo(UpagDropdown)
