import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import axios from 'axios'

import { ApplicationState } from '@store/types'
import services from '@services'
import { HttpStatus } from '@services/base'

import { sendToken, setError, tokenSuccess } from '../actions'
import { SendTokenPayload } from '../types'
import { buildToken } from './models'

export default function* sendTokenSaga(action: AnyAction) {
  const payload = action.payload as SendTokenPayload

  const appState = yield* select((state: ApplicationState) => state)
  const bodyToken = buildToken[payload.stateType](appState, payload.type)

  try {
    yield services.postEnviaToken(bodyToken, payload.recaptchaToken)
    yield put(tokenSuccess())
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      yield put(
        setError({
          title: 'Ocorreu um erro!',
          mensagem: 'Quantidade de solicitações máximas foram atingidas',
          callback: () => sendToken(payload),
          step: 'create-token',
        }),
      )
    } else {
      yield put(
        setError({
          title: 'Ocorreu um erro!',
          mensagem: 'Aconteceu um erro ao enviar token. Tente novamente mais tarde!',
          callback: () => sendToken(payload),
          step: 'create-token',
        }),
      )
    }
  }
}
