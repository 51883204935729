import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { removeMask } from '@utils/masks'

import {
  dadosBeneficiarioInssError,
  dadosBeneficiarioInssRequest,
  dadosBeneficiarioInssSuccess,
} from '../actions'
import { DadosInssPayload } from '../types'

export default function* getDadosBeneficiarioInssSaga(action: AnyAction) {
  const payload = action.payload as DadosInssPayload
  const cpf = yield* select((state: ApplicationState) => state.portabilidade.dadosPessoais.cpf)
  const beneficio = yield* select(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais.matricula,
  )

  try {
    const { data } = yield services.getDadosBeneficiarioInss(
      removeMask(cpf),
      removeMask(String(beneficio)),
    )
    yield put(dadosBeneficiarioInssSuccess(data))
    yield payload.callback(data.isConsultaRealizada, data.pendencia, data?.isBeneficioValido)
  } catch (error) {
    yield put(dadosBeneficiarioInssError())
    yield put(
      showError({
        title: 'Erro ao buscar os dados do beneficiário',
        message: 'Ocorreu um erro ao buscar os dados do beneficiário.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => dadosBeneficiarioInssRequest(payload),
      }),
    )
  }

  return cpf
}
