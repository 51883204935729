import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import {
  simulacaoPortabilidadeError,
  simulacaoPortabilidadeSuccess,
} from '@store/portabilidade/simulacao/actions'
import { ConveniosCodes, ErrorCodesAPI } from '@utils/enums'
import postSimulatePortability from '@services/postSimulatePortability'
import { ApplicationState } from '@store/types'

import { SimulacaoPortabilidadeRequestPayload } from '../types'

export default function* postSimularPortabilidade(action: AnyAction) {
  const payload = action.payload as SimulacaoPortabilidadeRequestPayload
  const { convenioSelecionado } = yield* select(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  try {
    const convenioUrl = convenioSelecionado === ConveniosCodes.INSS ? 'inss' : 'siape'
    const { data } = yield call(postSimulatePortability, convenioUrl, {
      debtBalance: payload.debtBalance,
      installmentValue: payload.installmentValue,
    })
    yield put(simulacaoPortabilidadeSuccess(data))
    yield payload.callback(data.errorCode)
  } catch (error) {
    yield put(simulacaoPortabilidadeError())
    yield payload.callback(ErrorCodesAPI.R0003)
  }
}
