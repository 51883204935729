import { call, put } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { fontesSuccess, fontesError, fontesRequest } from '../actions'

export default function* getFontesPagamentos() {
  try {
    const response = yield* call(services.getFontesPagamentos)
    const { familiasConvenios } = response.data
    yield put(
      fontesSuccess(Object.values(familiasConvenios).filter((value) => !!value && !!value?.codigo)),
    )
  } catch (error) {
    yield put(fontesError())
    yield put(
      showError({
        title: 'Erro ao buscar as fontes de pagamento',
        message: 'Ocorreu um erro listagem de fontes de pagamento.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_002_FONTES,
        actionCallback: () => fontesRequest(),
      }),
    )
  }
}
