import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import { Colors } from '@utils/colors'

import { Container, Column, Line } from './styles'

interface OwnProps {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetValorParcelaHelp = ({ setShowBottomSheet }: OwnProps) => {
  const navigate = useNavigate()
  return (
    <BottomSheet onClose={() => setShowBottomSheet(false)} noSwipe>
      <Container>
        <Column>
          <Text variant="body-2" bold colorWeight={500}>
            Valor da parcela
          </Text>
          <Text variant="body-3">
            Informe o valor da parcela que está no contrato que você deseja trazer para o Inter.
          </Text>
          <Text variant="body-3">Em caso de múltiplos contratos, informe a soma das parcelas.</Text>
        </Column>

        <Column>
          <Text variant="body-3" bold colorWeight={500}>
            Você pode encontrar esses dados:
          </Text>
          <Line>
            <div
              style={{
                width: '6px',
                height: '6px',
                backgroundColor: Colors.GRAY500,
                borderRadius: '50%',
              }}
            />
            <Text variant="body-3">No seu contracheque.</Text>
          </Line>
          <Line>
            <div
              style={{
                width: '6px',
                height: '6px',
                backgroundColor: Colors.GRAY500,
                borderRadius: '50%',
              }}
            />
            <Text variant="body-3">No contrato de empréstimo que deseja trazer para o Inter.</Text>
          </Line>
          <Line>
            <div
              style={{
                width: '6px',
                height: '6px',
                backgroundColor: Colors.GRAY500,
                borderRadius: '50%',
              }}
            />
            <Text variant="body-3">
              No seu Documento Descritivo de Crédito (DDC)
              <br />
              <Text
                variant="body-3"
                colorWeight={500}
                bold
                color="primary"
                onClick={() => {
                  navigate(routes.DDC)
                }}
              >
                Saiba como solicitar
              </Text>
            </Text>
          </Line>
        </Column>

        <Button fullWidth style={{ marginTop: '8px' }} onClick={() => setShowBottomSheet(false)}>
          Entendi
        </Button>
      </Container>
    </BottomSheet>
  )
}

export default BottomsheetValorParcelaHelp
