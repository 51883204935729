import { action, Action } from 'typesafe-actions'

import { ValidarMatriculaPayload } from '../situacaoFuncional/types'
import { DadosConsultaInssState, DadosInssPayload, DadosProfissionaisTypes } from './types'

export const setFonteSelecionada = (payload: string | number | undefined): Action =>
  action(DadosProfissionaisTypes.SET_FONTE_SELECIONADA, payload)

export const setConvenioSelecionado = (payload: string | number | undefined): Action =>
  action(DadosProfissionaisTypes.SET_CONVENIO_SELECIONADO, payload)

export const setNomeConvenioSelecionado = (payload: string): Action =>
  action(DadosProfissionaisTypes.SET_NOME_CONVENIO_SELECIONADO, payload)

export const setEstadoSelecionado = (payload: string | number | undefined): Action =>
  action(DadosProfissionaisTypes.SET_ESTADO_SELECIONADO, payload)

export const setCidadeSelecionada = (payload: string | number | undefined): Action =>
  action(DadosProfissionaisTypes.SET_CIDADE_SELECIONADA, payload)

export const setOrgaoSelecionado = (payload: string | number | undefined): Action =>
  action(DadosProfissionaisTypes.SET_ORGAO_SELECIONADO, payload)

export const dadosBeneficiarioInssRequest = (payload: DadosInssPayload): Action =>
  action(DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_REQUEST, payload)

export const dadosBeneficiarioInssSuccess = (payload: DadosConsultaInssState): Action =>
  action(DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_SUCCESS, payload)

export const dadosBeneficiarioInssError = (): Action =>
  action(DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_ERROR)

export const setCheckAutorizacaoInss = (payload: boolean): Action =>
  action(DadosProfissionaisTypes.SET_CHECK_AUTORIZACAO_INSS, payload)

export const setMatricula = (payload: string): Action =>
  action(DadosProfissionaisTypes.SET_MATRICULA, payload)

export const setIsMatriculaValida = (payload: boolean): Action =>
  action(DadosProfissionaisTypes.SET_MATRICULA_VALIDA, payload)

export const validarMatriculaRequest = (payload: ValidarMatriculaPayload): Action =>
  action(DadosProfissionaisTypes.VALIDAR_MATRICULA, payload)

export const setSalario = (payload: number): Action =>
  action(DadosProfissionaisTypes.SET_SALARIO, payload)

export const upagsRequest = () => action(DadosProfissionaisTypes.UPAGS_REQUESTS)
