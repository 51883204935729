import { BaseServices, HttpMethod } from '@services/base'

export default (socialNumber: string) => {
  const pathname = process.env.REACT_APP_POST_ATUALIZA_CACHE_SIAPE_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    socialNumber,
    viewCode: 'SP01',
  }

  return BaseServices.request({
    pathname,
    method,
    host,
    headers,
  })
}
