import { BridgeService } from '@services/bridge'
import { IDataLayer, ElementAction } from '@utils/types'

interface TagProps {
  product: string
  name: string
  ref_type?: string
  installments_number?: string | number
  action_id?: string
  content_action?: string
  value?: string | number
  content_aba?: string
  content_grupo?: string | number
  content_conv?: string | number
  content_sel?: string
  ref_figma: string
  screen?: string
  utm_campaign?: string
  item_name?: string
  link?: string
}

interface TagPropsWeb {
  element_name: string
  section_name: string
  element_action?: ElementAction
  c_page?: string
  step?: string | null
  redirect_url?: string | null
  input_name_1?: string | number | boolean
  input_value_1?: string | number | boolean
  input_name_2?: string | number | boolean
  input_value_2?: string | number | boolean
  input_name_3?: string | number | boolean
  input_value_3?: string | number | boolean
  input_name_4?: string | number | boolean
  input_value_4?: string | number | boolean
  input_name_5?: string | number | boolean
  input_value_5?: string | number | boolean
  input_name_6?: string | number | boolean
  input_value_6?: string | number | boolean
  input_name_7?: string | number | boolean
  input_value_7?: string | number | boolean
  input_name_8?: string | number | boolean
  input_value_8?: string | number | boolean
  input_name_9?: string | number | boolean
  input_value_9?: string | number | boolean
}

export default {
  enviarTagueamentoWeb: ({
    element_name = '',
    section_name,
    element_action,
    c_page = 'null',
    step,
    redirect_url,
    input_name_1 = 'null',
    input_value_1 = 'null',
    input_name_2 = 'null',
    input_value_2 = 'null',
    input_name_3 = 'null',
    input_value_3 = 'null',
    input_name_4 = 'null',
    input_value_4 = 'null',
    input_name_5 = 'null',
    input_value_5 = 'null',
    input_name_6 = 'null',
    input_value_6 = 'null',
    input_name_7 = 'null',
    input_value_7 = 'null',
    input_name_8 = 'null',
    input_value_8 = 'null',
    input_name_9 = 'null',
    input_value_9 = 'null',
  }: TagPropsWeb) => {
    const dataLayer: IDataLayer = {
      event: 'ga_event_body_cred',
      params: {
        section_name: String(section_name),
        element_action,
        element_name: String(element_name),
        c_page,
        step: step || 'null',
        redirect_url: redirect_url || 'null',
      },
      params_input: {
        input_name_1,
        input_value_1,
        input_name_2,
        input_value_2,
        input_name_3,
        input_value_3,
        input_name_4,
        input_value_4,
        input_name_5,
        input_value_5,
        input_name_6,
        input_value_6,
        input_name_7,
        input_value_7,
        input_name_8,
        input_value_8,
        input_name_9,
        input_value_9,
      },
    }
    if (window.dataLayer) {
      window.dataLayer.push(dataLayer)
      // console.log(window.dataLayer.filter((event) => event.event === 'ga_event_body_cred'))
    }
  },
  enviarTagueamentoApp: ({
    action_id = '',
    screen,
    content_action = '',
    installments_number = '',
    value = '',
    content_aba = '',
    content_grupo = '',
    content_conv = '',
    content_sel = '',
    ref_figma,
    ref_type,
    product,
    name,
    utm_campaign = '',
    item_name = '',
    link = '',
  }: TagProps) => {
    if (!BridgeService.isBrowser()) {
      BridgeService.requestAnalytics(name, {
        ref_figma,
        screen: String(screen),
        ref_type: String(ref_type),
        content_action: String(content_action),
        value: String(value),
        currency: 'BRL',
        installments_number: String(installments_number),
        item_name: String(item_name),
        action_id: String(action_id),
        product,
        utm_campaign,
        link,
        content_aba: String(content_aba),
        content_grupo: String(content_grupo).toUpperCase(),
        content_conv: String(content_conv).toUpperCase(),
        content_sel: String(content_sel),
      })
    }
  },
}
