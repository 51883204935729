import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const FooterGrid = styled.div`
  background-color: transparent;
  margin-top: auto;
`

export const LinkPrivacidade = styled.a`
  color: ${Colors.PRIMARY500};
  background-color: transparent;
  text-decoration: none;
  font-weight: 700;
`
export const Div = styled.div`
  & > label {
    align-items: flex-end;
  }
`
