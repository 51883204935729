import React from 'react'

import { GridForIcon } from './styles'
import { GridProps } from './props'

const GridStyled = ({ children, justify, align, className, margin }: GridProps) => (
  <GridForIcon margin={margin} justifyContent={justify} alignItems={align} className={className}>
    {children}
  </GridForIcon>
)

GridStyled.defaultProps = {
  margin: '16px 0 0',
}

export default GridStyled
