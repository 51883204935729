/**
 * Actions Types
 */

export enum DadosPessoaisTypes {
  SET_NOME = '@dadosPessoais/SET_NOME',
  SET_CPF = '@dadosPessoais/SET_CPF',
  SET_DATA_NASCIMENTO = '@dadosPessoais/SET_DATA_NASCIMENTO',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface DadosPessoaisState {
  readonly nome: string
  readonly cpf: string
  readonly dataNascimento: string
}
