import styled from 'styled-components'

export const ImagesLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ImageBanner = styled.img`
  width: 250px;
  border-radius: 8px;

  @media (max-width: 425px) {
    width: 170px;
  }
  @media (max-width: 375px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 120px;
  }
`
