import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import ChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { validarContrato } from '@utils/validators'
import { Input } from '@atoms'
import {
  setSaldoDevedorContratoAtual,
  setParcelasRestantes,
  setNumeroContrato,
  setvalorParcela,
} from '@store/portabilidade/contratoAtual/actions'
import { bancosRequest } from '@store/globalApi/actions'
import { Alert } from '@interco/inter-ui/components/Alert'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { setMatricula } from '@store/portabilidade/dadosProfissionais/actions'

import { LineInfo } from './styles'
import BottomsheetErroBeneficio from './Bottomsheets'

const AdicionarContrato = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openBottomsheetBeneficio, setOpenBottomsheetBeneficio] = useState(false)
  const [openBottomsheetErroBeneficio, setOpenBottomsheetErroBeneficio] = useState(false)
  const [numeroContratoError, setNumeroContratoError] = useState<string>('')
  const [valorParcelaError, setValorParcelaError] = useState<string>('')
  const [saldoDevedorError, setSaldoDevedorError] = useState<string>('')
  const [parcelaRestanteError, setParcelaRestanteError] = useState<string>('')
  const [bancoError, setBancoError] = useState<string>('')
  const [beneficioError, setBeneficioError] = useState<string>('')
  const { numeroContrato, saldoDevedor, valorParcela, parcelasRestantes } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { matricula } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { bancoSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { bancos = [] } = useSelector((state: ApplicationState) => state.globalApi)

  // TODO: de onde vai vir essa informação de mais de um beneficio?
  const maisDeUmBeneficio = true

  // TODO: de onde vai vir esse array de matriculas?
  const matriculasDisponiveis = ['123456', '445677']

  useEffect(() => {
    if (!bancos || bancos.length === 0) {
      dispatch(bancosRequest())
    }
  }, [bancos, dispatch])

  const validateField = (
    condition: boolean,
    setError: (message: string) => void,
    errorMessage: string,
  ) => {
    if (!condition) {
      setError(errorMessage)
      return false
    }
    setError('')
    return true
  }

  const handleContinue = () => {
    let valid = true

    valid = validateField(Boolean(matricula), setBeneficioError, 'Selecione o benefício') && valid

    valid =
      validateField(Boolean(bancoSelecionado), setBancoError, 'Informe o banco de origem') && valid

    valid =
      validateField(
        Boolean(numeroContrato) && validarContrato(numeroContrato),
        setNumeroContratoError,
        !numeroContrato ? 'Informe o número do contrato' : 'Informe um número válido',
      ) && valid

    valid =
      validateField(Boolean(valorParcela), setValorParcelaError, 'Informe o valor da parcela') &&
      valid

    valid =
      validateField(
        Boolean(saldoDevedor),
        setSaldoDevedorError,
        'Informe um saldo devedor válido',
      ) && valid

    valid =
      validateField(
        Boolean(parcelasRestantes),
        setParcelaRestanteError,
        'Informe uma quantidade válida',
      ) && valid

    if (valid) {
      // TODO: navegar para tela de contrato cadastrado
      navigate(routes.VALIDA_EMAIL_V2)
    }
  }

  useEffect(() => {
    if (numeroContrato && !validarContrato(numeroContrato)) {
      setNumeroContratoError('Informe um número válido')
    } else {
      setNumeroContratoError('')
    }
  }, [numeroContrato])

  return (
    <NovoPage
      id="dados-basicos"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <>
          {maisDeUmBeneficio ? (
            <Alert type="warning">
              É possível adicionar apenas contratos do mesmo convênio e benefício.
            </Alert>
          ) : (
            <></>
          )}
          <Button fullWidth style={{ marginTop: '8px' }} onClick={handleContinue}>
            Continuar
          </Button>
        </>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 16px 0">
        <TextParagraph variant="headline-h1">Informe os dados do seu contrato</TextParagraph>
      </ColorWrapper>

      <Input
        id="beneficio"
        label="Benefício"
        placeholder="Selecione o benefício"
        value={matricula}
        iconRight={<ChevronDown color={Colors.PRIMARY500} width={24} height={24} />}
        onClick={() => setOpenBottomsheetBeneficio(true)}
        error={Boolean(beneficioError)}
        infoText={beneficioError}
      />

      <Input
        id="banco-origem"
        label="Banco de origem"
        placeholder="Selecione o banco"
        value={bancos.find((banco) => banco.codigo === bancoSelecionado)?.nome}
        iconRight={<ChevronRight color={Colors.PRIMARY500} width={24} height={24} />}
        onClick={() => navigate(routes.BANCO_CONTRATO_V2)}
        error={Boolean(bancoError)}
        infoText={bancoError}
      />

      <Input
        id="contrato"
        value={numeroContrato}
        label="Número do contrato"
        placeholder="Informe o número do contrato"
        onChange={(e) => dispatch(setNumeroContrato((e.target as HTMLInputElement).value))}
        error={Boolean(numeroContratoError)}
        infoText={numeroContratoError}
      />
      <Input
        id="valor-parcela"
        type="currency"
        onlyNumber
        value={valorParcela}
        label="Valor da parcela"
        placeholder="R$ 0,00"
        onChange={(e) => dispatch(setvalorParcela((e.target as HTMLInputElement).value))}
        error={Boolean(valorParcelaError)}
        infoText={valorParcelaError}
      />
      <Input
        id="saldo-devedor"
        type="currency"
        onlyNumber
        value={saldoDevedor}
        label="Saldo devedor"
        placeholder="R$ 0,00"
        onChange={(e) =>
          dispatch(setSaldoDevedorContratoAtual((e.target as HTMLInputElement).value))
        }
        error={Boolean(saldoDevedorError)}
        infoText={saldoDevedorError}
      />
      <Input
        id="parcela-restante"
        type="number"
        onlyNumber
        value={parcelasRestantes}
        label="Parcelas remanescentes"
        placeholder="0"
        onChange={(e) => dispatch(setParcelasRestantes((e.target as HTMLInputElement).value))}
        error={Boolean(parcelaRestanteError)}
        infoText={parcelaRestanteError}
      />
      {openBottomsheetBeneficio ? (
        <BottomSheet onClose={() => setOpenBottomsheetBeneficio(false)}>
          <ColorWrapper color={Colors.GRAY500} fontWeight="600">
            <TextParagraph variant="headline-h3">Selecione o benefício</TextParagraph>
          </ColorWrapper>
          {matriculasDisponiveis.map((matriculaItem) => (
            <LineInfo
              key={matriculaItem}
              onClick={() => {
                dispatch(setMatricula(matriculaItem))
                setOpenBottomsheetBeneficio(false)
              }}
            >
              <ColorWrapper color={Colors.GRAY500} margin="0" fontWeight="600">
                <TextParagraph variant="body-3">{matriculaItem}</TextParagraph>
              </ColorWrapper>
              <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
            </LineInfo>
          ))}
        </BottomSheet>
      ) : (
        <></>
      )}
      {openBottomsheetErroBeneficio ? (
        <BottomsheetErroBeneficio
          setOpenBottomsheetErroBeneficio={setOpenBottomsheetErroBeneficio}
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}
export default AdicionarContrato
