/**
 * Actions Types
 */
export enum DadosProfissionaisTypes {
  SET_FONTE_SELECIONADA = '@dadosProfissionais/SET_FONTE_PAGEMENTO',
  SET_CONVENIO_SELECIONADO = '@dadosProfissionais/SET_CONVENIO_SELECIONADO',
  SET_NOME_CONVENIO_SELECIONADO = '@dadosProfissionais/SET_NOME_CONVENIO_SELECIONADO',
  SET_ESTADO_SELECIONADO = '@dadosProfissionais/SET_ESTADO_SELECIONADO',
  SET_CIDADE_SELECIONADA = '@dadosProfissionais/SET_CIDADE_SELECIONADA',
  SET_ORGAO_SELECIONADO = '@dadosProfissionais/SET_ORGAO_SELECIONADO',
  SET_SALARIO = '@dadosProfissionais/SET_SALARIO',
  SET_MATRICULA = '@dadosProfissionais/SET_MATRICULA',
  SET_MATRICULA_VALIDA = '@dadosProfissionais/SET_MATRICULA_VALIDA',
  VALIDAR_MATRICULA = '@dadosProfissionais/VALIDAR_MATRICULA',
  DADOS_BENEFICIARIO_INSS_SUCCESS = '@dadosProfissionais/DADOS_BENEFICIARIO_INSS_SUCCESS',
  DADOS_BENEFICIARIO_INSS_ERROR = '@dadosProfissionais/DADOS_BENEFICIARIO_INSS_ERROR',
  DADOS_BENEFICIARIO_INSS_REQUEST = '@dadosProfissionais/DADOS_BENEFICIARIO_INSS_REQUEST',
  SET_CHECK_AUTORIZACAO_INSS = '@dadosProfissionais/SET_CHECK_AUTORIZACAO_INSS',
  SET_UPAG = '@dadosProfissionais/SET_UPAG',
  UPAGS_REQUESTS = '@dadosProfissionais/UPAGS_REQUESTS',

  RESET = 'RESET',
}
/**
 * State Type
 */
export interface DadosProfissionaisState {
  readonly cidadeSelecionada?: string | number
  readonly convenioSelecionado?: string | number
  readonly nomeConvenioSelecionado?: string
  readonly estadoSelecionado?: string | number
  readonly fonteSelecionada?: number
  readonly orgaoSelecionado?: string | number
  readonly salario: number | ''
  readonly matricula?: string | number
  readonly matriculaValida: boolean
  readonly dadosInssLoading: boolean
  readonly dadosInssError?: boolean
  readonly dadosBeneciario?: DadosConsultaInssState
  readonly checkAutorizacaoInss?: boolean
}

export type DadosProfissionaisComponentsTypes = 'convenio' | 'estado' | 'orgao' | 'cidade'

export interface DadosBeneficiarioInss {
  estado: string
  dataNascimento: string
  situacaoFuncional: string
  margem: string
}

export interface DadosInfoInss {
  banco: string
  agencia: string
  conta: string
  digitoConta: string
  tipoCredito: string
  margem: number
  nome: string
}
export interface DadosConsultaInssState {
  isConsultaRealizada: boolean
  isBeneficioValido: boolean
  beneficiado?: DadosBeneficiarioInss
  pendencia?: tipoPendencia
  infoInss?: DadosInfoInss
}

type tipoPendencia =
  | 'PENDENCIA_REVERSIVEL_LEAD_PORTABILIDADE'
  | 'SEM_PENDENCIA_LEAD_PORTABILIDADE'
  | 'PENDENCIA_REVERSIVEL_LEAD_NOVO'
  | 'SEM_PENDENCIA_LEAD_NOVO'
  | 'PENDENCIA_IRREVERSIVEL'
  | ''

export type DadosInssPayload = {
  callback: (isConsultaRealizada: boolean, pendencia: string, isBeneficioValido?: boolean) => void
}

export type ValidarMatriculaPayload = {
  callback: (isValido: boolean | undefined) => void
}

export type DadosInssPayloadNew = {
  callback: (pendencia: string) => void
}
