import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { isEmail } from '@utils/validators'
import { Button } from '@interco/inter-ui/components/Button'
import { resetToken, sendToken, validateToken } from '@store/token/actions'
import { useGetRecaptchaToken } from '@hooks'
import { Separator } from '@atoms/Separator'
import routes from '@routes/routes'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { Input } from '@atoms'
import { setEmail } from '@store/portabilidade/contato/actions'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'

import InputToken from '../InputToken'

const RESEND_TIMER = 60

const ValidaEmail = () => {
  const type = 'email'
  const stateType = 'portabilidade'
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getRecaptchaToken } = useGetRecaptchaToken()
  const [timer, setTimer] = useState(RESEND_TIMER)
  const [showTokenField, setShowTokenField] = useState(false)
  const [token, setToken] = useState('')
  const [filledToken, setFilledToken] = useState(false)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { error, stepError } = useSelector((state: ApplicationState) => state.token)
  const { email } = useSelector((state: ApplicationState) => state.portabilidade.contato)

  const onSuccess = useCallback(() => {
    dispatch(trackingRequest(StepPortabilidadeNovo.TOKEN_VALIDADO_EMAIL))
    navigate(routes.VALIDA_TELEFONE_V2)
  }, [dispatch, navigate])

  const sendTokenCallback = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      if (recaptchaToken) {
        dispatch(trackingRequest(StepPortabilidadeNovo.ENVIO_TOKEN_EMAIL))
        dispatch(sendToken({ type, stateType, recaptchaToken }))
      }
    } else {
      dispatch(trackingRequest(StepPortabilidadeNovo.ENVIO_TOKEN_EMAIL))
      dispatch(sendToken({ type, stateType, recaptchaToken: 'NULLCAPTCHA' }))
    }
  }, [dispatch, getRecaptchaToken, isApp, stateType, type])

  useEffect(() => {
    if (showTokenField) {
      sendTokenCallback()
    }
  }, [sendTokenCallback, showTokenField])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const sendValidateToken = useCallback(async () => {
    let recaptchaToken = 'NULLCAPTCHA'

    if (!isApp) recaptchaToken = await getRecaptchaToken()

    dispatch(
      validateToken({
        stateType,
        token,
        type,
        recaptchaToken,
        success: () => onSuccess(),
      }),
    )
    dispatch(trackingRequest(StepPortabilidadeNovo.VALIDA_TOKEN_EMAIL))
  }, [isApp, dispatch, stateType, token, type, getRecaptchaToken, onSuccess])

  useEffect(() => {
    if (filledToken) {
      sendValidateToken()
    }
  }, [filledToken, sendValidateToken])

  useEffect(() => {
    dispatch(resetToken())
  }, [dispatch])

  return (
    <NovoPage id="valida-email" stickyFooter={<></>} stickyContainerFooterBottom="180px">
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 8px 0">
        <TextParagraph variant="headline-h2">Agora, vamos validar o seu e-mail</TextParagraph>
      </ColorWrapper>
      <ColorWrapper color={Colors.GRAY500} margin="0 0 24px 0">
        <TextParagraph variant="caption-1">
          Enviaremos um código de verificação para o endereço de e-mail que você informar.
        </TextParagraph>
      </ColorWrapper>
      <Input
        id="valida-email"
        type="email"
        label="Informe seu E-mail"
        placeholder="email@email.com.br"
        value={email}
        onChange={(e) => {
          setShowTokenField(false)
          dispatch(setEmail((e.target as HTMLInputElement).value))
        }}
        error={email.length ? !isEmail(email) : false}
        infoText={email.length && !isEmail(email) ? 'Preencha com um e-mail válido.' : ''}
      />
      <Button
        variant="primary"
        disabled={showTokenField}
        onClick={() => {
          if (isEmail(email)) {
            setShowTokenField(true)
          }
        }}
      >
        Enviar e-mail
      </Button>
      {showTokenField ? (
        <>
          <Separator
            margin="24px 0 0 0"
            variant="small"
            style={{ backgroundColor: Colors.GRAY100 }}
          />
          <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="24px 0 0px 0">
            <TextParagraph variant="headline-h3">Insira o código enviado por e-mail:</TextParagraph>
          </ColorWrapper>
          <InputToken
            error={error}
            stepError={stepError}
            getToken={(t, filled) => {
              setToken(t)
              setFilledToken(filled)
            }}
            id="input-token"
          />
          <Button
            variant="link"
            disabled={timer > 0}
            onClick={() => {
              dispatch(resetToken())
              sendTokenCallback()
              setTimer(RESEND_TIMER)
            }}
            style={{
              margin: '10px auto 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              display: 'flex',
            }}
          >
            {`Reenviar código ${timer > 0 ? `(${timer}s)` : ''}`}
          </Button>
        </>
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default ValidaEmail
