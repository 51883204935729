import { Reducer } from 'redux'

import { ConsultaMargemGeralState, ConsultaMargemGeralTypes } from './types'

const INITIAL_STATE: ConsultaMargemGeralState = {
  checkConsultaMargem: false,
  loading: false,
  error: false,
  consultaMargemAutorizada: false,
  margem: 0,
  dataValidadeAutorizacao: '',
}

const reducer: Reducer<ConsultaMargemGeralState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultaMargemGeralTypes.SET_CHECK_CONSULTA_MARGEM_GERAL:
      return { ...state, checkConsultaMargem: action.payload }
    case ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_REQUEST:
      return { ...state, consultaMargemAutorizada: false, error: false, loading: true }
    case ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS:
      return {
        ...state,
        consultaMargemAutorizada: true,
        error: false,
        loading: false,
      }
    case ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK:
      return {
        ...state,
        consultaMargemAutorizada: false,
        error: false,
        loading: false,
      }
    case ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_ERROR:
      return { ...state, error: true, loading: false }
    case ConsultaMargemGeralTypes.SET_VALOR_MARGEM:
      return { ...state, margem: action.payload }
    case ConsultaMargemGeralTypes.SET_DATA_VALIDADE_AUTORIZACAO:
      return { ...state, dataValidadeAutorizacao: action.payload }
    case ConsultaMargemGeralTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
