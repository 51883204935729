import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { removeMask, phoneMask } from '@utils/masks'
import { isCelphone, isEmail } from '@utils/validators'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/colors'
import { Page } from '@templates'
import { Input } from '@atoms'
import { setEmail, setTelefone } from '@store/portabilidade/contato/actions'
import { StepPortabilidade } from '@utils/enums'
import { useTrackingPortabilidade } from '@hooks'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import routes from '@routes/routes'

const Contato = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { telefone, email } = useSelector((state: ApplicationState) => state.portabilidade.contato)

  const disableContinue = (): boolean => !isEmail(email) || !isCelphone(phoneMask(telefone))

  useTrackingPortabilidade(StepPortabilidade.CONTATO)

  return (
    <Page
      id="page-portabilidade-contato"
      stickyFooter={
        <Button
          disabled={disableContinue()}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidade.CONFIRMAR_CONTATO))
            navigate(routes.TOKEN)
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph
        fontFamily="Sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="0 0 24px"
      >
        Contato
      </Paragraph>
      <Input
        id="contato-telefone"
        label="Qual é o seu telefone?"
        placeholder="(00) 00000-0000"
        value={phoneMask(telefone)}
        onChange={(e) => dispatch(setTelefone(removeMask((e.target as HTMLInputElement).value)))}
        maxLength={15}
        error={telefone.length ? !isCelphone(phoneMask(telefone)) : false}
        infoText={
          telefone.length && !isCelphone(phoneMask(telefone))
            ? 'Preencha com um número válido.'
            : ''
        }
      />
      <Input
        id="contato-email"
        type="email"
        label="E seu e-mail?"
        placeholder="digite seu e-mail"
        value={email}
        onChange={(e) => dispatch(setEmail((e.target as HTMLInputElement).value))}
        error={email.length ? !isEmail(email) : false}
        infoText={email.length && !isEmail(email) ? 'Preencha com um e-mail válido.' : ''}
      />
    </Page>
  )
}

export default Contato
