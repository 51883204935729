import { Reducer } from 'redux'

import { ConsultaMarginQueryState, ConsultMarginQueryTypes } from './types'

const INITIAL_STATE: ConsultaMarginQueryState = {
  dadosBancarios: {
    banco: '',
    agencia: '',
    conta: '',
    digitoConta: '',
    tipoCredito: '',
    margem: 0,
    nome: '',
  },
  loadingQuery: false,
  counterRecall: 0,
  code: '',
  marginSuccess: false,
}

const reducer: Reducer<ConsultaMarginQueryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultMarginQueryTypes.SET_INFO_INSS_DADOS_BANCARIOS:
      return {
        ...state,
        dadosBancarios: action.payload,
      }
    case ConsultMarginQueryTypes.SET_LOADING_QUERY:
      return { ...state, loadingQuery: action.payload }
    case ConsultMarginQueryTypes.SET_COUNTER_RECALL:
      return { ...state, counterRecall: state.counterRecall + action.payload }
    case ConsultMarginQueryTypes.MARGIN_SUCCESS:
      return { ...state, marginSuccess: action.payload }
    case ConsultMarginQueryTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
