import { BaseServices, HttpMethod } from '@services/base'
import { ConveniosCodes } from '@utils/enums'

export default (convenioSelecionado: number) => {
  const pathname =
    convenioSelecionado === ConveniosCodes.INSS
      ? process.env.REACT_APP_SEND_PORTABILITY_LEAD_INSS
      : process.env.REACT_APP_SEND_PORTABILITY_LEAD_SIAPE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
  })
}
