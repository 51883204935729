import { action, Action } from 'typesafe-actions'

import { ConsultMarginQueryTypes, InfoInssType, ResultPayload, StartPayload } from './types'

export const setInfoInssDadosBancarios = (payload: InfoInssType): Action =>
  action(ConsultMarginQueryTypes.SET_INFO_INSS_DADOS_BANCARIOS, payload)

export const requestMarginAccept = (payload: StartPayload): Action =>
  action(ConsultMarginQueryTypes.REQUEST_MARGIN_ACCEPT, payload)

export const setLoadingQuery = (payload: boolean): Action =>
  action(ConsultMarginQueryTypes.SET_LOADING_QUERY, payload)

export const setRecallInss = (payload: number): Action =>
  action(ConsultMarginQueryTypes.SET_COUNTER_RECALL, payload)

export const requestMarginResult = (payload: ResultPayload): Action =>
  action(ConsultMarginQueryTypes.REQUEST_MARGIN_RESULT, payload)

export const setMarginSucess = (payload: boolean): Action =>
  action(ConsultMarginQueryTypes.MARGIN_SUCCESS, payload)
