import styled from 'styled-components'

import { SelectBottomSheet } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
import { Colors } from '@utils/colors'
import { Input } from '@atoms'

export const CustomBottomSheet = styled(SelectBottomSheet)<{ buttonSaibaMais?: boolean }>`
  display: flex;
  width: 100%;
  ${(props) => props.buttonSaibaMais && `margin-top: -16px;`}
  & input {
    color: ${Colors.GRAY500};
  }
`

export const CustomInput = styled(Input)`
  & input {
    color: ${Colors.GRAY500};
  }
`

export const CustomInputNoLabel = styled(Input)`
  & div:nth-child(1) {
    display: none;
  }
  width: 100%;
`
