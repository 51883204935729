import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`

export const BoxLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BoxWithoutChange = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  justify-content: center;
`
