import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Select } from '@atoms'
import { buildCidades } from '@utils/functions'

import { CustomBottomSheet } from './styles'
import { CidadeDropdownProps } from './props'

const CidadeDropdownNovo = ({
  id,
  cidadeSelecionada,
  isDisabled,
  onChange,
}: CidadeDropdownProps) => {
  const { cidades = [], cidadesLoading } = useSelector((state: ApplicationState) => state.globalApi)

  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="De qual cidade?"
        margin="0 0 20px 0"
        name="dropdown"
        value={cidadeSelecionada}
        hideSearch={false}
        options={buildCidades(cidades)}
        placeholder="Selecione a cidade"
        onChange={(value) => {
          onChange(value)
        }}
        disabled={isDisabled || cidadesLoading}
        loading={cidadesLoading}
      />
    )
  }

  return (
    <CustomBottomSheet
      id={id}
      label="De qual cidade?"
      name="dropdown"
      value={cidadeSelecionada}
      onChange={(value) => {
        onChange(value)
      }}
      options={buildCidades(cidades)}
      placeholder="Selecione a cidade"
      disabled={isDisabled || cidadesLoading}
      isLoading={cidadesLoading}
      isUsingFilter
    />
  )
}

export default memo(CidadeDropdownNovo)
