import React from 'react'

import { useSelector } from 'react-redux'

import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import GridStyled from '@atoms/GridStyled'
import { Button } from '@interco/inter-ui/components/Button'
import NovoPage from '@templates/NovoPage'
import { ApplicationState } from '@store/types'
import Timer from '@icons/Timer'

const FeedbackResultadoMargem = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  return (
    <NovoPage
      id="erro-sistemico"
      stickyFooter={
        <>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = isApp
                ? process.env.REACT_APP_HOME_EMPRESTIMO || ''
                : process.env.REACT_APP_HOME_SIMULADOR_NOVO || ''
            }}
          >
            Voltar para home
          </Button>
        </>
      }
    >
      <GridStyled justify="space-around" margin="40px 0 24px 0">
        <Timer />
      </GridStyled>
      <ColorWrapper fontWeight="600" style={{ marginBottom: '8px', textAlign: 'center' }}>
        <TextParagraph variant="headline-h1" textAlign="center">
          Entraremos em contato para te ajudar a autorizar
        </TextParagraph>
      </ColorWrapper>

      <TextParagraph variant="body-3" textAlign="center">
        Assim, você conseguirá dar sequência a sua contratação por telefone.
      </TextParagraph>
    </NovoPage>
  )
}
export default FeedbackResultadoMargem
