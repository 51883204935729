import React from 'react'

import { useSelector } from 'react-redux'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/colors'
import Paragraph from '@atoms/Paragraph'
import { ApplicationState } from '@store/types'

import { Header, LargeButton, ModalAlert, ModalConfirmation, SpaceBetween } from './styles'
import { DialogProps } from './props'

export const Dialog = ({
  headerTitle,
  description,
  onClose,
  children,
  buttonText,
  buttonAction,
  secondaryButtonText,
  secondaryButtonAction,
  shouldDisableButton,
}: DialogProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp && secondaryButtonText && secondaryButtonAction) {
    return (
      <ModalConfirmation
        width={540}
        visible
        onClose={onClose}
        bottomFade={false}
        title={headerTitle}
        onPrimary={() => {
          if (buttonAction) {
            buttonAction()
            onClose()
          } else {
            onClose()
          }
        }}
        onSecondary={() => onClose()}
      >
        {description ? <Paragraph margin="0 0 24px 0">{description}</Paragraph> : <></>}
        {children}
      </ModalConfirmation>
    )
  }
  if (!isApp) {
    return (
      <ModalAlert
        width={540}
        visible
        bottomFade={false}
        onClose={onClose}
        title={headerTitle}
        onPrimary={() => {
          if (buttonAction) {
            buttonAction()
            onClose()
          } else {
            onClose()
          }
        }}
      >
        {description ? <Paragraph margin="0 0 24px 0">{description}</Paragraph> : <></>}
        {children}
      </ModalAlert>
    )
  }

  return (
    <BottomSheet onClose={() => onClose()}>
      <Header>
        <Paragraph
          fontFamily="sora"
          fontSize="16px"
          lineHeight="20px"
          color={Colors.GRAY500}
          fontWeight={600}
        >
          {headerTitle}
        </Paragraph>
      </Header>
      {description ? <Paragraph margin="0 0 24px 0">{description}</Paragraph> : <></>}
      {children}
      {buttonText ? (
        <LargeButton
          fullWidth
          disabled={shouldDisableButton}
          onClick={() => {
            if (buttonAction) {
              buttonAction()
              onClose()
            } else {
              onClose()
            }
          }}
        >
          {buttonText}
        </LargeButton>
      ) : (
        <></>
      )}
      {secondaryButtonText && secondaryButtonAction ? (
        <>
          <SpaceBetween />
          <LargeButton fullWidth variant="secondary" onClick={() => secondaryButtonAction()}>
            {secondaryButtonText}
          </LargeButton>
        </>
      ) : (
        <></>
      )}
    </BottomSheet>
  )
}
