/**
 * Actions Types
 */

export enum SessionTypes {
  SET_SESSION_ID = '@session/SET_SESSION_ID',
  SET_SESSION_CREATE_DATE = '@session/SET_SESSION_CREATE_DATE',
  SET_UTM_SOURCE = '@session/SET_UTM_SOURCE',
  SET_UTM_MEDIUM = '@session/SET_UTM_MEDIUM',
  SET_UTM_CONTENT = '@session/SET_UTM_CONTENT',
  SET_UTM_CAMPAIGN = '@session/SET_UTM_CAMPAIGN',
  SET_FACEBOOK_ID = '@session/SET_FACEBOOK_ID',
  SET_GA_CLIENT_ID = '@session/SET_GA_CLIENT_ID',
  SET_GA_CLID = '@session/SET_GA_CLID',
  RESET = '@session/RESET',
}

/**
 * State Type
 */

export interface SessionState {
  readonly sessionId?: string
  readonly sessionCreateDate?: Date
  readonly utmSource?: string
  readonly utmMedium?: string
  readonly utmContent?: string
  readonly utmCampaign?: string
  readonly facebookId?: string
  readonly gaClientId?: string
  readonly gaClid?: string
}
