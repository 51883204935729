import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { GridStyled } from '@atoms'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import NovoPage from '@templates/NovoPage'
import Timer from '@icons/Timer'
import useTicTac from '@hooks/useTicTac'
import Tags from '@utils/Tags'

const InssIndisponivel = () => {
  const { useTac } = useTicTac()
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useTac({
    name: 'inss-indisponivel',
    module: 'portabilidade_consignado',
    actions: ['retornar_margin_result'],
    params: {},
  })

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Feedback: problema tecnico',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'INSS indisponivel',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado])

  return (
    <NovoPage
      id="inss-indisponivel"
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Feedback: problema tecnico',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Ir para a home',
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
          }}
        >
          Ir para home
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <GridStyled justify="space-around" margin="24px 0">
          <Timer />
        </GridStyled>
        <ColorWrapper
          fontWeight="600"
          style={{ marginBottom: '8px', display: 'flex', justifyContent: 'center' }}
        >
          <TextParagraph variant="headline-h1" textAlign="center">
            INSS indisponível
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <TextParagraph variant="body-3" textAlign="center">
            Devido ao horário, não conseguimos dar sequência na contratação do empréstimo.
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <TextParagraph variant="body-3" textAlign="center">
            Por favor, retorne entre 06h e 22h
          </TextParagraph>
        </ColorWrapper>
      </div>
    </NovoPage>
  )
}

export default InssIndisponivel
